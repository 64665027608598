<template>
    <div class="sm:ml-22">
        <div class="fixed p-4 pl-5 top-0 bg-gray-800/50 shadow-lg z-10 border-b-2 border-gray-500" style="width:calc(100% - 5rem);">
            <h1 class="float-left mr-5 text-base sm:text-xl text-white font-bold mt-1">Tutorials</h1>
        </div>

        <section class="mt-22 ml-5 mb-20 mt-5 bg-gray-800/80 p-1 px-7 rounded-xl">
            <ofdash-button @click="openLink()" class="my-3" label="Open folder" icon="fa-solid fa-up-right-from-square" />
        </section>
    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
    import ofdashButton from '@/components/ofdashButton.vue'
</script>

<script>
    export default {
        components() {
            headerfilter,
            ofdashButton
        },
        data() {
            return {
                loading:false,
                myself:null,
                companyID:null
            }
        },
        methods: {
            openLink() {
                window.open("https://drive.google.com/drive/folders/1ra49IBdc1W0GBCrBMtqZe3jTmKT2EZ5f?usp=share_link", '_blank', 'noreferrer');
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                    this.myself = result.data.myself;
                }, (error) => {
                console.log(error);
            });
        }
    }
</script>