<template>
    <div>
        <div class="w-full text-sm leading-8 text-center">Chargeback:</div>
        <input type="datetime-local" placeholder="Date and time" class="w-52"  @keyup="changeInput()" @change="changeInput()" v-model="inputValue"><br />
    </div>
</template>

<script>
    export default {
        props: ['pholder','vmodel','vvalue'],
        data() {
            return {
                inputValue:null
            }
        },
        watch: {
            inputValue: function(o,n) {
                this.$emit(this.vmodel, this.inputValue);
            }
        },
        methods: {
            changeInput() {
                this.$emit(this.vmodel, this.inputValue);
            }
        },
        mounted() {
            if (this.vvalue) {
                this.inputValue = this.vvalue;
                this.$emit(this.vmodel, this.inputValue);
            } else {
                this.$emit(this.vmodel, null);
            }
        }
    }
</script>