<template>
    <div class="w-full mb-4 relative">
        <router-link :to="'../../Settings'">
            <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
        </router-link>
        <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">My account</h2>
    </div>

    <section>
        <div>
            <label>Name</label><br />
            <input type="text" placeholder="Name" v-model="myData" />
        </div>
    </section>

    <html>
        <head>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap" rel="stylesheet">
        </head>
        <body>
            <div style="width:400px;height:200px;font-family:Montserrat;background-image:url('https://api.expfollow.com/emailFooter/showbizz_bg.png');padding-left:100px;padding-top:35px;">
                <h1 style="font-size:18.2px;color:white;font-weight:400;line-height:27px">Name Surname</h1>
                <h1 style="font-size:8px;color:#ffed00;font-weight:500;line-height:50%;text-transform:uppercase;">Position</h1>
                <section style="line-height:300%;margin-top:5px;font-size:11px;">
                <a href="#" style="color:white;text-decoration:none;">
                <img src="https://api.expfollow.com/emailFooter/email.png" width="25px" style="float:left;margin-right:3px;vertical-align:middle" /><span class="float-left">company e-mail</span>
                </a><br />
                <a href="https://www.showbizz.media" target="_blank" style="color:white;text-decoration:none;">
                <img src="https://api.expfollow.com/emailFooter/website.png" width="25px" style="float:left;margin-right:3px;vertical-align:middle" /><span class="float-left">www.showbizz.media</span>
                </a><br />
                <a href="https://www.instagram.com/showbizzmediaro" target="_blank" style="color:white;text-decoration:none;">
                <img src="https://api.expfollow.com/emailFooter/instagram.png" width="25px" style="float:left;margin-right:3px;vertical-align:middle" /><span class="float-left">@showbizzmediaro</span>
                </a>
                </section>
            </div>
        </body>
    </html>
</template>

<script>
    export default {
        data() {
            return {
                myData:null
            }
        },
        created() {

        }
    }
</script>