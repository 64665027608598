<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Edit a model</h2>
    </div>

    <div>
        <table class="text-white bg-gray-700" id="modelTable">
            <tr>
                <th class="cursor-pointer" @click="sortTable('code','ASC')" >Code &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'code'}"></i></th>
                <th class="cursor-pointer w-1/6" @click="sortTable('identifier','ASC')" >Group &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'identifier'}"></i></th>
                <th class="cursor-pointer w-1/3" @click="sortTable('free.name','ASC')" >Name &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'free.name'}"></i></th>
                <th class="w-24">Edit</th>
                <th class="w-48">Archive</th>
                <th class="w-20">Bugs</th>
            </tr>
            <tr v-for="(model, index) in modelData" :key="index" v-bind:class="{'opacity-50 cursor-default': model.free.active== 0}">
                <td><i v-if="model.free.scraped == 0" class="fas fa-exclamation-circle" title="Probably wrong credentials"></i> {{ model.code }}</td> 
                <td>{{ model.identifier }}</td>
                <td>{{ model.free.name }}</td>
                <td class="cursor-pointer" v-if="model.free.active != 0">
                    <router-link :to="{ name: 'modelsForm', params: {edit:true, modelID: model.pair_id}}">Edit <i class="fas fa-external-link-alt"></i></router-link>
                </td>
                <td v-else></td>
                <td class="cursor-pointer" @click="modelDeArchive(index)" v-if="model.free.active == 0">Return from archive <i class="fas fa-undo"></i></td>
                <td class="cursor-pointer" @click="modelArchive(index)" v-else>Archive <i class="fas fa-archive"></i></td>
                <td class="text-center cursor-pointer" @click="displayBugs(index)"><i class="fa-solid fa-bug"></i></td>
            </tr>
        </table>
    </div>

    <div v-if="openBugs && bugsData" class="fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50" @click="openBugs = false">
        <div class="p-5 absolute right-3 top-0 text-white cursor-pointer" @click="openBugs = false"><i class="fa-solid fa-xmark fa-lg"></i></div>
    </div>
    <div v-if="openBugs && bugsData" class="text-white mx-auto w-1/2 left-1/4 bg-gray-700 mt-10 z-50 fixed top-10 h-5/6 overflow-y-scroll">
        <div class="text-center p-4 text-lg">Model: <strong>{{bugsData.modelName}}</strong></div>
        <table>
            <tr>
                <th>Account type</th>
                <th>Timestamp</th>
                <th>Bug</th>
            </tr>
            <tr v-for="(bug,index) in bugsData.values" :key="index">
                <td class="text-center">{{bug.type}}</td>
                <td class="text-center">{{bug.timeStamp}}</td>
                <td class="text-center">{{bug.bug_name}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        data() {
            return { 
                modelData: [],
                modelPairID: null,
                sortColumn: null,
                loading:false,
                openBugs:false,
                bugsData:[]
            }
        },        
        methods: {
            sortTable(column,order) {
                this.sortColumn = column
                let orderMultiplier = order === 'ASC' ? 1 : -1;
                console.log(column,this.$cookies.get('orderColumnM'))
                if (this.$cookies.get('orderColumnM') != column) {
                    this.$cookies.set('orderColumnM',column);
                }
                const properties = column.split('.');

                this.modelData.sort((a, b) => {
                    const valueA = properties.reduce((obj, key) => obj[key], a);
                    const valueB = properties.reduce((obj, key) => obj[key], b);
                    return order === 'ASC'
                    ? valueA.localeCompare(valueB, undefined, { sensitivity: 'base' })
                    : valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
                });
                console.log(this.modelData);
            },
            displayBugs(index) {
                this.openBugs = true
                
                axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'getModelBugs',
                        model_pair_id: this.modelData[index].pair_id,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.bugsData = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            modelArchive(index) {
                if (confirm("Are you sure you want to archive this model?")) { 
                    this.modelData[index].free.active=0 
                    axios.post(baseURL+"settingspost/", {                    
                        action: "modelArchiveDearchive",
                        modelPairID: this.modelData[index].pair_id,
                        active: 0,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            modelDeArchive(index) {
                if (confirm("Are you sure you want to return this model from archive?")) { 
                    this.modelData[index].free.active=1 
                    axios.post(baseURL+"settingspost/", {                    
                        action: "modelArchiveDearchive",
                        modelPairID: this.modelData[index].pair_id,
                        active: 1,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                    }, (error) => {
                        console.log(error);
                    });
                    }
            }
        },
        created() {
            this.loading = true;
            axios.get(baseURL+"settingsget/", {
                params: {
                    searchFor: '',
                    action: 'editModels',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.modelData = result.data
                if (this.$cookies.get('orderColumnE')) {
                    this.sortTable(this.$cookies.get('orderColumnM'),'ASC')
                }
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
        }
    }
</script>

<style scoped>
    tr:nth-child(odd) {
        background-color: rgb(75, 85, 99);
    }
    td {
        padding:5px;
    }
</style>