<template>
    <div class="p-2 px-5 cursor-pointer rounded-full bg-indigo-300 text-black w-max">
        <i :class="icon" class="mr-2"></i> {{label}}
    </div>
</template>

<script>
    export default {
        props: ['label','icon']
    }
</script>