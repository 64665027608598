<template> 
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>

    <div class="w-full mb-4 relative">
      <router-link :to="'/Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Groups</h2>
  
    </div>

    <div>

        <!--var cislo=1;-->
        <div class="bg-gray-300 text-black p-1 rounded mb-5 w-full px-3">
            <span class="cursor-pointer"
                v-for="(layer,index) in layers" 
                @click="openGroup(layer.id,layer.name,false,index)"
                v-bind:class="{ active: index==layers.length-1 && index!=0}"
                :key="index">
                    <span style="display:none">{{ layer.id }}</span>
                    {{ layer.name }}
            | </span>
        </div>
        <ol class="list-outside list-disc text-white mt-3 pl-6 ml-2 w-80 leading-10">
            <li v-for="(hodnota,groupIndex) in groups" v-bind:id="hodnota.id" :key="groupIndex" class="cursor-pointer">                            
                <article class="float-left" v-if="!hodnota.editing" @click="openGroup(hodnota.id,hodnota.name,true,'')">{{ hodnota.name }}</article>
                <input type="text" v-if="hodnota.editing" @blur="hodnota.editing = false" autofocus v-model="editGroupVal" @keydown.enter="hodnota.editing = false, editGroup(hodnota.id,groupIndex)"/>
                <div v-if="!hodnota.editing"
                    class="bg-indigo-300 display-inline-block rounded-full text-xs leading-none p-2 text-black text-center cursor-pointer float-left ml-4 mt-2" 
                    @click="addInput(groupIndex)"><i class="fas fa-pencil-alt"></i></div>
                <div v-if="!hodnota.editing && hodnota.hasChildren == false" 
                    class="groupToTrash p-2 text-center cursor-pointer float-left text-white ml-4 mt-2" 
                    @click="removeGroup(hodnota.id,groupIndex)"><i class="fas fa-trash-alt"></i></div>                            
            </li>
            <div class="cursor-text mt-2">
                <label>Name of a new group</label><br />
                <input type="text" class="new_group_name" v-model="newGroup" ref="new" @keydown.enter="addGroup()" placeholder="Name" autofocus /><br />                                
                <small>Press enter to save</small>
            </div>
        </ol>
    </div>    
</template>

<script>
    export default { 
        props: ['headerName'],  
        data() {
            return {
                groups: [],
                newGroup: null,
                editGroupVal: null,
                editGroupName: '',
                layers: [{
                    "id": 0,
                    "name": "Branches"
                }],
                loading: false
            }  
        }, 
        computed: {
            activeGroups: function() {
                return this.groups.filter(function(g) {
                    return g.editing
                });
            }
        },
        methods: {
            openGroup(parent,parentName,addLayer,index) {
                this.loading = true;
                if (addLayer) {
                    this.layers.push({
                        id: parent,
                        name: parentName
                    })
                } else {
                    this.layers.splice(index+1);
                }
                axios.get(baseURL+"settingsget/", {
                        params: {
                                    parentGroup: parent,
                                    action: 'groupsSettings',
                                    userToken: this.$cookies.get('userToken'),
                                    companyID: this.$cookies.get('c_id')
                                }
                    }).then(result => {
                    this.groups = result.data
                }, (error) => {
                    console.log(error);
                })
                .finally(() => this.loading = false)
            },
            addInput(index) {
                for (let i = 0; i < this.groups.length; i++) {
                    this.groups[i].editing = false;
                }
                this.groups[index].editing = true;
                this.editGroupVal = this.groups[index].name;
            },
            addGroup() {
                axios.post(baseURL+"settingspost/", {
                    parentGroup: this.layers[this.layers.length - 1].id,
                    groupName: this.newGroup,
                    action: 'addNewGroup',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(result => {
                    this.groups.push({id:result.data,name:this.newGroup,hasChildren:false})
                    this.newGroup = null;
                }, (error) => {
                    console.log(error);
                })
            },
            editGroup(groupID,groupIndex) {
                axios.post(baseURL+"settingspost/", {
                    newValue: this.editGroupVal,
                    groupID: groupID,
                    action: 'editGroup',
                    innerAction: 'edit',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(result => {
                }, (error) => {
                    console.log(error);
                })
                this.groups[groupIndex].name = this.editGroupVal;
            },
            removeGroup(groupID,groupIndex) {
                if (confirm("Are you sure you want to remove this group?")) {
                    axios.post(baseURL+"settingspost/", {
                        newValue: null,
                        groupID: groupID,
                        action: 'editGroup',
                        innerAction: 'remove',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then(result => {
                    }, (error) => {
                        console.log(error);
                    })
                    this.groups.splice(groupIndex,1);
                }
            }
        },
        created() {
            this.loading = true;
            axios.get(baseURL+"settingsget/", {
                params: {
                            parentGroup: null,
                            action: 'groupsSettings',
                            userToken: this.$cookies.get('userToken'),
                            companyID: this.$cookies.get('c_id')
                        }
            })
            .then(result => {
                this.groups = result.data
            })
            .finally(() => this.loading = false)
        }
    }
</script>





