<template>
    <div class="fixed top-0 w-full sm:pl-22 z-10">
        <div class="p-4 pl-5 bg-gray-800/50 shadow-lg  border-b-2 border-gray-500 w-full">
            <h1 class="float-left mr-5 text-base sm:text-xl text-white font-bold mt-1">{{pageTitle}}</h1>
            <div @click="mobileFilter=!mobileFilter" class="sm:hidden text-white mt-1 absolute right-5"><i class="fa-solid fa-filter"></i></div>
            <div class="metrics-bar" v-if="pageTitle != 'Settings'">
                <span v-bind:class="{'hidden sm:contents':!mobileFilter}">
                    <slot v-if="pageTitle != 'Promo' && pageTitle != 'Drop Shifts' && pageTitle != 'Metrics summary'">
                        <select class="ml-2 sm:ml-0 float-left w-min mr-4" @change="changedGroup($event.target.selectedIndex)" v-model="selectedGroup" v-if="groupList.length>1">
                            <option :value="null">All groups</option>
                            <option v-for="(group,index) in groupList" :key="index" :value="group.id">{{group.name}}</option>
                        </select>
                        <span v-else class="text-white float-left mr-5 text-lg mt-1">{{singleGroup}}</span>

                        <slot v-if="(pageTitle=='Sexting' || pageTitle=='Sexting costs' || pageTitle=='Payrolls' || pageTitle=='Shifts') && (((groupList.length>1) && (selectedGroup != null)) || ((selectedGroup != null && groupList[groupIndex].hasChildren) || (singleGroup && groupList[groupIndex].hasChildren)))">
                            <select class="float-left w-min mr-4" @change="changedTeam()" v-if="groupList[groupIndex].hasChildren" v-model="selectedTeam">
                                <option value="null">All teams</option>
                                <option v-for="(team,index) in groupList[groupIndex].children" :key="index" :value="team.id">{{team.name}}</option>
                            </select>
                        </slot>
                    </slot>
                    <slot v-else-if="pageTitle == 'Metrics summary'">
                        <select class="ml-2 sm:ml-0 float-left w-min mr-4" @change="changedBranch($event.target.selectedIndex)" v-model="selectedBranch" v-if="branchList.length>1">
                            <option :value="null">All branches</option>
                            <option v-for="(branch,index) in branchList" :key="index" :value="branch.id">{{branch.name}}</option>
                        </select>
                    </slot>
                    <slot v-else-if="pageTitle == 'Drop Shifts'">
                        <select class="float-left w-min mr-4" @change="changedmTeam($event.target.selectedIndex)" v-model="selectedmTeam" v-if="mTeamsList.length>1">
                            <option :value="null">All teams</option>
                            <option v-for="(mTeam,index) in mTeamsList" :key="index" :value="mTeam.id">{{mTeam.name}}</option>
                        </select>
                        <span v-else class="text-white float-left mr-5 text-lg mt-1">{{singleMarkTeam}}</span>

                        <slot v-if="(((groupList.length>1) && (selectedmTeam != null)) || ((selectedmTeam != null && assistantsList) || (singleMarkTeam && assistantsList)))">
                            <select class="float-left w-min mr-4" @change="$emit('selectedTeam', selectedAssistant);" v-if="assistantsList" v-model="selectedAssistant">
                                <option value="null">All assistants</option>
                                <option v-for="(assistant,index) in assistantsList" :key="index" :value="assistant.id">{{assistant.name}}</option>
                            </select>
                            <!--<span v-else class="text-white float-left mr-5 text-lg mt-1">{{singleTeam}}</span>-->
                        </slot>
                    </slot>
                
                    <slot v-if="(pageTitle=='Metrics' && enable>1) || pageTitle == 'Transactions' || pageTitle == 'Promo'">
                        <span v-if="modelsList.length>1">
                            <input type="search" class="float-left w-min mr-4" placeholder="Search" v-model="selectedModelName" list="models" @click="selectedModelName = null" @change="modelSelected()" />
                            <datalist id="models">
                                <option v-for="(model,index) in modelsList" :key="index" :value="model.code + ' (' + model.name + ')'" :class="model.pair_id"></option>
                            </datalist>
                        </span>
                        
                        <span v-else class="text-gray-200 float-left mr-5 text-base mt-2">No models</span>
                        <div class="float-left mr-3">
                            <i class="far fa-question-circle text-lg text-gray-500 cursor-pointer" @mouseenter="displayLegend = true"  @mouseleave="displayLegend = false"></i>
                            <div v-bind:class="{'hidden':displayLegend == false}" class="bg-red-300 text-black absolute mt-5 p-2 rounded border border-black">* - in a different team<br />** - archived<br />*** - in a different company</div>
                        </div>
                    </slot>
                    <slot v-else-if="pageTitle=='Metrics' && enable<=1">
                        <div class="float-left mr-5 mt-2"><i class="fa-lg fa-solid fa-spinner animate-spin text-gray-300"></i></div>
                    </slot>
                    <slot v-else-if="pageTitle=='Sexting' || pageTitle=='Sexting costs' || pageTitle=='Payrolls'">
                        <slot v-if="employeeList.length>0">
                            <input type="search" class="float-left w-min mr-4" placeholder="Search" v-model="selectedEmpl" list="models" @click="selectedEmpl = null" @change="employeeSelected()" />
                            <datalist id="models">
                                <option v-for="(employee,index) in employeeList" :key="index" :value="employee.name" :class="employee.id"></option>
                            </datalist>
                        </slot>
                        <span v-else class="text-gray-200 float-left mr-5 text-base mt-2">No employees</span>

                        <select v-if="1==2" class="float-left w-min mr-4"  v-model="selectedEmpl">
                            <option v-for="(employee,index) in employeeList" :key="index" v-bind:value="employee.id">{{employee.name}}</option>
                        </select>
                        
                    </slot>

                    <!-- Clear filters -->
                    <div v-if="pageTitle=='Metrics' || pageTitle == 'Metrics summary' || pageTitle=='Transactions' || pageTitle == 'Promo' || pageTitle=='Sexting' || pageTitle=='Sexting costs' || pageTitle=='Payrolls' || (pageTitle=='Shifts' && groupList.length>1)" class="whitespace-nowrap cursor-pointer w-min float-left p-2 rounded-xl text-gray-800 bg-red-300 mr-3" @click="clearFilters()" title="Clear">
                        <i class="fas fa-eraser"></i>
                    </div>

                    <select v-if="pageTitle == 'Sexting' || pageTitle=='Sexting costs' || pageTitle=='Payrolls' || pageTitle == 'Models'" v-model="month" class="-mt-2 absolute right-5" @change="monthChanged()">
                        <option v-for="(month,index) in months" :key="index" :value="month.id">{{month.name}}</option>
                    </select>

                    <div v-else class="sm:absolute right-5 clear-both sm:float-left">
                        <datepicker v-model="dateFrom" v-if="dateFrom" class="datepickerInput float-left"></datepicker>
                        <datepicker v-model="dateTo" v-if="dateTo" class="datepickerInput float-left"></datepicker>
                    </div>
                </span>
                <div class="hidden sm:contents absolute right-7 mt-0 sm:mt-8 text-indigo-300 float-left">Timezone: <strong class="text-indigo-100 text-sm">UTC (+0)</strong></div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['pageTitle','lrIndex','lrData','enable'],
        data() {
            return {
                groupList: [],
                mTeamsList: [],
                assistantsList: [],
                modelsList: [],
                displayLegend: false,
                employeeList: [],
                branchList:[],
                unselectGroups: false,
                dateFrom: null,
                timeNow: null,
                timeZone: null,
                dateTo: null,
                selectedGroup: null,
                selectedTeam: 0,
                selectedBranch:null,
                selectedModelName: null,
                selectedModel: null,
                selectedEmpl:null,
                selectedmTeam:null,
                selectedAssistant:null,
                singleGroup:null,
                singleMarkTeam:null,
                singleGroupID:null,
                groupIndex:null,
                months: [],
                month: moment().format("YYYY-MM-")+'01',
                mobileFilter:false
            }
        },
        watch: {
            dateFrom: function () {
                this.$emit('dateFrom', this.dateFrom);
            },
            dateTo: function () {
                this.$emit('dateTo', this.dateTo);
            },
            lrIndex: async function (nVal,oVal) {
                if (!oVal) {oVal = 0;}
                if (this.lrData == 'model') {
                    var modelIndex = this.getModelIndex(oVal,nVal);
                    this.selectedModelName = this.modelsList[modelIndex].code + ' (' + this.modelsList[modelIndex].name + ')';
                    if (this.selectedModelName != null) {
                        this.$emit('selectedModel', this.modelsList[modelIndex].pair_id);
                        this.$emit('selectedModelN', this.modelsList[modelIndex].name);
                    }
                } else {
                    var groupIndex = this.getGroupIndex(oVal,nVal);
                    this.selectedGroup = this.groupList[groupIndex].id;
                    if (this.selectedGroup != null) {
                        this.$emit('selectedGroup', this.groupList[groupIndex].id);
                        this.$emit('selectedGroupN', this.groupList[groupIndex].name);
                    }

                    if (this.selectedGroup != null) {
                        if (this.pageTitle === 'Metrics' || this.pageTitle === 'Transactions') {
                            this.modelsList = await this.getListData('models',this.selectedGroup,null);
                        } else if (this.pageTitle === 'Sexting'  || this.pageTitle === 'Sexting costs' || pageTitle === 'Payrolls') {
                            this.employeeList = await this.getListData('sextarsList',this.selectedGroup,null);
                        }
                    }
                }
            }
        },
        methods: {
            getGroupIndex(oVal, nVal) {
                return (this.groupList.findIndex(b => b.id === this.selectedGroup) + (oVal < nVal ? 1 : this.groupList.length - 1)) % this.groupList.length;
            },
            getModelIndex(oVal, nVal) {
                const selectedModel = this.modelsList.find(b => b.code + ' (' + b.name + ')' === this.selectedModelName)
                return (this.modelsList.indexOf(selectedModel) + (oVal < nVal ? 1 : this.modelsList.length - 1)) % this.modelsList.length
            },
            modelSelected() {
                const model = this.modelsList.find(b => b.code + ' (' + b.name + ')' === this.selectedModelName)
                if (model) {
                    this.$emit('selectedModel', model.pair_id);
                    this.$emit('selectedModelN', this.selectedModelName);
                }
            },
            employeeSelected() {
                const employee = this.employeeList.find(b => b.name === this.selectedEmpl)
                if (employee) {
                    this.$emit('selectedEmpl', employee.id);                   
                }
            },
            monthChanged() {
                this.$emit('selectedMonth',this.month);
            },
            clearFilters() {                
                this.unselectGroups = true;
                this.selectedBranch = null;
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.selectedTeam = null;
                this.selectedModelName = null;
                this.selectedGroup = null;
                this.$emit('selectedModel', null);
                this.$emit('selectedBranch', null);
                this.$emit('selectedTeam', null);
                this.$emit('selectedEmpl', null);
                this.$emit('selectedGroup', 0);
            },
            async changedGroup(index) {
                this.groupIndex = index-1;
                this.selectedTeam = null;
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.unselectGroups = false;
                this.$emit('selectedGroup', this.selectedGroup);
                
                if (this.selectedGroup != null) {
                    this.$emit('selectedGroupN', this.groupList[this.groupIndex].name);
                    if (this.pageTitle == 'Metrics' || this.pageTitle=='Transactions' || this.pageTitle == 'Metrics summary') {
                        this.modelsList = await this.getListData('models',this.selectedGroup,null);
                    } else if (this.pageTitle == 'Sexting' || this.pageTitle=='Sexting costs') {
                        this.employeeList = await this.getListData('sextarsList',this.selectedGroup,null);
                    }
                }
            },
            changedBranch() {
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.$emit('selectedBranch', this.selectedBranch);
            },
            async changedTeam() {
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.employeeList = await this.getListData('sextarsList',this.selectedGroup,this.selectedTeam);
                this.$emit('selectedTeam', this.selectedTeam);
            },
            async changedmTeam() {
                this.assistantsList = await this.getListData('assistants',null,this.selectedmTeam);
                this.$emit('selectedGroup', this.selectedmTeam);
            },
            async getListData(whichData,group,team,mSummary = null) {
                return new Promise(async (resolve, reject) => {
                    try {
                      const result = await axios.get(baseURL+"lists/", {
                            params: {
                                build: whichData,
                                groupFilter: group,
                                teamFilter: team,
                                msummary:mSummary,
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id')
                            }
                        })
                      resolve(result.data);
                    } catch (error) {
                      reject(error);
                    }
                });
            }
        },
        async created() {
            let currentMoment = moment();
            while (this.months.length < 13) {
                this.months.push({
                    'name': `${currentMoment.format("MMMM YYYY")}`,
                    'id': `${currentMoment.format("YYYY-MM")}-01`
                });
                currentMoment.subtract(1, "month");
            }
            
            this.groupList = await this.getListData('groups',null,null);
            if (this.groupList.length == 1) {
                this.singleGroup = this.groupList[0].name;
                this.selectedGroup = this.groupList[0].id;
            }
            this.groupIndex = 0;

            if (this.pageTitle == 'Metrics summary') {
                this.branchList = await this.getListData('branches',null,null,1);
            }
            this.mTeamsList = await this.getListData('assistant_teams',null,null);
            this.modelsList = await this.getListData('models',this.selectedGroup,null);
            this.employeeList = await this.getListData('sextarsList',this.selectedGroup,null);

            const dateF = new Date();
            const dateT = new Date();

            if (this.pageTitle == 'Metrics' || this.pageTitle == 'Drop Shifts' || this.pageTitle=='Transactions' || this.pageTitle == 'Promo' || this.pageTitle == 'Metrics summary') {
                dateF.setDate(dateF.getDate() - 9);
                this.dateFrom = dateF;
                this.dateTo = new Date();
            } else if (this.pageTitle == 'Shifts') {
                dateF.setDate(dateF.getDate() - 1);
                this.dateFrom = dateF;
                dateT.setDate(dateT.getDate() + 5);
                this.dateTo = dateT;
            } else if (this.pageTitle == 'Sexting' || this.pageTitle == 'Models' || this.pageTitle == 'Payrolls' || this.pageTitle == 'Sexting costs') {
                this.selectedMonth = moment().format("YYYY-MM-")+'01';
            }

            this.$emit('dateFrom', this.dateFrom);
            this.$emit('dateTo', this.dateTo);
            this.$emit('selectedMonth', this.selectedMonth);
            //this.$emit('selectedGroup', null);
            this.$emit('selectedModel', null);
        }
    }
</script>