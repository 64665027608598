<template>
    <div class="hidden bg-pink-200 bg-blue-200 bg-green-200 bg-green-100"></div>
    
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   
    <headerfilter pageTitle="Content management" @selectedMonth="selectedMonth = $event" @selectedModel="selectedModel = $event" @selectedGroup="selectedGroup = $event" />

    <div class="mt-24"></div>
    <slot v-if="opravnenia != null && opravnenia[17] == 1">
        <slot v-for="(group, gIndex) in contentEvidence.values" :key="gIndex" >
            <slot v-if="group.modelsCards && group.modelsCards.length > 0">
                <section class="text-white tracking-widest uppercase block bg-transparent relative mt-5 ml-24 w-max text-3xl font-bold float-left mr-4">{{group.groupName}}</section>
                <section v-if="group.modelsCards.length > 1" class="bg-gray-700 mt-4 text-white rounded ml-10 p-3 px-7 float-left">
                    <b class="mr-3 font-semibold">Pay to models:</b> $ {{group.earningsGroup}}
                </section>
                <section v-if="group.modelsCards.length > 1" class="bg-gray-700 mt-4 text-white rounded ml-5 p-3 px-7 float-left">
                    <b class="mr-3 font-semibold">Bonuses:</b> $ {{group.salaryGroup}}
                </section>
                <section v-if="group.modelsCards.length > 1" class="bg-gray-700 mt-4 text-white rounded ml-5 p-3 px-7 float-left">
                    <b class="mr-3 font-semibold">Cost ratio:</b> {{group.costRatioGroup}} %
                </section>
            </slot>
            <section v-if="group.modelsCards && group.modelsCards.length > 0" class="ml-20 flex h-2/3 relative clear-both">
                <div class="flex ml-3 mt-5 overflow-x-scroll overflow-y-hidden mb-10">
                    <div class="float-left justify-center bg-gray-700 text-white p-4 rounded m-2 mr-4 ml-0 h-max" v-for="(model,i) in group.modelsCards" :key="i">       
                        <div class="col-span-10">
                            <div class="">
                                <h1 class="font-semibold w-96 mb-5 text-2xl text-center">{{ model.name }}</h1>
                                <section class="w-full flex mb-0.5">
                                    <div class="mr-1.5 cursor-pointer bg-gray-200 text-gray-800 font-semibold p-1 px-2 rounded-t-lg"><i class="fa-solid fa-spinner fa-xs mr-1"></i> Pending</div>
                                    <div class="mr-1.5 cursor-pointer bg-gray-800 p-1 px-2 font-semibold rounded-t-lg"><i class="fa-solid fa-circle-check fa-xs mr-1"></i> Done</div>
                                </section>
                                <div class="overflow-y-scroll h-128 pinkScrollbar pb-0">
                                    <div class="rounded relative w-full mb-1 p-3 px-4 cursor-pointer" v-for="(task,taskIndex) in model.tasks" :key="taskIndex" v-bind:class="{'bg-gray-900':task.read == 1,'bg-green-600':task.read == 0}">
                                        <h1 class="font-semibold text-sm bg-gray-700 w-max p-0.5 px-2 mb-1 rounded-full float-left mr-5">{{task.hashtag}}</h1>
                                        <h3 class="text-gray-200 text-base absolute right-5" v-if="task.comment>0"><i class="fa-solid fa-message"></i> {{task.comment}}</h3>
                                        <h3 class="text-gray-200 text-xs clear-both">{{task.note}}</h3>
                                        <p class="mt-3 text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent fermentum sed mi vitae pharetra. Praesent eu tempor dui, et semper sapien.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </slot>
    </slot>
    <div v-else class="text-center mt-52 text-white">It seems you don't have access to this service</div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        props: ['selectedModel','selectedGroup','selectedMonth'],
        components() {
            headerfilter
        },
        data() {
            return {
                loading:false,
                modelsEarnings: [],
                contentEvidence:[],                
                opravnenia:'0000000000000000000'
            }
        },
        methods: {
            inputChange(index) {
                var real = 0;
                for (var i = 0;i<this.contentEvidence.data[index].evidence.categories.length;i++) {
                    var value = this.contentEvidence.data[index].evidence.categories[i];
                    if (value.type == 1) {real += parseInt(value.amount);}
                    if (this.contentEvidence.data[index].evidence.categories[i].name=='KPI total') {
                        this.contentEvidence.data[index].evidence.categories[i].amount = parseFloat(this.contentEvidence.data[index].evidence.categories[i-1].amount)*5
                    }
                    if (this.contentEvidence.data[index].evidence.categories[i].name=='REAL') {
                        this.contentEvidence.data[index].evidence.categories[i].amount = real
                    }
                    if (this.contentEvidence.data[index].evidence.categories[i].name=='TOTAL') {
                        this.contentEvidence.data[index].evidence.categories[i].amount = (parseFloat(this.contentEvidence.data[index].evidence.categories[i-3].amount)*parseFloat(this.contentEvidence.data[index].evidence.categories[i-2].amount)*parseFloat(this.contentEvidence.data[index].evidence.categories[i-1].amount)).toFixed(2)
                    }
                    if (this.contentEvidence.data[index].evidence.categories[i].name=='VÝKON') {
                        this.contentEvidence.data[index].evidence.categories[i].amount = (parseFloat(this.contentEvidence.data[index].evidence.categories[i-1].amount) / parseFloat(this.contentEvidence.data[index].evidence.categories[i-5].amount)*100).toFixed(2)
                    }
                }
                
            },
            getData() {
                this.loading = true;
                axios.get(baseURL+"models/", {
                    params: {
                        build: "modelsEarnings",
                        userToken: this.$cookies.get('userToken'),
                        groupId: this.selectedGroup,
                        modelId: this.selectedModel,
                        fromDate: this.selectedMonth,
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.modelsEarnings = result.data
                    console.log(result.data);
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            }
        },
        watch: {
            selectedMonth: function(newVal, oldVal) { // watch it
                if (this.$cookies.get('c_id')) {
                    this.getData();
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                this.loading = true;
                axios.get(baseURL+"modelManagement/", {
                params: {
                    build: "manageContent",
                    fromDate: this.selectedMonth,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    selectedGroup: this.selectedGroup
                }
                }).then((result) => {
                    this.contentEvidence = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            }
        },
        mounted() {
            if (this.$cookies.get('c_id')) {
                axios.get(baseURL+"modelManagement/", {
                    params: {
                        build: "manageContent",
                        fromDate: this.selectedMonth,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id'),
                        selectedGroup: this.selectedGroup
                    }
                }).then((result) => {
                    console.log('result:', result.data)
                    this.contentEvidence = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                }, (error) => {
                console.log(error);
            })
        }
    }
</script>

<style scoped>
    .rightBorder {
        border-right-color: rgb(199,210,254) !important;
        border-right-width: 4px !important;
    }
#parent {
    max-height:85vh;
    overflow: hidden;
    position:absolute;
    width:90%;
    height:80vh;
}

table {
    border:1px solid rgb(199,210,254);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width:100%;
    max-height:100%;
    border-collapse: collapse;
    overflow: hidden;
}

thead {
  /*
  Grow thead automatically to fit content, don't shrink it
  proportionately to the body.
  */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
  Keep header columns aligned with useless scrollbar.
  For IE11, use "dead area" color to hide scrollbar functions
  */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

/*
For Webkit, use "dead area" color to hide scrollbar functions
TODO: on Chrome/Safari for Mac, scrollbars are not shown anyway and
this creates an extra block. No impact on iOS Safari.
*/
thead::-webkit-scrollbar { display: block; background-color: #ccc; }
thead::-webkit-scrollbar-track { background-color: #ccc; }

/* Scroll the actual tbody (second child on all browsers) */
tbody {
  display: block;
  overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) { display: none; }

/* The one caveat, a hard-set width is required. */
td, th {
  width: 10em;
  min-width: 10em;
  padding: 0.3em;
}

td:first-child,
th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left:0;
}
</style>