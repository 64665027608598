<template>
    <div>
        <div class="bg-green-300 rounded-full text-black p-2 fixed z-50 top-5 px-6 left-1/2" v-if="saved">Saved</div>

        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
        <div class="w-full mb-4 relative">
        <router-link :to="'../Settings'">
            <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
        </router-link>
        <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Salary settings</h2>
        <p class="mt-2 text-sm text-gray-300 text-center">*Set all the limits for the whole month, in the sexting section the limits will be divided automatically.</p>
        </div>

        <div class="min-w-52 block mb-10">
            <p class="text-lg bold text-white mt-5 mb-1">Wage based on:</p>
            <span @click="changeSalary(0)" class="cursor-pointer">
                <i class="fas fa-circle mr-3 text-gray-200" v-bind:class="{'text-indigo-500':salaryType == 0}"></i>
                <label class="text-base cursor-pointer">Fixed wage</label><br>
            </span>
            <span @click="changeSalary(1)" class="cursor-pointer">
                <i class="fas fa-circle mr-3 text-gray-200" v-bind:class="{'text-indigo-500':salaryType == 1}"></i>
                <label class="text-base cursor-pointer">Earnings/shift</label><br>
            </span>
            <span @click="changeSalary(2)" class="cursor-pointer">
                <i class="fas fa-circle mr-3 text-gray-200" v-bind:class="{'text-indigo-500':salaryType == 2}"></i>
                <label class="text-base cursor-pointer">Earnings/month</label><br>
            </span>
            
            <div v-if="salaryType == 2">
                <base-salary-component :salary="baseSalary" :sHours="baseSalaryH" />
                
                <salary-table class="mt-10" :salaryType="salaryType" :salary="monthlySalary" />
            </div>

            <salary-table class="mt-10" v-else-if="salaryType == 1" :salaryType="salaryType" :salary="salary" />
            <div class="mt-10 text-indigo-300 text-lg" v-else>Please fill out hourly wages of all employees</div>
        </div>
    </div>
</template>

<script setup>
    import salaryTable from './salaryTable.vue'
    import baseSalaryComponent from './baseSalaryComponent.vue'
</script>
<script>
    export default {
        components: { salaryTable,baseSalaryComponent },
        data() {
            return { 
                salary: [],
                monthlySalary: [],
                baseSalary:0,
                baseSalaryH:0,
                salaryMonth: [],
                modelPairID: null,
                sortColumn: null,
                loading:false,
                editIndex: null,
                salaryType: false,
                addingNewLine: false,
                rozdielOK: false,
                saved: false
            }
        },        
        methods: {
            changeSalary(val) {
                if (this.salaryType != val) {
                    this.salaryType = val;
                    axios.post(baseURL+"settingspost/", {
                        action: 'salaryType',
                        type: this.salaryType,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        console.log(result.data);
                        setTimeout(() => (this.saved = false),500);         
                    }, (error) => {
                        console.log(error);
                    })
                }
            },
            saveMonthlySalary() {

            },
            deleteMonthlySalary() {

            }
        },
        mounted() {
            this.loading = true;
            axios.get(baseURL+"settingsget/", {
                params: {
                    searchFor: '',
                    action: 'getSalarySettings',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.salary = result.data.salaryLevels
                this.monthlySalary = result.data.salaryMonth
                this.salaryType = result.data.type;
                this.baseSalaryH = result.data.monthlyBased.hours
                this.baseSalary = result.data.monthlyBased.salary
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
        }
    }
</script>