<template>
    <div class="w-full z-40 top-0 fixed h-screen mb-10 bg-gray-800">
    
    <h1 class="text-white text-center mt-5 font-semibold text-3xl">Please select a company</h1><br />
    <div class="w-96 mx-auto z-50 relative h-full overflow-y-scroll">
        <ol class="h-max w-full mt-0 pb-20">
            <li v-for="(branch, index) in branches" :key="index" 
                @click="selectedCompany(branch.companyID)"
                class="bg-gray-600 text-white p-1 px-4 rounded w-full cursor-pointer font-semibold text-center whitespace-nowrap m-1 mt-2">
                {{branch.companyName}}
            </li>
        </ol>
    </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                branch:null,
                branches:null
            }
        },
        methods: {
            selectedCompany(compID) {
                this.$cookies.set('c_id',compID)
                if (this.$route.params && (this.$route.params.prevRoute == 'Login')) {
                    location.replace("/metrics");
                } else if (this.$route.params.prevRoute) {
                    if (this.$route.params.prevRoute == "/settings") {
                        location.replace("/metrics");
                    } else {
                        location.replace(this.$route.params.prevRoute)
                    }
                } else {
                    location.replace("/metrics");
                }
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        this.branches = result.data.branches;
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                }, (error) => {
                console.log(error);
            })
        }
    }
</script>