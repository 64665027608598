import { createRouter,createWebHistory } from 'vue-router'
import Finance from '@/views/Finance.vue'
import ModelCosts from '@/views/ModelCosts.vue'
import SextingCosts from '@/views/SextingCosts.vue'
import Metrics from '@/views/Metrics.vue'
import Stexting from '@/views/Stexting.vue'
import Shifts from '@/views/Shifts.vue'
import Settings from '@/views/Settings.vue'
import Login from '@/views/Login.vue'
import Analytics from '@/views/Analytics.vue'
import Transactions from '@/views/Transactions.vue'
import Tutorials from '@/views/Tutorials.vue'
import models from '@/components/settings/models.vue'
import modelsForm from '@/components/settings/modelsForm.vue'
import settingsPanel from '@/components/settings/settingsPanel.vue'
import groups from '@/components/settings/groupsSettings.vue'
import employees from '@/components/settings/employees.vue'
import employeesForm from '@/components/settings/employeesForm.vue'
import salary from '@/components/settings/salary.vue'
import logs from '@/components/settings/logs.vue'  
import privileges from '@/components/settings/privileges.vue'
import shifts from '@/components/settings/shifts.vue'
import drop_shifts from '@/components/settings/drop_shifts.vue'
import myAccount from '@/components/settings/myAccount.vue'
import marketingSetting from "@/components/settings/marketingSetting.vue"
import archivedModels from "@/components/settings/archivedModels.vue"
import marketingAssistants from "@/components/settings/marketingAssistants.vue"
import marketingAssistantsGroups from "@/components/settings/marketingAssistantsGroups.vue"
import regCompany from '@/views/regCompany.vue'
import metricsSettings from '@/components/settings/metricsSettings.vue'
import taskScheduler from '@/components/settings/scheduler.vue'
import employeesArch from '@/components/settings/employeesArch.vue'
import moveModels from '@/components/settings/moveModels.vue'
import employeeBranch from '@/components/settings/employeesBranches.vue'
import ModelsPayments from '@/views/Models.vue'
import Marketing from '@/views/Marketing.vue'
import PromoPurchase from '@/views/PromoPurchase.vue'
import PromoTools from '@/views/PromoTools.vue'
import DropStats from '@/views/DropStats.vue'
import DropShifts from '@/views/DropShifts.vue'
import Summary from '@/views/Summary.vue'
import BranchPicker from '@/views/BranchPicker.vue'
import Tools from '@/views/Tools.vue'
import MetricsSummary from '@/views/MetricsSummary.vue'
import Payrolls from '@/views/Payrolls.vue'

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes: [
        {path: '/finance', name: 'Finance', component: Finance},
        {path: '/modelcosts', name: 'ModelCosts', component: ModelCosts},
        {path: '/sextingcosts', name: 'SextingCosts', component: SextingCosts},
        {path: '/payrolls', name: 'Payrolls', component: Payrolls},
        {path: '/tools', name: 'Tools', component: Tools},
        {path: '/marketing', name: 'Marketing', component: Marketing},
        {path: '/summary', name: 'Summary', component: Summary},
        {path: '/dropstats', name: 'DropStats', component: DropStats},
        {path: '/dropshifts', name: 'DropShifts', component: DropShifts},
        {path: '/promotools', name: 'PromoTools', component: PromoTools},
        {path: '/promopurchase', name: 'PromoPurchase', component: PromoPurchase},
        {path: '/metrics', name: 'Metrics', component: Metrics},
        {path: '/metricssummary', name: 'MetricsSummary', component: MetricsSummary},
        {path: '/analytics', name: 'Analytics', component: Analytics},
        {path: '/sexting', name: 'Stexting', component: Stexting},
        {path: '/shifts', name: 'Shifts', component: Shifts},
        {path: '/models', name: 'Models', component: ModelsPayments},
        {path: '/transactions', name: 'Transactions', component: Transactions},
        {path: '/tutorials', name: 'Tutorials', component: Tutorials},
        {path: '/regCompany', name: 'regCompany', component: regCompany},
        {path: '/branch', name: 'Branches', component: BranchPicker},
        {path: '/settings', name: 'Settings', component: Settings,
            children: [
            {
                path: '',
                component: settingsPanel
            },
            {
                name: 'menu',
                path: 'menu',
                component: settingsPanel
            },
            {   
                name: 'settingsGroups',
                path: 'groups',
                component: groups
            },
            {
                name: 'taskScheduler',
                path: 'taskScheduler',
                component: taskScheduler
            },
            {
                name: 'myAccount',
                path:'myAccount',
                component: myAccount
            },
            {
                name: 'marketingSetting',
                path:'marketingSetting',
                component: marketingSetting
            },
            {
                name: 'employeeBranch',
                path: 'employeeBranch',
                component: employeeBranch
            },
            {
                name: 'marketingAssistants',
                path:'marketingAssistants',
                component: marketingAssistants
            },
            {
                name: 'marketingAssistantsGroups',
                path: 'marketingAssistantsGroups',
                component: marketingAssistantsGroups
            },
            {
                name: 'models',
                path:'models',
                component: models
            },
            {
                name: 'archivedModels',
                path: 'archivedModels',
                component: archivedModels
            },
            {
                name: 'moveModels',
                path:'moveModels',
                component: moveModels
            },
            {
                name: 'salary',
                path:'salary',
                component: salary
            },
            {
                name: 'employees',
                path:'employees',
                component: employees
            },
            {
                name: 'employeesArchive',
                path:'archivedEmployees',
                component: employeesArch
            },
            {
                name:'employeesForm',
                path:'employees/form',
                component: employeesForm
            },
            {
                name:'modelsForm',
                path:'models/form',
                component: modelsForm
            },
            {
                name: 'logs',
                path:'logs',
                component: logs
            },
            {
                name: 'shifts',
                path:'shifts',
                component: shifts
            },
            {
                name: 'drop_shifts',
                path: 'drop_shifts',
                component: drop_shifts
            },
            {
                name:'metricsSettings',
                path:'metricsSettings',
                component: metricsSettings
            },
            {
                name: 'privileges', 
                path:'privileges',
                component: privileges
            }
        ]
        },
        {path: '/', name: 'Login', component: Login},
    ]
});

export default router