<template>
    <div class="text-white ml-28 text-2xl mt-5">
        <h1 class="float-left mr-5 text-xl text-white font-bold mt-1">Promotion tools</h1><br />
        <section class="grid grid-cols-4 gap-3 mr-5 mt-5">
            <div class="bg-gray-700 p-3 rounded">
                <fansly-logo />
            </div>
            <div class="bg-gray-700 p-3 rounded">
                <fansly-logo />
            </div>
            <div class="bg-gray-700 p-3 rounded">
                <fansly-logo />
            </div>
            <div class="bg-gray-700 p-3 rounded">
                <fansly-logo />
            </div>
            <div class="bg-gray-700 p-3 rounded">
                <fansly-logo class="mx-auto w-min"/>
            </div>
        </section>
    </div>
</template>

<script setup>
    import FanslyLogo from "@/components/fansly_logo.vue"
</script>

<script>
    export default {
        data() {
            return {
            }
        },
        components: {FanslyLogo}
    }
</script>