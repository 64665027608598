<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div> 
    <headerfilter :pageTitle="'Drop Stats'" @selectedGroup="selectedGroup = $event" />
    
    <section v-if="opravnenia != null && opravnenia[16] == 1 && myself && myself.ownMarketing == 1">
        
        <div class="whitespace-nowrap cursor-pointer w-min p-1 px-3 mt-24 ml-24 rounded-full text-gray-800 bg-indigo-300" @click="refresh()" title="Refresh"><i class="fas fa-redo"></i> Refresh</div>

        <section class="relative ml-20">
            <section class="flex">
                <section class="float-left" v-for="(category, gIndex) in marketingStats" :key="gIndex" >
                    <slot>
                        <section class="text-white tracking-widest uppercase block bg-transparent relative my-5 ml-4 w-max text-3xl font-bold float-left mr-4">{{gIndex}}</section>
                    </slot>
                    <section v-if="gIndex == 'organized'" class="relative clear-both w-max">
                        <div class="ml-3 overflow-x-scroll overflow-y-hidden mb-16 grid grid-cols-1">
                            <div class="float-left justify-center p-4 rounded mb-5 mr-8 ml-0 bg-gray-700" v-for="(partner,i) in category" :key="i">       
                                <div class="col-span-10">
                                    <div class="overflow-auto lg:overflow-visible relative">
                                        <div>
                                            <h2 class="text-white absolute bg-gray-800 p-1 px-2 rounded">Claims: {{partner.claims}}</h2>
                                            <h2 class="text-center font-bold mt-0 mb-5 text-gray-200">{{partner.name}}</h2>
                                        </div>
                                        <div @click="addFromClipboard(gIndex,i)" class="whitespace-nowrap cursor-pointer w-min p-1 -mt-11 px-3 absolute right-0 rounded-full text-gray-800 bg-indigo-300 text-base"><i class="fa-solid fa-paste"></i> Add from clipboard</div>
                                        <div class="text-white w-144" v-if="!partner.links.length>0">No links yet</div>
                                        <table v-else class="text-white border-collapse w-full border-separate space-y-4 text-sm whitespace-nowrap overflow-x-hidden h-80 block border-0">
                                            <thead class="text-gray-800 bg-white sticky top-1 z-9">
                                                <tr>
                                                    <th class="p-3 border-0 border-r-1">ID</th>
                                                    <th class="p-3 border-0 border-r-1">Model</th>
                                                    <th class="p-3 border-0 border-r-1">Model no.</th>
                                                    <th class="p-3 border-0 border-r-1">Branch</th>
                                                    <th class="p-3 border-0 border-r-1">Group</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Url</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Days</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Claims</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Created</th>
                                                    <!--<th class="p-3 text-center border-0">Action</th>-->
                                                </tr>
                                            </thead>
                                            <tbody class="border-0">
                                                <tr class="relative text-white border-0 mt-1 bg-gray-800 transition-all tranform"
                                                    @click="openLink(link.linkID,gIndex,i,index)" v-for="(link,index) in partner.links" :key="index">
                                                    
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="absolute bg-red-500 bg-opacity-90 z-10 text-black-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-if="!link.linkID && link.waiting">Validation failed!
                                                            <div class="absolute right-5 rounded-full bg-white text-xs top-2 text-black p-1 px-2 cursor-pointer" @click="deleteLink(gIndex,i,index)">DELETE</div>                                                   </div> 
                                                        <div class="absolute bg-indigo-200 bg-opacity-90 z-10 text-gray-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-else-if="!link.linkID"><i class="fa-solid fa-spinner animate-spin mr-2"></i> Waiting for validation <span class="font-semibold">(can take up to 3 hours)</span></div>
                                                        <span class="text-gray-400">{{link.linkID}}</span>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-24 truncate text-right" :title="link.modelName">{{link.modelName}}</div>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-16 truncate text-right">{{link.modelCode}}</div>
                                                    </td>     
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-16 truncate text-right" :title="link.modelBranch">{{link.modelBranch}}</div>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-16 truncate text-right" :title="link.modelGroup">{{link.modelGroup}}</div>
                                                    </td>                    
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-64 truncate text-right" :title=link.trialLink>{{link.displayLink}}</div>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        {{link.subscribeDays}}
                                                    </td>
                                                    <td class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        {{link.claimCount}}
                                                    </td>
                                                    <td class="p-2 leading-loose border-0 border-r-1 text-red-500 font-bold text-center cursor-pointer" v-if="link.expired == 1">LINK EXPIRED</td>
                                                    <td v-else class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">  
                                                        {{link.createdAt}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </section>
                    <section v-else class="mt-10">
                        <table class="text-white border-collapse w-full border-separate space-y-4 text-sm whitespace-nowrap overflow-x-hidden h-full block border-0">
                            <thead class="text-gray-800 bg-white sticky top-1 z-9">
                                <tr>
                                    <th class="p-3 border-0 border-r-1">ID</th>
                                    <th class="p-3 border-0 border-r-1">Model</th>
                                    <th class="p-3 border-0 border-r-1">Model no.</th>
                                    <th class="p-3 border-0 border-r-1">Branch</th>
                                    <th class="p-3 border-0 border-r-1">Group</th>
                                    <th class="p-3 text-center border-0 border-r-1">Url</th>
                                    <th class="p-3 text-center border-0 border-r-1">Days</th>
                                    <th class="p-3 text-center border-0 border-r-1">Claims</th>
                                    <th class="p-3 text-center border-0 border-r-1">Created</th>
                                    <!--<th class="p-3 text-center border-0">Action</th>-->
                                </tr>
                            </thead>
                            <tbody class="border-0">
                                <tr class="relative text-white border-0 mt-1 bg-gray-800 transition-all tranform"
                                    @click="openLink(link.linkID,gIndex,index,index)" v-for="(link,index) in category" :key="index">
                                    
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="absolute bg-red-500 bg-opacity-90 z-10 text-black-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-if="!link.linkID && link.waiting">Validation failed!
                                            <div class="absolute right-5 rounded-full bg-white text-xs top-2 text-black p-1 px-2 cursor-pointer" @click="deleteLink(gIndex,i,index)">DELETE</div>                                                   </div> 
                                        <div class="absolute bg-indigo-200 bg-opacity-90 z-10 text-gray-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-else-if="!link.linkID"><i class="fa-solid fa-spinner animate-spin mr-2"></i> Waiting for validation <span class="font-semibold">(can take up to 3 hours)</span></div>
                                        <span class="text-gray-400">{{link.linkID}}</span>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-24 truncate text-right" :title="link.modelName">{{link.modelName}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-16 truncate text-right">{{link.modelCode}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-16 truncate text-right" :title="link.modelBranch">{{link.modelBranch}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-16 truncate text-right" :title="link.modelGroup">{{link.modelGroup}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-64 truncate text-right" :title="link.trialLink">{{link.displayLink}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        {{link.subscribeDays}}
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        {{link.claimCount}}
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1 text-red-500 font-bold text-center cursor-pointer" v-if="link.expired == 1">LINK EXPIRED</td>
                                    <td v-else class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">  
                                        {{link.createdAt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </section>
            </section>
        </section>
    </section>

    <div v-else class="text-center mt-52 text-white">It seems you don't have access to this service</div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        props: ['selectedGroup'],
        components() {headerfilter},
        data() {
           return {
               dateFrom: null,
               dateTo: null,
               loading:false,
               marketingStats: null,
               saveClicked:false,
               modalData:null,
               indexesModal:[0,0,0],
               linkCategories:null,
               openModal:false,
               opravnenia:'0000000000000000000',
               myself:null
           }
        }, 
        watch: {
            dateFrom: function(newVal, oldVal) { // watch it
                if (this.modalData) {
                    this.linkData(this.modalData.linkID);
                }
            },
            dateTo: function(newVal, oldVal) { // watch it
                if (this.modalData) {
                    this.linkData(this.modalData.linkID);
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
            }
        },
        methods: {
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
            refresh() {
                this.loading = true;
                axios.get(baseURL+"marketingStats/", {
                params: {
                    action: "getTrialLinks",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    groupId: this.selectedGroup,
                }
                }).then((result) => {
                    this.marketingStats = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            deleteLink(gIndex,i,index) {
                if(confirm('This link will be deleted. Are you sure?')) {
                    axios.post(baseURL+"marketingPOST/", {                    
                        action: "deleteLink",
                        linkURL: this.marketingStats[gIndex].modelsCards[i].links[index].trialLink,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }, {headers: {'Content-type': 'application/x-www-form-urlencoded'}
                    }).then((result) => {
                        this.marketingStats[gIndex].modelsCards[i].links.splice(parseInt(index),1)
                    }, (error) => {
                        console.log(error);
                    })
                }
            },
            saveChanges() {
                this.saveClicked = true;
                if (this.modalData.active == true) {
                    this.modalData.active = 1;
                } else {
                    this.modalData.active = 0;
                }
                if (parseInt(this.modalData.categoryId)>0) {
                    axios.post(baseURL+"marketingPOST/", {                    
                        action: "saveLink",
                        data: this.modalData,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }, {headers: {'Content-type': 'application/x-www-form-urlencoded'}
                    }).then((result) => {
                        if (result.data == 'error - duplicate') {
                            alert('This link is already in use!')
                            this.openModal = false;
                        } else {
                            var i = this.indexesModal;
                            if (i[0] == 'organized') {
                                // moving inside categorized
                                if (this.modalData.categoryId != this.marketingStats['organized'][i[1]].links[i[2]].categId) {
                                    // category change
                                    let categID = this.modalData.categoryId;
                                    var indexKey = null;
                                    this.marketingStats['organized'].forEach(function(value,key) {
                                        if (value.id === parseInt(categID)) {indexKey = key;}
                                    });
                                    if (indexKey !== null) {
                                        this.marketingStats['organized'][i[1]].links[i[2]].categId = this.modalData.categoryId
                                        this.marketingStats['organized'][indexKey].links.push(this.marketingStats['organized'][i[1]].links[i[2]]);
                                    }

                                    // moving from the previous partner
                                    this.marketingStats['organized'][i[1]].links.splice([i[2]],1);
                                } 
                            } else {
                                // moving into the organized
                                let categID = this.modalData.categoryId;
                                var indexKey = null;
                                this.marketingStats['organized'].forEach(function(value,key) {
                                    if (value.id === parseInt(categID)) {
                                        indexKey = key;
                                    }
                                });
                                if (indexKey !== null) {
                                    this.marketingStats['organized'][indexKey].links.push(this.marketingStats['unorganized'][i[1]]);
                                }

                                // moving out of unorganized
                                this.marketingStats['unorganized'].splice(i[1],1);
                            }

                            // closing the modal window
                            this.openModal = false;
                        }
                    }, (error) => {
                        console.log(error);
                    }).finally(()=>this.saveClicked = false)
                    
                }
            },
            addFromClipboard(index,i) {
                setTimeout(async () => {
                    const text = await navigator.clipboard.readText();
                    if (text.substring(0,34) == 'https://onlyfans.com/action/trial/') {
                        this.openModal = true;
                        this.modalData = {id: this.marketingStats[index].modelsCards[i].id,modelName: this.marketingStats[index].modelsCards[i].name,
                            trialLink: text,displayLink: text,linkID: "",subscribeDays: "0",subscribeCount: "0",
                            claimCount: "0",linkNote: "",expiredAt: null,active: true,
                            linkStats: [],max: "0", createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),categoryId: '0',categoryName: ""};
                    } else {
                        alert('Link not valid');
                    }
                }, 200);
            },
            linkData(linkID) {
                axios.get(baseURL+"marketingStats/", {
                    params: {
                        action: "linkDetails",
                        userToken: this.$cookies.get('userToken'),
                        linkID: linkID,
                        companyID: this.$cookies.get('c_id'),
                        dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                        dateTo: moment(this.dateTo).format('YYYY-MM-DD')
                    }
                }).then((res) => {
                    if (res.data.success == true) {
                        this.openModal = true;
                        this.modalData = res.data.values;
                    } else {
                        alert("Access denied");
                    }
                }, (error) => {
                    console.log(error);
                })
            },
            openLink(linkID,gIndex,i,lIndex) {
                if (linkID) {
                    this.indexesModal = [gIndex,i,lIndex]                    
                    this.saveClicked = false;
                    this.linkData(linkID);
                }
            },
            dayFormat(origDate) {
                if (origDate != '0') {
                    return moment(origDate).format("DD.MM.YYYY") 
                } else {return 'Not expired'}
            },
        },
        created() {
            //this.loading = true;
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            var dateF = new Date();
            dateF.setDate(dateF.getDate() - 9);
            this.dateFrom = dateF;
            this.dateTo = new Date();
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                    this.myself = result.data.myself;
                    console.log(this.myself)
                }, (error) => {
                console.log(error);
            });
        },
        mounted() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            // this.loading = true;
        }
    }
</script>

<style scoped>
	.table {
		border-spacing: 0 15px;
        border:0;
	}

	i {
		font-size: 1rem !important;
	}

	.table tr {
		border-radius: 5px;
	}

	tr td:nth-child(n+11),
	tr th:nth-child(n+9) {
		border-radius: 0 5px 5px 0;
	}

	tr td:nth-child(1),
	tr th:nth-child(1) {
		border-radius: 5px 0 0 5px;
	}

    tbody tr:nth-child(odd) {
        background:#1a212c;
    }

    tbody tr:nth-child(even) {
        background:#2c333d;
    }
</style>