<template>
    <headerfilter :enable="loadingTables" :pageTitle="'Metrics summary'" :lrIndex="lrIndex" :lrData="lrData" @selectedEmpl="selectedEmpl = $event" @selectedBranch="selectedBranch = $event" @selectedModelN="selectedModelN=$event" @selectedModel="selectedModel = $event" @selectedGroupN="selectedGroupN=$event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event"/>
    
    <div class="mx-2 mb-24 sm:ml-24 mr-2" :class="{'mt-36':selectedModel, 'mt-24': !selectedModel}" v-if="opravnenia != null && opravnenia[0] == '1'">
        <section v-if="selectedModel" class="float-right md:clear-both lg:mx-auto w-max grid grid-cols-2 text-center font-semibold cursor-pointer">
            <div class="p-1 px-4 rounded-l" :class="{'bg-indigo-300 text-black':displayTab == 1, 'border border-indigo-300 text-indigo-300': displayTab == 2}" @click="dTab(1)">Metrics</div>
            <div class="p-1 px-4 rounded-r" :class="{'bg-indigo-300 text-black':displayTab == 2, 'border border-indigo-300 text-indigo-300': displayTab == 1}" @click="dTab(2)">History metrics</div>
        </section>

        <slot v-if="displayTab == 1">
            <metricsTable :summary="'1'" @lrIndex="lrIndex = $event" @lrData="lrData = $event" :selectedBranch="selectedBranch" :blockedColumns="blockedColumns" :selectedModelN="selectedModelN" :selectedModel="selectedModel" :permissions="opravnenia" :selectedGroup="selectedGroup" :selectedGroupN="selectedGroupN" :dateFrom="dateFrom" :dateTo="dateTo" />
            <br />
            <metricsPeriodTable :summary="'1'" v-if="loadingTables > 0" :period="'week'" :selectedBranch="selectedBranch" :selectedGroup="selectedGroup" :blockedColumns="blockedColumns" :selectedModel="selectedModel"/>
            <div v-else class="text-white text-center"><i class="fa-xl fa-solid fa-spinner animate-spin"></i></div>
            <br />
            <metricsPeriodTable :summary="'1'" v-if="loadingTables > 1" :period="'month'" :selectedBranch="selectedBranch" :selectedGroup="selectedGroup" :blockedColumns="blockedColumns" :selectedModel="selectedModel" />
            <div v-else class="text-white text-center"><i class="fa-xl fa-solid fa-spinner animate-spin"></i></div>
        </slot>
        <slot v-else>
            History data
        </slot>
    </div>
    <div v-else class="text-center mt-52 text-white">It seems you don't have access to this service</div>

    <branch v-if="branches" :branches="branches"/>
</template>

<script setup>
    import branch from '@/components/branch.vue'
    import metricsTable from '@/components/metrics/metricsTable.vue'
    import metricsPeriodTable from '@/components/metrics/metricsPeriodTable.vue'    
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        props:['lrIndex','lrData','selectedModel', 'selectedBranch', 'selectedModelN','selectedGroupN','selectedEmpl','selectedGroup','dateFrom','dateTo'],
        data() {
            return {
                opravnenia:'00',
                branches:null,
                blockedColumns: {renewOn:0,subsPaid:0},
                displayTab:1,
                loadingTables:0
            }
        },
        components() {
            branch,
            metricsTable,
            metricsPeriodTable,
            headerfilter
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                }, (error) => {
                console.log(error);
            })
        },
        mounted() {
            setTimeout(() => {
                this.loadingTables = 1;
            }, 1000);
            setTimeout(() => {
                this.loadingTables = 2;
            }, 1000); 
        },
        methods: {
            dTab(w) {
                this.displayTab = w;
            },
        }
    }
</script>

<style scoped>
.v3dp__datepicker {
    float:left;
    margin-left:10px;
    margin-top:-7px;
}
</style>