<template>
    <headerfilter :pageTitle="'Drop Shifts'" @selectedModel="selectedModel = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event" />
    <div class="mainPanel mt-3">
        <div id="parent" class="mt-14">
            <table v-if="shiftsData && shiftsData.length>0">
                <thead id="myhead">
                    <tr class="border border-indigo-300">
                        <th class="h-16 text-indigo-200 bg-gray-700 font-bold text-center pt-5 border-r-2 border-indigo-200">Models </th>
                        <slot v-if="shiftsData.length>0">
                            <slot v-for="(sh,index) in shiftsData[0].assistants[0].models[0].data" :key="index">
                                
                            <th class="bg-gray-700 text-indigo-200" v-bind:class="{'rightBorder': shiftCIndex == borderCount}" v-for="(shiftColumn,shiftCIndex) in sh.dayData" :key="shiftCIndex">
                                <span>{{sh.dayName}} - {{formatDate(sh.date)}}</span><br>
                                <span>{{parseInt(shiftCIndex)+1 + '. shift'}}</span>
                            </th>
                            </slot>
                        </slot>
                    </tr>
                </thead>
                <tbody id="mybody" onscroll="fixscroll()" v-if="shiftsData.length>0">
                    <slot v-for="(team,gInd) in shiftsData" :key="gInd">
                        <!--<span v-if="team.assistants.models.length>0 && !selectedGroup" class="sticky bg-indigo-300 font-semibold top-0 w-max z-11 p-1 px-3 left-0">{{team.teamName}}</span>-->
                        <span class="sticky bg-indigo-300 font-semibold top-0 w-max z-11 p-1 px-3 left-0">{{team.teamName}}</span>
                        <slot v-for="(assistant,asInd) in team.assistants" :key="asInd">
                            <span class="sticky bg-indigo-300 font-semibold top-0 w-max z-11 p-0.5 px-2 text-sm left-0 left-20 rounded">{{assistant.name}}</span>
                            <slot v-for="(model,ind) in assistant.models" :key="ind">
                                <!--<div class="w-full h-5" :class="{'hidden': ind>0 && model.team == group.models[parseInt(ind)-1].team || model.team == team.teamName}"></div>-->
                                <tr class="border border-gray-200">
                                    <td class="border border-indigo-200 bg-gray-700 text-indigo-200 bg-gray-600 z-10">
                                        <strong class="text-sm">{{model.name}}</strong><br>{{model.code}}
                                        <!--<span class="absolute left-24 -top-4 bg-indigo-300 text-sm text-black z-50 p-0.5 px-2 rounded whitespace-nowrap" :class="{'hidden': ind>0 && model.team == group.models[parseInt(ind)-1].team || model.team == group.groupName}">{{model.team}}</span>-->
                                    </td>
                                    <slot v-if="shiftsData.length>0">
                                        <slot v-for="(sh,index) in model.data" :key="index">
                                            <slot v-if="(permissions && permissions[4] == '1')">
                                                <td class="cursor-pointer bg-gray-600 border-indigo-200 text-white"
                                                    v-bind:class="{'rightBorder': i == borderCount}" 
                                                    @click="showModal(gInd,ind,index,i,asInd)"
                                                    v-for="(shift,i) in sh.dayData" :key="i">
                                                    <section v-if="shift.shiftField && shift.shiftField.employeeName" class="block relative align-text-bottom text-sm text-gray-900 bg-gray-200 h-14 rounded-r-lg text-left pl-1" :style="'border-top:8px solid ' + shift.shiftField.employeeColor">
                                                        <i class="absolute p-2 -mt-1 text-red-600 text-xs fas fa-times" v-on:click.stop="removeShift(gInd,shift.shiftField.shiftID,ind,index,i,asInd)"></i>
                                                        <span class="inline-block whitespace-nowrap w-28 overflow-ellipsis font-semibold overflow-hidden"> {{shift.shiftField.employeeName}}</span><br />
                                                        <span class="inline-block whitespace-nowrap w-32 overflow-ellipsis overflow-hidden text-xs"> {{shift.shiftField.employeeGroupName}}</span>
                                                        <span v-if="shift.shiftField.note" class="absolute bg-white rounded whitespace-nowrap mt-1 right-2 text-xs bg-gray-700 text-white font-semibold px-1">Note <i class="far fa-sticky-note"></i></span>
                                                        <span class="absolute text-xs left-1 rounded-lg font-semibold">{{formatTime('20-10-2020 '+shift.shiftField.timeFrom,'HH:mm')}} - {{formatTime('20-10-2020 '+shift.shiftField.timeTo,'HH:mm')}}</span>
                                                        <span v-if="shift.hours != shift.shiftH" class="absolute bg-white rounded whitespace-nowrap mt-1 right-1 text-xs bg-indigo-300 text-black font-semibold px-1">{{shift.hours}}h</span>
                                                    </section>
                                                </td>
                                            </slot>
                                            <slot v-else>
                                                <td class="bg-gray-600 border-indigo-200 text-white"
                                                    v-bind:class="{'rightBorder': i == borderCount}" 
                                                    v-for="(shift,i) in sh.dayData" :key="i">
                                                    <section v-if="shift.shiftField && shift.shiftField.employeeName" class="block relative align-text-bottom text-sm text-gray-900 bg-gray-200 h-12 rounded-r-lg mt-1 text-left pl-2">
                                                        <span class="inline-block whitespace-nowrap w-28 overflow-ellipsis font-semibold overflow-hidden"> {{shift.shiftField.employeeName}}</span><br />
                                                        <span class="inline-block whitespace-nowrap w-32 overflow-ellipsis overflow-hidden text-xs"> {{shift.shiftField.employeeGroupName}}</span>
                                                        <span v-if="shift.shiftField.note" class="absolute bg-white rounded whitespace-nowrap mt-1 right-2 text-xs bg-gray-700 text-white font-semibold px-1">Note <i class="far fa-sticky-note"></i></span>
                                                    </section>
                                                </td>
                                            </slot>
                                        </slot>
                                    </slot>
                                </tr>
                            </slot>
                        </slot>
                    </slot>
                </tbody>
            </table>
            <span v-else class=" ml-4 text-white text-center text-2xl font-bold">No models</span> 
            <div style="clear:both"></div>
        </div>

        <div class="bg-green-300 rounded-full text-black p-2 fixed top-0 px-6 left-1/2" v-if="saved">Saved</div>
        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   
        
        <slot v-if="showModalkey && permissions && permissions[4] == '1'">
            <div class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40" @click="showModalkey = false"></div>
            <section class="fixed block items-center mt-2 p-10 bg-gray-300 z-50 rounded-lg w-2/5 left-64 top-14">        
                <h2 class="text-black font-bold text-xl text-center -mt-2">Assign an employee</h2>
                <i class="absolute mr-4 p-4 fas fa-times" @click="showModalkey = false"></i>
                <h2 class="text-gray font-semibold text-center"> {{modalField.den}} - {{modalField.date}} (Shift no.  {{modalField.shiftNo}})</h2>
                <h2 class="text-gray font-bold text-center">Model: {{modalField.modelName}}</h2>
                <h2 class="text-gray-500 font-semibold text-center">{{modalField.group}}</h2>
                <hr class="mt-2 border-black" noshade><br />
                <div @click="forAll = !forAll" class="cursor-pointer w-max"><input type="checkbox" class="w-max" v-model="forAll" /> Valid for the entire assistant</div>
                <label>Name</label><br />
                <select id="empl_id" class="emplList" v-model="modalField.empl">
                    <option value="0">Choose droper</option>
                    <option v-for="(v,i) in droperList" :key="i" :value="v.id">{{v.name}}</option>
                </select>
                <br>
                <label>Note</label><br />
                <textarea id="shifts_note" placeholder="Add note" v-model="modalField.note" class="w-full h-16"></textarea><br />

                <slot v-if="1==2">
                    <label>Shift lenght</label><br />
                    <div class="text-black font-semibold">{{modalField.hours}} hours</div>
                </slot>

                <div v-if="1==2" class="float-left mr-5">
                    <label>Time from</label><br />
                    <select class="w-20 rounded p-1" v-model="modalField.timeFrom" @change="changeHours()">
                        <option v-for="index in 24" :key="index" :value="index">{{index}}:00</option>
                    </select>
                </div>

                <div v-if="1==2">
                    <label>Time to</label><br />
                    <select class="w-20 rounded p-1" v-model="modalField.timeTo" @change="changeHours()">
                        <option v-for="index in 24" :key="index" :value="index">{{index}}:00</option>
                    </select>
                </div>

                <div class="errorMessage" v-if="errorMessage"></div>
                <button @click="saveShift(modalField.teamID)" class="delay-75 duration-75 transition-all float-right right-10 bottom-10 p-2 px-7 text-lg rounded-full text-black font-medium bg-indigo-300 uppercase">
                    {{"SAVE"}}
                </button>
            </section>
        </slot>
    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        props: ['selectedModel','selectedGroup','dateFrom','dateTo','selectedTeam'],
        components() {headerfilter},
        data() {
            return {
                droperList: [],
                modelsList: [],
                shiftTimes: [],
                helpFrom: null,
                helpTo: null,
                forAll:true,
                borderCount: 0,
                days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                mounted: false,
                showModalkey:false,
                shiftsData: {model_pairID: null,name: '',code: '',
                    data:{date:null,dayName:null,timeFrom:null, timeTo:null, hours: 0, shiftNo: null, shiftField:{employeeID:null,employeeName:null,note:null,salary:true}}
                },
                loading:false,
                recalcLoading:false,
                errorMessage: null,
                saved:false,
                myself:null,
                permissions:null,
                timeZone: moment().utcOffset(),
                modalField: {modelPairID:null,timeFrom:null,timeTo:null,modelName:null,den:null,date:null,hours:0,empl:0,note:null,indexes:null,shiftNo:null,teamID:null,salary:null},
            }
        },
        methods: {
            changeHours() {
                if(this.mounted) { 
                    if (this.modalField.timeFrom < this.modalField.timeTo) {
                        this.modalField.hours = this.modalField.timeTo - this.modalField.timeFrom;
                    } else if (this.modalField.timeFrom > this.modalField.timeTo) {
                        this.modalField.hours = (24 - this.modalField.timeFrom) + this.modalField.timeTo;
                    } else {
                        alert('The time difference must be at least 1 hour');
                        this.modalField.hours = 1;
                        if (this.modalField.timeTo != 24) {
                            this.modalField.timeTo=this.modalField.timeTo + 1; 
                        } else { 
                            this.modalField.timeTo = 1;
                        }
                    }
                }
            },
            saveShift(teamID) {
                /*if (this.modalField.timeFrom == 24) {this.modalField.timeFrom = 0}
                if (this.modalField.timeTo == 24) {this.modalField.timeTo = 0}

                this.modalField.timeFrom = this.formatTimeBack(moment(this.modalField.date,'DD.MM.YYYY').format("YYYY-MM-DD") + ' ' + this.modalField.timeFrom + ':00:00',"HH:mm","H")
                this.modalField.timeTo = this.formatTimeBack(moment(this.modalField.date,'DD.MM.YYYY').format("YYYY-MM-DD") + ' ' + this.modalField.timeTo + ':00:00',"HH:mm","H")
                */
                axios.post(baseURL+"shiftsPOST/", {
                    action: "saveShiftTimeTableDROP",
                    modelObject: this.modalField,
                    forAll:this.forAll,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                        this.saved = true;
                    setTimeout(() => {
                        this.saved = false;
                    }, 1000);

                    if (this.forAll === true) {
                        this.shiftsData[this.modalField.indexes['gIndex']].assistants[this.modalField.indexes['asInd']].models.forEach((values,index) => {
                            this.shiftsData[this.modalField.indexes['gIndex']].assistants[this.modalField.indexes['asInd']].models[index].data[this.modalField.indexes['index']].dayData[this.modalField.indexes['i']].shiftField = 
                            {'shiftID':result.data[0], 'employeeID': this.modalField.empl,'employeeName': result.data[1],'note': this.modalField.note, 'employeeColor': result.data[2]};
                        });
                    } else {
                        this.shiftsData[this.modalField.indexes['gIndex']].assistants[this.modalField.indexes['asInd']].models[this.modalField.indexes['ind']].data[this.modalField.indexes['index']].dayData[this.modalField.indexes['i']].shiftField = 
                        {'shiftID':result.data[0], 'employeeID': this.modalField.empl,'employeeName': result.data[1],'note': this.modalField.note, 'employeeColor': result.data[2]};
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
                this.showModalkey = false;
            },
            removeShift(gIndex,shift_id,ind,index,i,asInd) {
                if (confirm('Do you want to remove this shift?')) {
                    axios.post(baseURL+"shiftsPOST/", {                    
                        action: "deleteShiftDROP",
                        shift_id: shift_id,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data == 'OK') {
                            this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField = null;
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            showModal(gIndex,ind,index,i,asInd) {
                this.showModalkey = true;
                this.modalField = {modelPairID:null,group:null,modelName:null,den:null,date:null,hours:0,empl:0,note:null,indexes:null,shiftNo:null}
                this.modalField.teamID = this.shiftsData[gIndex].assistants[asInd].models[ind].teamID
                this.modalField.modelName = this.shiftsData[gIndex].assistants[asInd].models[ind].name
                this.modalField.group = this.shiftsData[gIndex].teamName + ' - ' + this.shiftsData[gIndex].assistants[asInd].name;
                this.modalField.modelPairID = this.shiftsData[gIndex].assistants[asInd].models[ind].pair_id
                this.modalField.den = this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayName
                this.modalField.date = moment(this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].date).format("DD.MM.YYYY")
                this.modalField.shiftNo = this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftNo
                if (this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField) {
                    this.modalField.salary = Boolean(this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField.giveSalary)
                    this.modalField.timeFrom = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField.timeFrom,"H");
                    this.modalField.timeTo = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField.timeTo,"H");
                    if (this.modalField.timeFrom == 0) {this.modalField.timeFrom = 24} if (this.modalField.timeTo == 0) {this.modalField.timeTo = 24}
                } else {
                    this.modalField.salary = true
                    this.modalField.timeFrom = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].timeFrom,"H");
                    this.modalField.timeTo = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].timeTo,"H");
                    if (this.modalField.timeFrom == 0) {this.modalField.timeFrom = 24} if (this.modalField.timeTo == 0) {this.modalField.timeTo = 24}
                }
                this.modalField.hours = 8;
                this.modalField.indexes = {gIndex,ind,index,i,asInd}
                if (this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField.employeeID) {
                    this.modalField.empl = this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField.employeeID
                    this.modalField.note = this.shiftsData[gIndex].assistants[asInd].models[ind].data[index].dayData[i].shiftField.note
                }
                else {this.modalField.empl=0}
            },
            determinZoneDST(original) {
                var tzone = 0;
                if (moment().isDST()) {
                    if (moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = parseInt(this.timeZone)
                    } else {
                        tzone = (parseInt(this.timeZone)/60-1)*60
                    }
                } else {
                    if (moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = (parseInt(this.timeZone)/60+1)*60
                    } else {
                        tzone = parseInt(this.timeZone)
                    }
                }
                return tzone;
            },
            formatTime(original,finalFormat) {
                if (moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            formatTimeBack(original,finalFormat) {
                if (moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            formatDate(original) {
                return moment(original, "YYYY-MM-DD").format("DD.MM.YY");
            },
            loadDropers() {
                axios.get(baseURL+"lists/", {
                params: {
                    build: 'sextarsListActive',
                    groupFilter: this.selectedGroup,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
                }).then((result) => {
                    this.droperList = result.data
                }, (error) => {
                    console.log(error);
                });
            }
        },
        watch: { 
            selectedModel: function(newVal, oldVal) { // watch it
            },
            dateFrom: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    axios.get(baseURL+"shiftsGET/", {
                        params: {
                            build: 'shiftsDrop',
                            teamId: null,
                            assistant: null,
                            dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                            dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                            userToken: this.$cookies.get('userToken'),
                            companyID: this.$cookies.get('c_id')
                        }
                    }).then((result) => {
                        this.shiftsData = result.data
                    }, (error) => {
                        console.log(error);
                    }).finally(() => this.loading = false);
                }
            },
            dateTo: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    axios.get(baseURL+"shiftsGET/", {
                    params: {
                        build: 'shiftsDrop',
                        teamId: null,
                        assistant: null,
                        dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                        dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                    }).then((result) => {
                    this.shiftsData = result.data
                    //console.log("data",this.shiftsData);
                    }, (error) => {
                        console.log(error);
                    }).finally(() => this.loading = false);
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                console.log("team changed")
                this.loading = true;
                axios.get(baseURL+"shiftsGET/", {
                    params: {
                    build: 'shiftsDrop',
                    teamId: this.selectedGroup,
                    assistantId: null,
                    dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
                }).then((result) => {
                   this.shiftsData = result.data
                   }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                console.log("assistant changed")
                this.loading = true;
                axios.get(baseURL+"shiftsGET/", {
                    params: {
                    build: 'shiftsDrop',
                    teamId: this.selectedGroup,
                    assistantId: this.selectedTeam,
                    dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
                }).then((result) => {
                   this.shiftsData = result.data
                   }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
        },
        mounted() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: "header"
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.permissions = result.data.permissions;}
                    else {this.opravnenia = '00';}
                    this.myself = result.data.myself;
                }, (error) => {
                console.log(error);
            });

            axios.get(baseURL+"lists/", {
                params: {
                    build: 'droperList',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.droperList = result.data
            }, (error) => {
                console.log(error);
            });

            this.loading = true;
            axios.get(baseURL+"shiftsGET/", {
                params: {
                build: 'shiftsDrop',
                teamId: null,
                assistant: null,
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                userToken: this.$cookies.get('userToken'),
                companyID: this.$cookies.get('c_id')
            }
            }).then((result) => {
                this.shiftsData = result.data
                }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
            this.mounted = true
        }
    }
</script>

<style scoped>
    .rightBorder {
        border-left-color: rgb(199,210,254) !important;
        border-left-width: 4px !important;
    }

#parent {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 98%;
  height: 89vh;
  overflow: hidden;
}

table {
    border:1px solid rgb(199,210,254);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    max-height:100%;
    border-collapse: collapse;
    overflow: hidden;
}

thead {
  /*
  Grow thead automatically to fit content, don't shrink it
  proportionately to the body.
  */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
  Keep header columns aligned with useless scrollbar.
  For IE11, use "dead area" color to hide scrollbar functions
  */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

    /*
    For Webkit, use "dead area" color to hide scrollbar functions
    TODO: on Chrome/Safari for Mac, scrollbars are not shown anyway and
    this creates an extra block. No impact on iOS Safari.
    */
    thead::-webkit-scrollbar { display: block; background-color: #ccc; }
    thead::-webkit-scrollbar-track { background-color: #ccc; }

    /* Scroll the actual tbody (second child on all browsers) */
    tbody {
    display: block;
    overflow: scroll;
    }

    /* IE11 adds an extra tbody, have to hide it */
    tbody:nth-child(3) { display: none; }

    /* The one caveat, a hard-set width is required. */
    td, th {
    width: 10em;
    min-width: 10em;
    padding: 0.3em;
    }

    td:first-child,th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left:0;
    }
</style>