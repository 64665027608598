<template>
<div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">Saved</div>

    <div class="w-full mb-4 relative">
      <router-link to="../models" v-if="$route.params.edit">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <router-link to="../../settings" v-else>
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-1 text-xl text-gray-200 font-semibold text-center" v-if="$route.params.edit">Edit a model</h2>
      <h2 class="mt-1 text-xl text-gray-200 font-semibold text-center" v-else>Add a model</h2>
    </div>

    <div v-if="displayForm == true">
        <div class="float-left mr-10 shadow-2xl p-5 px-10 rounded-lg text-gray-300">
            <h5 class="text-center font-semibold mb-2 text-l">Account FREE</h5>
            <div>
                <label>Name</label><br />
                <input type="text" placeholder="Name" v-model="modelData.free.name"><i class="text-red-500 absolute -ml-4 mt-3 fas fa-exclamation" v-if="submitted && !modelData.free.name"></i><br />
                <label>Account ID (auth_id)</label><br />
                <input id="modelNamePaid" type="text" placeholder="Onlyfans ID" v-bind="{'readonly':modelData.free.accountId.filled}" v-bind:class="{'bg-gray-400': modelData.free.accountId.filled}" v-model="modelData.free.accountId.value"><br />
                <label>Email adress</label><br />
                <input type="text" placeholder="Email" v-model="modelData.free.email"><i class="text-red-500 absolute -ml-4 mt-3 fas fa-exclamation" v-if="submitted && !modelData.free.email"></i><br />
                <label>Password</label>
                <div class="relative">
                    <input v-bind:type="displayPass.f ? 'text' : 'password'" placeholder="Password" v-model="modelData.free.password">
                    <i class="text-red-500 absolute -ml-4 mt-3 fas fa-exclamation" v-if="submitted && !modelData.free.password"></i>
                    <i class="fa-regular absolute text-gray-700 right-2 mt-1.5 text-center p-1 cursor-pointer" :class="{'fa-eye':!displayPass.f,'fa-eye-slash':displayPass.f}" @click="this.displayPass.f = !this.displayPass.f" ></i><br /> 
                </div>
            </div> 
        </div>
        
        <div class="float-left shadow-2xl p-5 px-10 rounded-lg text-gray-300 relative opacity-100">
            <section v-if="modelPairID">
                <h4 class="block transform -rotate-45 text-center absolute text-white font-bold z-50 ml-8 mt-20 text-xl leading-8" @click="paidToArchiveAndBack('1')" v-if="modelData.paid.active == 0">
                    Archived<br><small class="cursor-pointer rounded-lg text-black p-1 px-4 bg-indigo-300">Return from archive</small>
                </h4>
                <div v-else @click="paidToArchiveAndBack('0')" class="absolute bg-indigo-300 rounded-full p-2 px-3 cursor-pointer text-black right-4 top-4" title="Archive model data">
                    <i class="fas fa-archive"></i>
                </div>
            </section>
            <h5 class="text-center font-semibold mb-2 text-l">Account PAID</h5>
            <div v-bind:class="{'opacity-50': modelData.paid.active == 0}">
                <label>Name</label><br />
                <input id="modelNamePaid" type="text" placeholder="Name" v-model="modelData.paid.name"><br />
                <label>Account ID (auth_id)</label><br />
                <input id="modelNamePaid" type="text" placeholder="Onlyfans ID" v-bind="{'readonly':modelData.paid.accountId.filled}" v-bind:class="{'bg-gray-400': modelData.paid.accountId.filled}" v-model="modelData.paid.accountId.value"><br />
                <label>Email adress</label><br />
                <input id="modelEmailPaid" type="text" placeholder="Email" v-model="modelData.paid.email"><br />
                <label>Password</label>
                <div class="relative">
                    <input v-bind:type="displayPass.p ? 'text' : 'password'" placeholder="Password" v-model="modelData.paid.password">
                    <i class="fa-regular absolute text-gray-700 right-2 mt-1.5 text-center p-1 cursor-pointer" :class="{'fa-eye':!displayPass.p,'fa-eye-slash':displayPass.p}" @click="this.displayPass.p = !this.displayPass.p"></i>
                </div>
            </div> 
        </div>
        <div class="float-left w-52"></div>
        <div class="clear-both"></div>
        <div class="mt-2 ml-10 text-gray-300 float-left mr-5">
            <label>Group</label><br />
            <select class="mt-1" v-model="modelData.groupID" v-if="noGroups">
                <option value="0">All groups</option>
            </select>
            <select class="mt-1 clear-both" v-model="modelData.groupID" v-if="groupList.length>1" @change="switchView($event, $event.target.selectedIndex)">
                <option v-for="(group,index) in groupList" :key="index" :value="group.id">{{group.name}}</option>
            </select>
            <span v-else class="text-white mr-5 text-lg mt-1 text-semibold">{{ singleGroup.name }}<br /></span><br />
        </div>

        <!-- if group has teams -->
        <slot class="mt-2 float-left" v-if="groupList && (groupList.length>0 && groupIndex!=null) || modelData.teamID">
            <div class="mt-2" v-if="(groupIndex!=-1 && groupList[groupIndex].hasChildren == true) || modelData.teamID">
                <label>Team</label><br />
                <select class="mt-1 clear-both" v-model="modelData.teamID">
                    <option value="0">All teams</option>
                    <option v-for="(team,index) in groupList[groupIndex].children" :key="index" :value="team.id">{{ team.name }}</option>
                </select>
            </div>
        </slot>
        <div class="clear-both"></div>

        <div class="ml-10 text-gray-300">
            <label>Code*</label><br />
            <select v-model="countryCode" @change="changedCountryCode()" class="float-left mr-4">
                <option value="0">Pick a country...</option>
                <option v-for="(country,cInd) in countryList" :key="cInd" :value="cInd">{{country.name}}</option>
            </select>
            <div class="float-left w-14 text-right mr-1 p-1.5 px-3 bg-gray-300 text-black rounded">{{codePartOne}}</div>
            <div class="float-left mt-1 mr-1">-</div>
            <div class="float-left w-16 h-9 text-right mr-1 p-1.5 px-3 bg-gray-300 text-black rounded">{{modelData.code}}</div> * Second part of the code is generated
        </div>

        <div class="ml-10 text-gray-300 clear-both">
            <label>Commission</label><br />
            <input id="modelCommission" type="number" placeholder="Commission %" v-model="modelData.commission"><br />
            <span class="text-sm">Only in case of % commission. In case of a FIX, leave blank</span>
        </div>
        

        <button @click="saveModel()" class="delay-75  duration-75 transition-all absolute right-20 bottom-10 p-4 px-7 text-lg rounded-full text-black font-medium bg-indigo-300 uppercase">
            <i class="fa-solid fa-spinner animate-spin" v-if="loading"></i> {{buttonValue}}
        </button>
    </div>
    <div v-else-if="createdForm">
        <h2 class="text-center font-bold mt-10 text-indigo-300">To access this form, please add groups</h2>
        <router-link :to="{ name: 'settingsGroups'}">
            <button class="mt-10 p-2 px-5 rounded-full bg-indigo-300 font-normal text-black">Add groups</button>
        </router-link>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                groupList: null,
                modelData: {
                    free: {name: null, email:null, password:null, accountId: {filled:false, value:null}},
                    paid: {active: true, name: null, email:null, password:null, accountId: {filled:false, value:null}},
                    code: null,
                    commission: null,
                    countryCode:0,
                    groupID:1,
                    teamID:null
                },
                loading: false,
                buttonValue: 'Create account',
                singleGroup: [],
                saved:false,
                submitted:false,
                createdForm:false,
                groupIndex:null,
                noGroups:false,
                displayForm:false,
                displayPass: {f:false,p:false},
                countryList:null,
                modelPairID:this.$route.params.modelID,
                countryCode:0,
                codePartOne: "001"
            }
        },
        methods: {
            pad(num, size) {
                while (num.length < size) num = "0" + num;
                return num;
            },
            changedCountryCode() {
                this.codePartOne = this.pad(this.countryList[this.countryCode].code,3);
            },
            switchView(event, selectedIndex) {
                this.groupIndex = selectedIndex;
                this.modelData.teamID = null;
            },
            saveModel() {
                this.submitted = true;
                
                if (this.modelData.free.name && this.modelData.free.email && this.modelData.free.password && this.countryCode) {
                    this.modelData.countryCode = this.countryList[this.countryCode].id;
                    this.loading = true
                    if (this.modelPairID) {
                        this.buttonValue = 'Updating...'
                        setTimeout(() => (this.loading = false, this.buttonValue = 'Update account'), 1000);
                    }else {
                        this.buttonValue = 'Creating...'
                        setTimeout(() => (this.loading = false, this.buttonValue = 'Create account'), 1000);
                    }
                    if (!(this.groupList.length>1)) {
                        this.modelData.groupID = this.singleGroup.id;
                    }

                    axios.post(baseURL+"settingspost/", {                    
                        action: "saveModel",
                        modelObject: this.modelData,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data.message) {
                            alert(result.data.message)
                        } else {
                            if(!this.modelPairID) {this.modelData = {free: {name: null,accountId: {filled:false,value:null}},paid: {name: null,accountId: {filled:false,value:null}},code: null}; this.submitted = false;}
                            this.saved = true
                            setTimeout(() => (this.saved = false), 2000)
                        }
                    }, (error) => {
                        console.log(error);
                    });
                } else {
                    alert('Please fill out required fields');
                }
            },
            paidToArchiveAndBack(active) {
                axios.post(baseURL+"settingspost/", {                    
                    action: "paidToArchiveAndBack",
                    modelID: this.modelData.paid.id,
                    active: active,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.modelData.paid.active = active;
                }, (error) => {
                    console.log(error);
                });
            },
            getTeamsAndGroups() {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'groups',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.groupList = result.data;
                    if(result.data.length) {
                        this.singleGroup.name = result.data[0].name;
                        this.singleGroup.id = result.data[0].id;
                        for(var i=0;i<this.groupList.length;i++) {
                            console.log(this.modelData.groupID, this.groupList[i].id);
                            if (this.modelData.groupID == this.groupList[i].id) {
                                this.groupIndex = i;
                                break;
                            } else {
                                this.groupIndex = 0;
                            }
                        }
                        this.displayForm = true;
                    } else {
                        this.displayForm = false;
                        this.noGroups = true;
                        this.singleGroup.id = 0;
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.createdForm = true);
            }
        },
        created() {
            //get list of countries
            axios.get(baseURL+"lists/", {
                params: {
                    build:"getCountryCodes",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {                
                this.countryList = result.data;

                if (this.modelPairID) {
                    this.buttonValue = 'Update account'
                    axios.get(baseURL+"settingsget/", {
                        params: {
                            modelPairID: this.modelPairID,
                            action: 'editOneModel',
                            userToken: this.$cookies.get('userToken'),
                            companyID: this.$cookies.get('c_id')
                        }
                    }).then((result) => {
                        if (result.data) {
                            this.modelData = result.data;
                            this.countryCode = parseInt(result.data.countryCode)<998 ? parseInt(result.data.countryCode)-1 : this.countryList.length - (999 - parseInt(result.data.countryCode)) -1;
                            this.codePartOne = this.pad(this.countryList[String(parseInt(this.countryCode))].code,3);
                            //after account is loaded
                            this.getTeamsAndGroups();
                        }
                    }, (error) => {
                        console.log(error);
                    });
                } else {
                    // add a new model
                    this.getTeamsAndGroups();
                }

            }, (error) => {
                console.log(error);
            });

        }
    }
</script>