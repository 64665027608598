<template>
<div>
        <div id="parent" class="mb-36 mx-2 sm:mx-0 sm:pl-24 mt-20 w-full pr-2 h-full pt-40 sm:pt-24 -mt-24 sm:-mt-4">
            <table v-if="shiftsData && shiftsData.length>0">
                <thead id="myhead">
                    <tr class="border border-indigo-300">
                        <th class="h-16 text-indigo-200 bg-gray-700 font-bold text-center pt-5 border-r-2 border-indigo-200">Models</th>
                        <slot v-if="shiftsData.length>0 && shiftsData[0].models[0]">
                            <slot v-for="(sh,index) in shiftsData[0].models[0].data" :key="index">
                                <th class="bg-gray-700 text-indigo-200" v-bind:class="{'rightBorder': shiftCIndex == borderCount}" v-for="(shiftColumn,shiftCIndex) in sh.dayData" :key="shiftCIndex">
                                    <span>{{sh.dayName}} - {{formatDate(sh.date)}}</span><br>
                                    <span>{{formatTime(sh.date + " " + shiftColumn.timeFrom,"H") + ':00 - ' + formatTime(sh.date + " " + shiftColumn.timeTo,"H")}}:00</span>
                                </th>
                            </slot>
                        </slot>
                    </tr>
                </thead>
                <tbody id="mybody" onscroll="fixscroll()" v-if="shiftsData.length>0">
                    <slot v-for="(group,gInd) in shiftsData" :key="gInd">
                        <span v-if="group.models.length>0 && !selectedGroup" class="sticky bg-indigo-300 font-semibold top-0 w-max z-13 p-1 px-3 left-0">{{group.groupName}}</span>
                        <slot v-for="(model,ind) in group.models" :key="ind">
                            <div class="w-full h-5" :class="{'hidden': ind>0 && model.team == group.models[parseInt(ind)-1].team || model.team == group.groupName}"></div>
                            <tr class="border border-gray-200">
                                <td class="border border-indigo-200 bg-gray-700 text-indigo-200 bg-gray-600 z-12 w-5"><strong class="text-sm">{{model.name}}</strong><br>{{model.code}}<span class="absolute left-20 -top-4 bg-indigo-300 text-sm text-black z-50 p-0.5 px-1 rounded whitespace-nowrap" :class="{'hidden': ind>0 && model.team == group.models[parseInt(ind)-1].team || model.team == group.groupName}">{{model.team}}</span></td>
                                <slot v-if="shiftsData.length>0">
                                    <slot v-for="(sh,index) in model.data" :key="index">
                                        <slot v-if="(permissions && permissions[4] == '1') && ((myself.position == 12 && myself.group == model.teamID) || (myself.position!=12))">
                                            <td class="cursor-pointer relative bg-gray-600 border-indigo-200 text-white w-28 h-16"
                                                v-bind:class="{'rightBorder': i == borderCount}"
                                                v-for="(shift,i) in sh.dayData" :key="i">
                                                <section class="absolute w-full h-16 top-0" @click="showModal(gInd,ind,index,i,0,true)">
                                                </section>
                                                <slot v-for="(field,fI) in shift.shiftField" :key="fI">
                                                    <section @click="showModal(gInd,ind,index,i,fI,false)" class="absolute top-1 z-2 align-text-bottom text-sm text-gray-900 bg-gray-200 h-14 rounded-r-lg text-left pl-1" :class="{'bg-red-200/80':shift.shiftField.length>1}"
                                                        :style="[parseInt(field.timeFrom)-parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)<-1
                                                        ? {'border-top':'8px solid ' + field.employeeColor , 'width': + 95/8*parseFloat(field.hours) + '%','margin-left': + 100/8*(parseInt(field.timeFrom)+24-parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)) + '%'}
                                                        : {'border-top':'8px solid ' + field.employeeColor , 'width': + 95/8*parseFloat(field.hours) + '%','margin-left': + 100/8*(parseInt(field.timeFrom)-parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)) + '%'}]">
                                                        <i class="absolute p-2 -mt-1 text-red-600 text-xs fas fa-times" v-on:click.stop="openDialog(gInd,field.shiftID,ind,index,i,fI,model.teamID)"></i>
                                                        <span class="inline-block whitespace-nowrap w-4/5 overflow-ellipsis font-semibold overflow-hidden"> {{field.employeeName}}</span><br />
                                                        <span class="inline-block whitespace-nowrap w-2/3 overflow-ellipsis overflow-hidden text-xs"> {{field.employeeGroupName}}</span>
                                                        <span v-if="field.note" class="absolute rounded whitespace-nowrap mt-1 right-8 text-xs bg-gray-700 text-white font-semibold px-1"><i class="far fa-sticky-note"></i></span>
                                                        <span v-if="field.hours != shift.shiftH" class="absolute rounded whitespace-nowrap mt-1 right-1 text-xs bg-indigo-300 text-black font-semibold px-1">{{field.hours}}h</span>
                                                    </section>
                                                </slot>
                                            </td>
                                        </slot>
                                        <slot v-else>
                                            <td class="bg-gray-600 relative w-28 h-16 border-indigo-200 text-white"
                                                v-bind:class="{'rightBorder': i == borderCount}"
                                                v-for="(shift,i) in sh.dayData" :key="i">
                                                <section class="absolute w-full h-16 top-0">
                                                </section>
                                                <slot v-for="(field,fI) in shift.shiftField" :key="fI">
                                                    <section class="absolute top-1 align-text-bottom text-sm text-gray-900 z-2 bg-gray-200 h-14 rounded-r-lg text-left pl-1" :class="{'bg-red-200/80':shift.shiftField.length>1}"
                                                        :style="[parseInt(field.timeFrom)-parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)<-1 ? {'border-top':'8px solid ' + field.employeeColor , 'width': + 95/8*parseFloat(field.hours) + '%','margin-left': + 100/8*(parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)-parseInt(field.timeFrom)-24)*-1 + '%'} : {'border-top':'8px solid ' + field.employeeColor , 'width': + 95/8*parseFloat(field.hours) + '%','margin-left': + 100/8*(parseInt(field.timeFrom)-parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)) + '%'}]">
                                                        {{100/8*(parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)-parseInt(field.timeFrom)-24)*-1}}
                                                        <span class="inline-block whitespace-nowrap w-4/5 overflow-ellipsis font-semibold overflow-hidden"> {{field.employeeName}}</span><br />
                                                        <span class="inline-block whitespace-nowrap w-2/3 overflow-ellipsis overflow-hidden text-xs"> {{field.employeeGroupName}}</span>
                                                        <span v-if="field.note" class="absolute bg-white rounded whitespace-nowrap mt-1 right-8 text-xs bg-gray-700 text-white font-semibold px-1">Note <i class="far fa-sticky-note"></i></span>
                                                        <span v-if="field.hours != shift.shiftH" class="absolute bg-white rounded whitespace-nowrap mt-1 right-1 text-xs bg-indigo-300 text-black font-semibold px-1">{{field.hours}}h</span>
                                                    </section>
                                                </slot>
                                            </td>
                                        </slot>
                                    </slot>
                                </slot>
                            </tr>
                        </slot>
                    </slot>
                </tbody>
            </table>
            <span v-else class=" ml-4 text-white text-center text-2xl font-bold">No models</span>
            <div style="clear:both"></div>
        </div>

        <div class="bg-green-300 rounded-full text-black p-2 fixed top-0 px-6 left-1/2" v-if="saved">Saved</div>
        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>

        <slot v-if="showModalkey && permissions && permissions[4] == '1'">
            <div class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40" @click="showModalkey = false"></div>
            <section class="fixed block items-center mt-2 p-10 bg-gray-300 z-50 rounded-lg w-full sm:w-2/5 sm:left-64 top-14" style="min-width:400px">
                <h2 class="text-black font-bold text-xl text-center -mt-2">Assign an employee</h2>
                <i class="absolute mr-4 p-4 fas fa-times" @click="showModalkey = false"></i>
                <h2 class="text-gray font-semibold text-center"> {{modalField.den}} - {{modalField.date}} (Shift: {{formatTime(modalField.date + ' ' + helpFrom,"HH:mm")}} - {{formatTime(modalField.date + ' ' + helpTo,"HH:mm")}}) </h2>
                <h2 class="text-gray font-bold text-center">{{modalField.modelName}} - <span class="text-gray-500 font-normal text-center">{{modalField.group}}</span></h2>
                <h2 class="text-gray text-sm font-semibold text-center" v-if="modalField.assignedBy">Assigned by: {{modalField.assignedBy}} {{modalField.assignedAt}}</h2>
                <hr class="mt-2 border-black" noshade><br />
                <label>Name</label><br />
                <select id="empl_id" class="emplList" v-model="modalField.empl">
                    <option value="0">Choose employee</option>
                    <option v-for="(v,i) in employeeList" :key="i" :value="v.id">{{v.name}}</option>
                </select>
                <br>
                <label>Note</label><br />
                <textarea id="shifts_note" placeholder="Add note" v-model="modalField.note" class="w-full h-16"></textarea><br />

                <div @click="modalField.salary = !modalField.salary" class="cursor-pointer w-max mt-2 mb-2 leading-none"><input type="checkbox" class="w-max" v-model="modalField.salary" /> Give salary for this shift <small><br />(* if unchecked, sextar will not get salary for this shift)</small></div>

                <div v-if="!modalField.shiftID && modalField.split" class="bg-red-400 text-white p-2 px-3 rounded text-center font-bold my-2 mt-4">Don't forget to change the time</div>
                <label>Shift lenght</label><br />
                <div class="text-black font-semibold">{{modalField.hours}} hours</div>

                <div class="float-left mr-5">
                    <label>Time from</label><br />
                    <select class="w-16 rounded p-1" v-model="modalField.timeFrom" @change="changeHours()">
                        <option v-for="index in 24" :key="index" :value="index">{{index}}</option>
                    </select>
                    <select class="w-12 rounded p-1 ml-1" v-model="modalField.timeFromMinutes" @change="changeHours()">
                        <option v-for="(v,i) in 6" :key="i" :value="i*10">{{i}}0</option>
                    </select>
                </div>
                <div>
                    <label>Time to</label><br />
                    <select class="w-16 rounded p-1" v-model="modalField.timeTo" @change="changeHours()">
                        <option v-for="index in 24" :key="index" :value="index">{{index}}</option>
                    </select>
                    <select class="w-12 rounded p-1 ml-1" v-model="modalField.timeToMinutes" @change="changeHours()">
                        <option v-for="(v,i) in 6" :key="i" :value="i*10">{{i}}0</option>
                    </select>
                </div>

                <div class="errorMessage" v-if="errorMessage"></div>
                <section class="grid grid-cols-2 gap-2 mt-3">
                    <button @click="forAll = false,saveShift(modalField.teamID)" class="delay-75 duration-75 transition-all bottom-10 p-1 w-full rounded-full text-indigo-500 font-medium border-2 border-indigo-500 uppercase">Save for one model</button>
                    <button @click="forAll = true,saveShift(modalField.teamID)" class="delay-75 duration-75 transition-all p-1 w-full rounded-full text-white font-medium bg-indigo-500 uppercase">Save for the whole team</button>
                </section>
            </section>
        </slot>

        <slot v-if="dialogWindow">
            <div class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40" @click="dialogWindow = false"></div>
            <section class="absolute sm:inset-1/4 block mt-2 p-10 bg-gray-300 z-50 rounded-lg w-full sm:w-96 mx-auto h-48">
                <i @click="dialogWindow = false" class="fa-solid fa-times"></i>
                Do you want to remove shift on one model or on the whole team?<br />
                <section class="text-center w-full grid grid-cols-2 mt-5 gap-3">
                    <div @click="removeShift(false)" class="p-1 rounded border-2 border-indigo-300 text-indigo-500 font-semibold cursor-pointer transition-all duration-500 hover:scale-105">One model</div>
                    <div v-if="modalButtons == 1" @click="removeShift(true)" class="bg-indigo-300 rounded p-1 font-semibold cursor-pointer transition-all duration-500 hover:scale-105">Whole team</div>
                </section>
            </section>
        </slot>
</div>
</template>

<script>
    export default {
        props: ['selectedModel','selectedGroup','dateFrom','dateTo','selectedTeam','permissions','myself'],
        data() {
            return {
                employeeList: [],
                modelsList: [],
                shiftTimes: [],
                helpFrom: null,
                helpTo: null,
                forAll:true,
                borderCount: 0,
                days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                mounted: false,
                showModalkey:false,
                shiftsData: {model_pairID: null,name: '',code: '',
                    data:{date:null,dayName:null,timeFrom:null, timeTo:null, hours: 0, shiftNo: null, shiftField:{employeeID:null,employeeName:null,note:null,salary:true}}
                },
                loading:false,
                recalcLoading:false,
                errorMessage: null,
                saved:false,
                dialogWindow: false,
                modalButtons:1,
                indexesHelp:[],
                timeZone: moment().utcOffset() === 480 ? moment().utcOffset() - 60 : moment().utcOffset(),
                modalField: {split:false,modelPairID:null,shiftID:null,assignedAt:null,assignedBy:null,timeFrom:null,timeFromMinutes:0,timeTo:null,timeToMinutes:0,modelName:null,den:null,date:null,hours:0,empl:0,note:null,indexes:null,shiftNo:null,teamID:null,salary:null},
            }
        },
        methods: {
            changeHours() {
                if(this.mounted) {
                    if (parseInt(this.modalField.timeFrom) < parseInt(this.modalField.timeTo)) {
                        this.modalField.hours = this.modalField.timeTo - this.modalField.timeFrom;
                    } else if (parseInt(this.modalField.timeFrom) > parseInt(this.modalField.timeTo)) {
                        this.modalField.hours = (24 - parseInt(this.modalField.timeFrom)) + parseInt(this.modalField.timeTo);
                    } else {
                        alert('The time difference must be at least 1 hour');
                        this.modalField.hours = 1;
                        if (this.modalField.timeTo != 24) {
                            this.modalField.timeTo=this.modalField.timeTo + 1;
                        } else {
                            this.modalField.timeTo = 1;
                        }
                    }
                    if (this.modalField.timeToMinutes != 0) {this.modalField.hours = (parseFloat(this.modalField.hours) + this.modalField.timeToMinutes/60).toFixed(2)}
                    if (this.modalField.timeFromMinutes != 0) {this.modalField.hours = (parseFloat(this.modalField.hours) - this.modalField.timeFromMinutes/60).toFixed(2)}
                }
            },
            saveShift(teamID) {
                if (this.modalField.timeFrom == 24) {this.modalField.timeFrom = 0}
                if (this.modalField.timeTo == 24) {this.modalField.timeTo = 0}

                this.modalField.timeFrom = this.formatTimeBack(moment(this.modalField.date,'DD.MM.YYYY').format("YYYY-MM-DD") + ' ' + this.modalField.timeFrom + ':'+ this.modalField.timeFromMinutes + ':00',"HH:mm","H")
                this.modalField.timeTo = this.formatTimeBack(moment(this.modalField.date,'DD.MM.YYYY').format("YYYY-MM-DD") + ' ' + this.modalField.timeTo + ':'+ this.modalField.timeToMinutes + ':00',"HH:mm","H")

                console.log(this.modalField);

                axios.post(baseURL+"shiftsPOST/", {
                    action: "saveShiftTimeTable",
                    modelObject: this.modalField,
                    forAll: this.forAll,
                    summer: moment(this.modalField.date,'DD.MM.YYYY').isDST(),
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    var groupIndex = this.modalField.indexes['gIndex']; // index of the 1 layer - group
                    var columnIndex = this.modalField.indexes['index']; // index of the day
                    var shiftIndex = this.modalField.indexes['i']; //id of the shift of the day
                    var shiftFiledIndex = this.modalField.indexes['fI'];
                    if (result.data && result.data.success == true) {
                        if (this.forAll) {
                            this.shiftsData[groupIndex].models.forEach((values,index) => {
                                if(values.teamID == teamID) {
                                    if (result.data.action == 'insert') {
                                        this.shiftsData[groupIndex].models[index].data[columnIndex].dayData[shiftIndex].shiftField.push({'timeFrom':this.modalField.timeFrom,'timeTo':this.modalField.timeTo,'shiftID':result.data.id, 'employeeID': this.modalField.empl, 'employeeGroupName':  result.data.teamName,'employeeName': result.data.name,'note': this.modalField.note,'employeeColor': result.data.color,'hours':parseFloat(this.modalField.hours),'giveSalary': this.modalField.salary,'assignedBy':result.data.assignedBy});
                                        this.shiftsData[groupIndex].models[index].data[columnIndex].dayData[shiftIndex].hours = parseFloat(this.modalField.hours);
                                    } else {
                                        this.shiftsData[groupIndex].models[index].data[columnIndex].dayData[shiftIndex].shiftField.push({'timeFrom':this.modalField.timeFrom,'timeTo':this.modalField.timeTo,'shiftID':result.data.id, 'employeeID': this.modalField.empl, 'employeeGroupName':  result.data.teamName,'employeeName': result.data.name,'note': this.modalField.note,'employeeColor': result.data.color,'hours':parseFloat(this.modalField.hours)});
                                        this.shiftsData[groupIndex].models[index].data[columnIndex].dayData[shiftIndex].hours = parseFloat(this.modalField.hours);
                                    }
                                }
                            });
                        } else if (!this.forAll && result.data.action == 'insert') {
                            this.shiftsData[groupIndex].models[this.modalField.indexes['ind']].data[columnIndex].dayData[shiftIndex].shiftField.push({'timeFrom':this.modalField.timeFrom,'timeTo':this.modalField.timeTo,'shiftID':result.data.id, 'employeeID': this.modalField.empl, 'employeeGroupName':  result.data.teamName,'employeeName': result.data.name,'note': this.modalField.note,'employeeColor': result.data.color,'hours':parseFloat(this.modalField.hours)});
                            this.shiftsData[groupIndex].models[this.modalField.indexes['ind']].data[columnIndex].dayData[shiftIndex].hours = parseFloat(this.modalField.hours);
                        } else {
                            this.shiftsData[groupIndex].models[this.modalField.indexes['ind']].data[columnIndex].dayData[shiftIndex].shiftField[shiftFiledIndex] = {'timeFrom':this.modalField.timeFrom,'timeTo':this.modalField.timeTo,'shiftID':result.data.id, 'employeeID': this.modalField.empl, 'employeeGroupName':  result.data.teamName,'employeeName': result.data.name,'note': this.modalField.note, 'giveSalary': this.modalField.salary, 'employeeColor': result.data.color,'hours':parseFloat(this.modalField.hours)};
                            this.shiftsData[groupIndex].models[this.modalField.indexes['ind']].data[columnIndex].dayData[shiftIndex].hours = parseFloat(this.modalField.hours);
                        }
                    } else {
                        alert("Server error, shift not added. Please refresh the page");
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
                this.showModalkey = false;
            },
            openDialog(gIndex,shift_id,ind,index,i,fI,teamid) {
                var emplIDTest = 0;
                this.modalButtons = 1;
                this.shiftsData[gIndex].models.forEach((values,indexL) => {
                    if(values.teamID == teamid) {
                        //console.log(this.shiftsData[gIndex].models[indexL].data[index].dayData[i].shiftField);
                        if (this.shiftsData[gIndex].models[indexL].data[index].dayData[i].shiftField[fI]) {
                            if (emplIDTest == 0) {
                                emplIDTest = this.shiftsData[gIndex].models[indexL].data[index].dayData[i].shiftField[fI].employeeID;
                            } else {
                                if (emplIDTest != this.shiftsData[gIndex].models[indexL].data[index].dayData[i].shiftField[fI].employeeID) {
                                    this.modalButtons = 0;
                                }
                            }
                        }
                    }
                });

                this.dialogWindow = true;
                this.indexesHelp = [gIndex,shift_id,ind,index,i,fI,teamid];
            },
            removeShift(whole) {
                if (this.indexesHelp) {
                    axios.post(baseURL+"shiftsPOST/", {
                        action: "deleteShift",
                        wholeShift: whole,
                        shift_id: this.indexesHelp[1],
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data && result.data.success == true) {
                            if (whole == true) {
                                this.shiftsData[this.indexesHelp[0]].models.forEach((values,index) => {
                                    if(values.teamID == this.indexesHelp[6]) {
                                        this.shiftsData[this.indexesHelp[0]].models[index].data[this.indexesHelp[3]].dayData[this.indexesHelp[4]].shiftField.splice(this.indexesHelp[5],1);
                                    }
                                });
                            } else {
                                this.shiftsData[this.indexesHelp[0]].models[this.indexesHelp[2]].data[this.indexesHelp[3]].dayData[this.indexesHelp[4]].shiftField.splice(this.indexesHelp[5],1);
                            }
                        } else {
                            alert("Server error, shift not deleted. Please refresh the page");
                        }
                    }, (error) => {
                        console.log(error);
                    });
                    this.dialogWindow = false;
                }
            },
            showModal(gIndex,ind,index,i,fI,shiftEmpty) {
                this.showModalkey = true;
                this.modalField = {modelPairID:null,group:null,modelName:null,den:null,date:null,hours:0,empl:0,note:null,indexes:null,shiftNo:null}
                this.modalField = {
                    teamID: this.shiftsData[gIndex].models[ind].teamID,
                    modelName: this.shiftsData[gIndex].models[ind].name,
                    group: this.shiftsData[gIndex].models[ind].group,
                    modelPairID: this.shiftsData[gIndex].models[ind].model_pairID
                }
                this.modalField.den = this.shiftsData[gIndex].models[ind].data[index].dayName
                this.modalField.date = moment(this.shiftsData[gIndex].models[ind].data[index].date).format("DD.MM.YYYY")
                this.modalField.shiftNo = this.shiftsData[gIndex].models[ind].data[index].dayData[i].shiftNo
                this.modalField.timeFromMinutes = 0;
                this.modalField.timeToMinutes = 0;
                var shiftField = this.shiftsData[gIndex].models[ind].data[index].dayData[i].shiftField ? this.shiftsData[gIndex].models[ind].data[index].dayData[i].shiftField[fI] : null;
                if (shiftField && shiftEmpty == false) {
                    this.modalField.shiftID = shiftField.shiftID ? shiftField.shiftID : null;
                    this.modalField.assignedBy = !shiftField ? '' : shiftField.assignedBy;
                    this.modalField.assignedAt = !shiftField ? '' : shiftField.assignedAt;
                    this.modalField.salary = Boolean(shiftField.giveSalary)
                    this.helpFrom = moment(shiftField.timeFrom, "HH:mm:ss").format("HH:mm")
                    this.modalField.timeFromMinutes = parseInt(moment(shiftField.timeFrom, "HH:mm:ss").format("mm"));
                    this.modalField.timeToMinutes = parseInt(moment(shiftField.timeTo, "HH:mm:ss").format("mm"));
                    this.helpTo = shiftField.timeTo
                    this.modalField.timeFrom = this.formatTime(this.modalField.date + ' ' + shiftField.timeFrom,"H");
                    this.modalField.timeTo = this.formatTime(this.modalField.date + ' ' + shiftField.timeTo,"H");
                    if (this.modalField.timeFrom == 0) {this.modalField.timeFrom = 24} if (this.modalField.timeTo == 0) {this.modalField.timeTo = 24}
                    this.forAll = false;
                } else {
                    this.modalField.salary = true
                    this.helpFrom = this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeFrom;
                    this.helpTo = this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeTo
                    this.modalField.timeFrom = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeFrom,"H");
                    this.modalField.timeTo = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeTo,"H");
                    if (this.modalField.timeFrom == 0) {this.modalField.timeFrom = 24} if (this.modalField.timeTo == 0) {this.modalField.timeTo = 24}
                    this.forAll = true;
               }
               this.modalField.indexes = {gIndex,ind,index,i,fI}
               if (shiftField && shiftEmpty == false) {
                   this.modalField.empl = shiftField.employeeID
                   this.modalField.note = shiftField.note
               }
               else {this.modalField.empl=0}
               this.modalField.split = shiftField ? true : false;
               this.modalField.hours = this.timeDiff(this.modalField.timeFrom,this.modalField.timeTo)
            },
            timeDiff(from,to) {
                if (parseFloat(from)<parseFloat(to)) { return parseFloat(to)-parseFloat(from);}
                else { return 24-parseFloat(from)+parseFloat(to);}
            },
            determinZoneDST(original) {
                var tzone = 0;
                if (moment().isDST()) {
                    if (moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = parseInt(this.timeZone)
                    } else {
                        tzone = (parseInt(this.timeZone)/60-1)*60
                    }
                } else {
                    if (moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = (parseInt(this.timeZone)/60+1)*60
                    } else {
                        tzone = parseInt(this.timeZone)
                    }
                }
                return tzone;
            },
            formatTime(original,finalFormat) {
                if (moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            formatTimeBack(original,finalFormat) {
                if (moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        } else {
                            return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            formatDate(original) {
                return moment(original, "YYYY-MM-DD").format("DD.MM.YY");
            },
            loadSextars() {
                axios.get(baseURL+"lists/", {
                params: {
                    build: 'sextarsListActive',
                    groupFilter: this.selectedGroup,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
                }).then((result) => {
                    this.employeeList = result.data
                    console.log(this.employeeList);
                }, (error) => {
                    console.log(error);
                });
            },
            loadShifts(team) {
                this.loading = true;
                axios.get(baseURL+"shiftsGET/", {
                params: {
                    build: 'shiftsAll',
                    groupId: this.selectedGroup,
                    teamId: team,
                    dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
                }).then((result) => {
                    this.shiftsData = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            }
        },
        watch: {
            dateFrom: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loadShifts(this.selectedTeam);
                }
            },
            dateTo: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loadShifts(this.selectedTeam);
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                this.loadShifts(null);
                this.loadSextars()
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                this.loadShifts(this.selectedTeam);
            },
        },
        created() {
            axios.get(baseURL+"settingsget/", {
                params: {
                    action: 'shiftsSettings',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then(result => {
                if (result.data) {
                    this.shiftTimes = result.data;
                    this.borderCount = this.shiftTimes.length -1;
                } else {
                    this.nodata = true;
                }
            }, (error) => {
                console.log(error);
            });
            this.loadSextars();
        },
        mounted() {
            axios.get(baseURL+"lists/", {
                params: {
                    build: 'models',
                    groupFilter: this.selectedGroup,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.modelsList = result.data
            }, (error) => {
                console.log(error);
            });
            this.mounted = true
        }
    }
</script>

<style scoped>
    .rightBorder {
        border-right-color: rgb(199,210,254) !important;
        border-right-width: 4px !important;
    }
    #parent {
    position: absolute;
    overflow: hidden;
    }
    table {
        border:1px solid rgb(199,210,254);
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        max-height:100%;
        border-collapse: collapse;
        overflow: hidden;
    }

    thead {
        flex: 1 0 auto;
        display: block;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-base-color: #ccc;
        scrollbar-face-color: #ccc;
        scrollbar-highlight-color: #ccc;
        scrollbar-track-color: #ccc;
        scrollbar-arrow-color: #ccc;
        scrollbar-shadow-color: #ccc;
    }
    thead::-webkit-scrollbar { display: block; background-color: #ccc; }
    thead::-webkit-scrollbar-track { background-color: #ccc; }
    tbody {
        display: block;
        overflow: scroll;
    }
    tbody:nth-child(3) { display: none; }
    td, th {
        min-width: 9em;
        max-width: 9em;
        padding: 0.3em;
    }

    td:first-child,th:first-child {
        position: sticky;
        position: -webkit-sticky;
        left:0;
    }
</style>
