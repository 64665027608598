<template>
    <div class="text-sm sm:text-base mt-2">
        <section class="text-white tracking-widest uppercase leading-none block bg-transparent relative ml-6 w-max text-base font-bold sm:float-left" :class="{'sm:text-xl':layout == 2,'sm:text-2xl mt-2.5':layout == 1}">
            {{headline}}
            <div v-if="subHeadline" class="font-light text-sm mt-0">{{subHeadline}}</div>
        </section>
        <section class="bg-gray-700 w-20 text-white rounded ml-5 p-2 px-2 sm:px-4 float-left leading-5">
            <label>Hours:</label><br /><span class="font-semibold">{{parseFloat(panelData.hours).toFixed(2)}}</span>
        </section>
        <section class="bg-gray-700 w-16 text-white rounded ml-2 p-2 px-2 sm:px-4 float-left leading-5">
            <label>Shifts:</label><br /><span class="font-semibold">{{panelData.shifts}}</span>
        </section>
        <section class="bg-gray-700 w-32 text-white rounded ml-2 p-2 px-2 sm:px-4 float-left leading-5">
            <label>Earnings:</label><br /><span class="font-semibold">$ {{String(parseFloat(panelData.earnings.toFixed(2)).toLocaleString('en-US')).replace(/,/g,' ')}}</span>
        </section>
        <section class="bg-gray-700 w-32 text-white rounded ml-2 p-2 px-2 sm:px-4 float-left leading-5">
            <label>Salaries:</label><br /><span class="font-semibold">$ {{String(parseFloat(panelData.salary.toFixed(2)).toLocaleString('en-US')).replace(/,/g,' ')}}</span>
        </section>
        <section class="bg-gray-700 text-white rounded ml-2 p-2 px-2 sm:px-4 float-left leading-5">
            <label>Cost ratio:</label><br /><span class="font-semibold">{{panelData.costRatio ? panelData.costRatio.toFixed(2) : null}} %</span>
        </section>
        <div class="clear-both"></div>
        <hr v-if="lineSeparator" class="mt-2 mb-1 border-0 border-t border-gray-700" />
    </div>
</template>

<script>
    export default {
        props: ['panelData','headline','subHeadline','lineSeparator','layout'],
    }
</script>