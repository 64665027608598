<template>
    <tr v-if="metricsData && displayColumns && blockedColumns" class="bg-gray-800/70 border-t-2 border-white">
        <td class="z-9 bg-gray-800">Summary</td>
        <td v-if="!selectedModel"></td>
        <td>{{addSpace(metricsData.free.fans_active)}}</td>
        <slot v-if="displayColumns.free">
            <td :class="{'text-green-400':metricsData.free.fans_expired<0,'text-red-400':metricsData.free.fans_expired>0}">{{addSpace(metricsData.free.fans_expired)}}</td>
            <td :class="{'text-green-400':metricsData.free.new>0,'text-red-400':metricsData.free.new<0}">{{addSpace(metricsData.free.new)}}</td>
        </slot>
        <td :class="{'text-green-400':metricsData.free.change>0,'text-red-400':metricsData.free.change<0}">{{addSpace(metricsData.free.change)}}</td>
        <td>{{addSpace(metricsData.paid.fans_active)}}</td>
        <slot v-if="displayColumns.paid">
            <td :class="{'text-green-400':metricsData.paid.fans_expired<0,'text-red-400':metricsData.paid.fans_expired>0}">{{addSpace(metricsData.paid.fans_expired)}}</td>
            <td :class="{'text-green-400':metricsData.paid.new>0,'text-red-400':metricsData.paid.new<0}">{{addSpace(metricsData.paid.new)}}</td>
        </slot>
        <td :class="{'text-green-400':metricsData.paid.change>0,'text-red-400':metricsData.paid.change<0}">{{addSpace(metricsData.paid.change)}}</td>
        <td v-if="blockedColumns.renewOn == 1 && displayRenewOn">{{metricsData.paid.renewOn}}</td>
        <td>{{metricsData.free.messages}}</td>
        <td>{{metricsData.paid.messages}}</td>
        <td v-if="blockedColumns.subsPaid == 1 && displaySubs">$ {{addSpace(metricsData.paid.subsEarnings.toFixed(2))}}</td>
        <td>$ {{addSpace((metricsData.free.transactionSum - metricsData.free.reverseSum).toFixed(2))}}</td>
        <td>$ {{addSpace((metricsData.paid.transactionSum - metricsData.paid.reverseSum).toFixed(2))}}</td>
        <td>$ {{addSpace(((metricsData.free.transactionSum - metricsData.free.reverseSum) + (metricsData.paid.transactionSum - metricsData.paid.reverseSum)).toFixed(2))}}</td>
        <slot v-if="!selectedModel">
            <td :class="{'text-green-400':metricsData.free.change>0,'text-red-400':metricsData.free.change<0}">{{addSpace((metricsData.free.change/metricsData.models).toFixed(2))}}</td>
            <td>$ {{addSpace((((metricsData.free.transactionSum - metricsData.free.reverseSum) + (metricsData.paid.transactionSum - metricsData.paid.reverseSum))/metricsData.models).toFixed(2))}}</td>
        </slot>
    </tr>
</template>

<script>
    export default {
        props: ['displayRenewOn','displaySubs','blockedColumns','selectedModel','metricsData','displayColumns'],
        methods: {
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
        }
    }
</script>

<style scoped>
    @import "@/assets/css/metrics.css"
</style>