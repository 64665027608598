<template>
    <div @mouseover="hoverCommentIndex = true" @mouseleave="hoverCommentIndex = null">
        <slot v-if="selectedModel != null && editable == true">
            <inputMetricsEdit v-if="edit" @editM="editM = $event" :vvalue='editData' vmodel="editValue" pholder="Comment" inputType="text" class="text-white" @editValue="editValue = $event" />
            <section v-else>{{addSpace(displayData)}}</section>
            <section v-if="showOptions.index == optionIndex && hideCom" v-bind:class="{'-mt-20':ind>2,'-ml-36':dataName=='eEarnP','ml-16':dataName!='eEarnP'}" :style="'transform: translate3d('+optionPosition.x+'px,'+optionPosition.y+'px,0);'" 
            class="text-center leading-9 z-30 rounded absolute w-30 h-max bg-gray-100 border-white border-2 text-black font-normal">
                <slot v-if="sOp == 1">
                    <slot v-if="commentData[0]">
                        <div class="cursor-pointer hover:bg-gray-300 p-1 px-3 rounded" @click="sOp = 0,oldComment = commentData[0].value"><i class="fa-pen text-sm fa-solid mr-1"></i> Edit comment</div><hr />
                        <div class="cursor-pointer hover:bg-gray-300 p-1 px-3 rounded" @click="resetComments(), removeComment(commentData[0].commentID,ind,mType.toLowerCase(),column)"><i class="fa-trash-can text-sm fa-solid mr-1"></i> Remove comment</div><hr />
                    </slot>
                    <slot v-else><div class="cursor-pointer hover:bg-gray-300 p-1 px-3 rounded" @click="sOp = 0"><i class="fa-solid fa-plus mr-1"></i> Add a comment</div><hr /></slot>
                    <div class="cursor-pointer hover:bg-gray-300 p-1 px-3 rounded" @click="changeIndex++, $emit('hideF', [changeIndex,false]), edit = true">Change the value</div>
                </slot>
                <span v-else class="text-left">
                    <label class="text-sm text-black font-regular">Comment:</label><br />
                    <niceInput :vvalue='oldComment' vmodel="newComment" pholder="Comment" inputType="text" class="text-left text-black mb-2 mx-1.5" @newComment="newComment = $event" />
                    <button @click="resetComments()" class="border-black border bg-gray-300 mr-5 ml-1.5 px-5 rounded-lg mb-2 -mt-3"><i class="fa-solid fa-xmark"></i> Close</button>
                    <button @click="saveComment(column,date,selectedModel,mType,ind)" class="bg-green-400 px-5 rounded-lg mb-2 mr-1.5 -mt-3"><i class="fa-solid fa-floppy-disk"></i> Save</button>
                </span>
                <div class="clear-both"></div>
            </section>
        </slot>
        <slot v-else>
            <section>{{addSpace(displayData)}}</section>
        </slot>
        <metricsComment 
            v-if="displayComments && hoverCommentIndex && commentData && commentData[0]" 
            :data="[commentData,selectedModel,commentData]" />
    </div>
</template>

<script setup>
    import inputMetricsEdit from "@/components/inputMetricsEdit.vue"
    import niceInput from "@/components/niceInput.vue"
    import metricsComment from "@/components/metrics/metricsComment.vue"
</script>

<script>
    export default {
        props: ['editM','editValue','newComment','hideCom','displayComments','editable','dataName','ind','showOptions','optionIndex','commentData','displayData','editData','column','date','selectedModel','mType','optionPosition'],
        emits: ['hideF','changedData','changedComment'],
        data() {
            return {
                edit:false,
                sOp:1,
                oldComment:null,
                hoverCommentIndex:null,
                changeIndex:0,
            }
        },
        watch: {
            editValue: function() {
                console.log("changed");
                this.$emit('changedData', [this.editValue,this.ind,this.mType.toLowerCase(),this.column])
                this.updateMetrics(this.dataName, this.ind, this.editValue)
                this.edit=false
            },
            editM: function() {
                this.edit = this.editM;
            }
        },
        components() {metricsComment,inputMetricsEdit,niceInput},
        methods: {
            removeComment(comment_id,index,type,column) {
                if (confirm("Are you sure? " +comment_id)) {
                    axios.post(baseURL+"metricsUpdate/", {                    
                        action: "deleteMetricsComment",
                        commentID: comment_id,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.$emit('changedComment', [[],index,type.toLowerCase(),column])
                    });
                }
            },
            /*focusInput() {
                setTimeout(() => {
                    this.$refs.editInput.focus()
                }, 200);
            },*/
            updateMetrics(which,index,newVal) {
                if (newVal) {
                    newVal = parseFloat(String(newVal).replace(",","."));
                    axios.post(baseURL+"metricsUpdate/", {                    
                        action: "updateMetrics",
                        Model_pair_id: this.selectedModel,
                        newValue: newVal,
                        column: which,
                        date: this.date,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
            resetComments() {
                //this.$emit('showOptions[ind]',null)
                this.$emit('hideF', [this.changeIndex,false])
                this.sOp=1;                
            },
            saveComment(column,date,model,type,ind) {
                if (this.commentData && this.commentData.commentID) {
                    var commentID = this.commentData.commentID;
                } else {var commentID = null;}
                var row = moment(date, "DD.MM.YYYY").format("YYYY-MM-DD")
                axios.post(baseURL+"metricsUpdate/", {                    
                    action: "addComment",
                    comment: this.newComment,
                    commentID: commentID,
                    pair_ID: model,
                    column:column,
                    date: row,
                    modelType: type,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    if (commentID) {
                        this.$emit('changedComment', [[{'value':this.newComment,'commentID':commentID}],ind,type.toLowerCase(),column])
                    } else {
                        this.$emit('changedComment', [[{'value':this.newComment,'commentID':result.data}],ind,type.toLowerCase(),column]);
                    }
                    this.resetComments();
                });
            },
        },
    }
</script>

<style scoped>
tr td input {
    width: 100px;    
    text-align: center;
    color:inherit;
    background:none;
    font-size:inherit;
}
</style>