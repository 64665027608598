<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
    <section :class="{'-mt-16 sm:-mt-10':selectedModel}">
        <div class="whitespace-nowrap cursor-pointer w-min px-3 p-1 rounded-full text-gray-800 bg-indigo-300 sm:float-left" @click="refresh()" title="Refresh"><i class="fas fa-redo"></i> Refresh</div>
        <div class="text-white text-lg pt-1 block"> 
            <span v-if="selectedModel">
                <i @click="lrIndex = lrIndex-1, $emit('lrIndex', lrIndex), $emit('lrData', 'model')" class="float-left ml-8 p-1 px-2 -mt-2 cursor-pointer text-3xl transition-all transform hover:scale-125 ease-in-out fa-solid fa-caret-left"></i> 
                <div class="block float-left w-60 px-1 text-center truncate"> {{selectedModelN}} </div>
                <i @click="lrIndex = lrIndex+1, $emit('lrIndex', lrIndex), $emit('lrData', 'model')" class="fa-solid transition-all -mt-2  p-1 px-2 transform hover:scale-125 ease-in-out text-3xl cursor-pointer fa-caret-right"></i>
            </span>
            <span v-else-if="selectedGroup">
                <i @click="lrIndex = lrIndex-1, $emit('lrIndex', lrIndex), $emit('lrData', 'group')" class="float-left ml-8 p-1 -mt-2 px-2 cursor-pointer transition-all transform hover:scale-125 text-3xl ease-in-out fa-solid fa-caret-left"></i> 
                <div class="block float-left w-48 px-1 text-center truncate"> {{selectedGroupN}}</div>
                <i @click="lrIndex = lrIndex+1, $emit('lrIndex', lrIndex), $emit('lrData', 'group')" class="fa-solid p-1 px-2 -mt-2 cursor-pointer transition-all transform hover:scale-125 text-3xl ease-in-out fa-caret-right"></i>
            </span>
        </div>
    </section>
    <div class="clear-both"></div>

    <h3 class="text-white font-bold text-lg sm:text-2xl m-2">Daily stats 
        <span class="pl-6 text-sm sm:text-base float-right font-semibold cursor-pointer" @click="displayComments = !displayComments" v-bind:class="{'text-green-400':displayComments}"><i class="mr-0.5" v-bind:class="{'fa-solid fa-check-square':displayComments,'fa-regular fa-square':!displayComments}"></i> Display comments</span>
        <span class="pl-6 text-sm sm:text-base float-right font-semibold cursor-pointer" v-if="blockedColumns.renewOn == 1" @click="displayRenewOn = !displayRenewOn" v-bind:class="{'text-green-400':displayRenewOn}"><i class="mr-0.5" v-bind:class="{'fa-solid fa-check-square':displayRenewOn,'fa-regular fa-square':!displayRenewOn}"></i> Display Renew</span>
        <span class="pl-6 text-sm sm:text-base float-right font-semibold cursor-pointer" v-if="blockedColumns.subsPaid == 1" @click="displaySubs = !displaySubs" v-bind:class="{'text-green-400':displaySubs}"><i class="mr-0.5" v-bind:class="{'fa-solid fa-check-square':displaySubs,'fa-regular fa-square':!displaySubs}"></i> Display Subs Paid</span>
    </h3>

    <div id="parent">
        <div @click="resetComments()" v-if="hideCom && editable == true" class="z-10 absolute w-full h-full bg-black bg-opacity-20"></div>
        <table class="bg-gray-700/90 text-gray-200 whitespace-nowrap rounded-2xl font-semibold border-0" style="max-height:535px">
            <thead id="myhead" class="sticky top-0">
                <tr class="firstTableLine">
                    <th class="date bg-gray-700 z-9" v-bind:colspan="1"></th>
                    <th class="" v-if="selectedModel == null"></th>
                    <th class="activeFans" v-bind:colspan="displayColumns.free == true ? '4' : '2'" style="border-left: 2px solid black;">Fans free <i @click="displayColumns.free = !displayColumns.free" v-bind:class="{'fa-circle-minus text-red-400':displayColumns.free,'fa-circle-plus text-green-400':!displayColumns.free}" class="cursor-pointer fa-solid ml-2"></i></th>
                    <th class="activeFans" v-bind:colspan="displayColumns.paid == true ? (blockedColumns.renewOn == 1 && displayRenewOn == 1 ? '5' : '4') : blockedColumns.renewOn == 1 && displayRenewOn == 1 ? '3' : '2'" style="border-left: 2px solid black;">Fans PAID <i @click="displayColumns.paid = !displayColumns.paid" v-bind:class="{'fa-circle-minus text-red-400':displayColumns.paid,'fa-circle-plus text-green-400':!displayColumns.paid}" class="cursor-pointer fa-solid ml-2"></i></th>            
                    <th class="messagesCount" colspan="2" style="border-left: 2px solid black;">Mass DM</th>            
                    <th class="transactionsValue" v-bind:colspan="blockedColumns.subsPaid == 1 && displaySubs == 1 ? '4' : '3'" style="border-left: 2px solid black;">Earnings</th>            
                    <th class="average_fans" v-bind:class="{hidden: selectedModel != null}" colspan="2" style="border-left: 2px solid black;"></th>
                </tr>
                <tr>
                    <th class="date bg-gray-700 z-9">Date</th>
                    <th class="models" v-bind:class="{hidden: selectedModel != null}">Models</th>
                    <th class="activeFans" style="border-left: 2px solid black;">Active</th>
                    <slot v-if="displayColumns.free == true">
                        <th class="expiredFans">Expired</th>
                        <th>NEW</th>
                    </slot>
                    <th>Change</th>
                    <th class="activeFans" style="border-left: 2px solid black;">Active</th>
                    <slot v-if="displayColumns.paid == true">
                        <th class="expiredFans">Expired</th>
                        <th>NEW</th>
                    </slot>
                    <th>Change</th>
                    <th v-if="blockedColumns.renewOn == 1 && displayRenewOn == 1">Renew</th>
                    <th class="messagesCount" style="border-left: 2px solid black;">Free</th>
                    <th class="messagesCount PAID"  style="border-right: 2px solid black;">Paid</th>
                    <th v-if="blockedColumns.subsPaid == 1 && displaySubs == 1">Subs. Paid</th>
                    <th class="transactionsValue free">Free</th>
                    <th class="transactionsValue PAID">Paid</th>
                    <th>Together</th>
                    <th class="average_fans whitespace-normal" v-bind:class="{hidden: selectedModel != null}" style="border-left: 2px solid black;">Fans growth/ prospect</th>
                    <th class="average_sales whitespace-normal" v-bind:class="{hidden: selectedModel != null}">Earnings/ prospect</th>
                </tr>
            </thead>
            <tbody id="mybody" v-if="metricsTable" onscroll="fixscrollMetrics('myhead','mybody')">
                <summary-line v-if="metricsTable" :blockedColumns="blockedColumns" :displaySubs="displaySubs" :displayRenewOn="displayRenewOn" :selectedModel="selectedModel" :metricsData="metricsTable.summary" :displayColumns="displayColumns"/>
                <average-line v-if="metricsTable" :blockedColumns="blockedColumns" :displaySubs="displaySubs" :displayRenewOn="displayRenewOn" :selectedModel="selectedModel" :metricsData="metricsTable.average" :displayColumns="displayColumns"/>
                
                <slot v-for="(metric,index) in metricsTable.data" :key="index">
                    <slot v-if="metric.models.free">
                        <tr v-if="index > 0" v-bind:class="{'bg-gray-600': index % 2 == 0}">
                            <td :title="metric.dayName" v-bind:class="{'bg-gray-600': index % 2 == 0,'bg-gray-700': index % 2 == 1}" class="z-9 ">{{metric.forDate}}</td>

                            <td v-if="metric.models.modelNames" v-bind:class="{hidden: selectedModel != null}" @mouseover="hoverModelsIndex = index" @mouseleave="hoverModelsIndex = null">
                                {{ addSpace(metric.models.modelNames.length) }}
                                <div v-if="hoverModelsIndex == index" class="absolute max-h-60 overflow-y-scroll z-30 bg-gray-200 text-gray-700 -mt-7 ml-20 p-2 px-5 text-sm rounded-lg">
                                    <ol><li v-for="(model, mIndex) in metric.models.modelNames" :key="mIndex">{{model}}</li></ol>
                                </div>
                            </td>

                            <td @contextmenu.capture.prevent="showMetricsOptions('faf'+index,index)" class="relative w-min p-0 border-l-2 border-black"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='faf'+index,'border-2 border-red-400':displayComments && metric.models.free.comments.fans_active.length}">
                                <slot v-if="metric.models.free.comments">
                                    <addComment v-if="metric.models.free"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'fans_active'" :dataName="'eFansF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.fans_active" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.fans_active" :displayData="metric.models.free.fans_active" :ind="index" :showOptions="showOptions" :optionIndex="'faf'+index" />
                                </slot>
                            </td>
                            
                            <slot v-if="displayColumns.free">
                                <td v-if="index == 0">0</td>
                                <td v-else @contextmenu.capture.prevent="showMetricsOptions('fef'+index,index)" class="relative w-min p-0"
                                    v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='fef'+index,'border-2 border-red-400':displayComments && metric.models.free.comments && metric.models.free.comments.fans_expired.length,
                                    'text-red-400': (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0,'text-green-400': (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                        <slot v-if="metric.models.free.comments">
                                            <addComment v-if="metric.models.free"
                                            @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                            :editable="editable" :optionPosition="optionPosition" :column="'fans_expired'" :dataName="'exFansF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.fans_expired.length" 
                                            :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.fans_expired" :displayData="metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired" :ind="index" :showOptions="showOptions" :optionIndex="'fef'+index" />
                                        </slot>
                                </td>
                                <td v-bind:class="{'text-green-400': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0,
                                                    'text-red-400': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                    {{ addSpace(metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) }}
                                </td>
                            </slot>

                            <td v-if="index == 0">0</td>
                            <td v-else 
                                v-bind:class="{'text-green-400': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0,
                                                'text-red-400': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                {{ addSpace((metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired)) }}
                            </td>
                            
                            <td @contextmenu.capture.prevent="showMetricsOptions('fap'+index,index)" class="relative border-l-2 border-black"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='fap'+index,'border-2 border-red-400':displayComments && metric.models.paid.comments && metric.models.paid.comments.fans_active[0]}">
                                <slot v-if="metric.models.paid.comments">
                                    <addComment v-if="metric.models.paid"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'fans_active'" :dataName="'eFansP'" :mType="'paid'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.fans_active" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.fans_active" :displayData="metric.models.paid.fans_active" :ind="index" :showOptions="showOptions" :optionIndex="'fap'+index" />
                                </slot>
                            </td>
                            
                            <slot v-if="displayColumns.paid">
                                <td v-if="index == 0">0</td>
                                <td v-else @contextmenu.capture.prevent="showMetricsOptions('fep'+index,index)" class="relative w-min p-0"
                                    v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='fep'+index,'border-2 border-red-400':displayComments && metric.models.paid.comments && metric.models.paid.comments.fans_expired.length,
                                    'text-red-400': (metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) > 0,'text-green-400': (metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) < 0}">
                                        <slot v-if="metric.models.paid.comments">
                                            <addComment v-if="metric.models.paid"
                                            @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                            :editable="editable" :optionPosition="optionPosition" :column="'fans_expired'" :dataName="'exFansP'" :mType="'paid'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.fans_expired.length" 
                                            :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.fans_expired" :displayData="metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired" :ind="index" :showOptions="showOptions" :optionIndex="'fep'+index" />
                                        </slot>
                                </td>
                                <td v-bind:class="{'text-green-400': (metric.models.paid.fans_active - metricsTable.data[index-1].models.paid.fans_active + metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) > 0,
                                                    'text-red-400': (metric.models.paid.fans_active - metricsTable.data[index-1].models.paid.fans_active + metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) < 0}">
                                    {{ addSpace(metric.models.paid.fans_active - metricsTable.data[index-1].models.paid.fans_active + metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) }}
                                </td>
                            </slot>

                            <td v-if="index == 0">0</td>
                            <td v-else 
                                v-bind:class="{'text-green-400': (metric.models.paid.fans_active - metricsTable.data[index-1].models.paid.fans_active + metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) - (metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) > 0,
                                                'text-red-400': (metric.models.paid.fans_active - metricsTable.data[index-1].models.paid.fans_active + metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) - (metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) < 0}">
                                {{ addSpace((metric.models.paid.fans_active - metricsTable.data[index-1].models.paid.fans_active + metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired) - (metric.models.paid.fans_expired - metricsTable.data[index-1].models.paid.fans_expired)) }}
                            </td>
                            
                            <td class="relative" @contextmenu.capture.prevent="showMetricsOptions('ren'+index,index)" v-if="blockedColumns && blockedColumns.renewOn == 1 && displayRenewOn"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='ren'+index,'border-2 border-red-400':displayComments && metric.models.paid.comments && metric.models.paid.comments.renewOn.length}">
                                <slot v-if="metric.models.paid.comments">
                                    <addComment v-if="metric.models.paid"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'renewOn'" :dataName="'eRen'" :mType="'PAID'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.renewOn" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.renewOn" :displayData="metric.models.paid.renewOn" :ind="index" :showOptions="showOptions" :optionIndex="'ren'+index" />
                                </slot>
                            </td>

                            <td @contextmenu.capture.prevent="showMetricsOptions('mmf'+index,index)" class="relative border-l-2 border-black"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='mmf'+index,'border-2 border-red-400':displayComments && metric.models.free.comments && metric.models.free.comments.messages[0]}">
                                <slot v-if="metric.models.free.comments">
                                    <addComment v-if="metric.models.free"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'messages'" :dataName="'eDMF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.messages" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.messages" :displayData="metric.models.free.messages" :ind="index" :showOptions="showOptions" :optionIndex="'mmf'+index" />
                                </slot>
                            </td>

                            <td @contextmenu.capture.prevent="showMetricsOptions('mmp'+index,index)" class="relative"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='mmp'+index,'border-2 border-red-400':displayComments && metric.models.paid.comments && metric.models.paid.comments.messages[0]}">
                                <slot v-if="metric.models.paid.comments">
                                    <addComment v-if="metric.models.paid"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'messages'" :dataName="'eDMP'" :mType="'PAID'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.messages" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.messages" :displayData="metric.models.paid.messages" :ind="index" :showOptions="showOptions" :optionIndex="'mmp'+index" />
                                </slot>
                            </td>

                            <td @contextmenu.capture.prevent="showMetricsOptions('sbP'+index,index)" v-if="blockedColumns.subsPaid == 1 && displaySubs == 1"
                                class="relative border-l-2 border-black"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='sbP'+index,'border-2 border-red-400':displayComments && metric.models.paid.comments && metric.models.paid.comments.subsEarnings[0]}">

                                <slot v-if="metric.models.paid.comments">
                                    <addComment v-if="metric.models.paid"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'subsEarnings'" :dataName="'sbP'" :mType="'PAID'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.subsEarnings" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.subsEarnings" :displayData="'$ '+parseFloat(metric.models.paid.subsEarnings).toFixed(2)" :ind="index" :showOptions="showOptions" :optionIndex="'sbP'+index" />
                                </slot>
                            </td>

                            <td @contextmenu.capture.prevent="showMetricsOptions('earnF'+index,index)" class="relative" @click="openReverseAlert(parseFloat(metric.models.free.reverseSum).toFixed(2));"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='earnF'+index,'bg-red-500/50':metric.models.free.reverseSum,'border-2 border-red-400':displayComments && metric.models.free.comments && metric.models.free.comments.transactionSum[0]}">
                                <slot v-if="metric.models.free.comments">
                                    <addComment v-if="metric.models.free"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'transactionSum'" :dataName="'eEarnF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.transactionSum" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.transactionSum" :displayData="'$ '+parseFloat(parseFloat(metric.models.free.transactionSum) - parseFloat(metric.models.free.reverseSum)).toFixed(2)" :ind="index" :showOptions="showOptions" :optionIndex="'earnF'+index" />
                                </slot>
                            </td>

                            <td @contextmenu.capture.prevent="showMetricsOptions('eEarnP'+index,index)" class="relative" @click="openReverseAlert(parseFloat(metric.models.paid.reverseSum).toFixed(2));"
                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='eEarnP'+index,'bg-red-500/50':metric.models.paid.reverseSum,'border-2 border-red-400':displayComments && metric.models.paid.comments && metric.models.paid.comments.transactionSum[0]}">
                                <slot v-if="metric.models.paid.comments">
                                    <addComment v-if="metric.models.paid"
                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                    :editable="editable" :optionPosition="optionPosition" :column="'transactionSum'" :dataName="'eEarnP'" :mType="'PAID'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.transactionSum" 
                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.transactionSum" :displayData="'$ '+parseFloat(parseFloat(metric.models.paid.transactionSum) - parseFloat(metric.models.paid.reverseSum)).toFixed(2)" :ind="index" :showOptions="showOptions" :optionIndex="'eEarnP'+index" />
                                </slot>
                            </td>

                            <td>$ {{ addSpace(parseFloat(metric.models.free.transactionSum-metric.models.free.reverseSum+metric.models.paid.transactionSum-metric.models.paid.reverseSum).toFixed(2)) }}</td>

                            <td v-if="index == 0" style="border-left: 2px solid black;" v-bind:class="{hidden: selectedModel != null}">0</td>
                            <td style="border-left: 2px solid black;" v-else 
                                v-bind:class="{hidden: selectedModel != null, 
                                    'text-green-400': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0, 
                                    'text-red-400': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                {{ (((metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired)) / metric.models.modelNames.length).toFixed(2) }}
                            </td>

                            <td v-bind:class="{hidden: selectedModel != null,
                                'text-red-400':((metric.models.free.transactionSum + metric.models.paid.transactionSum) / metric.models.modelNames.length)
                                    < ((metricsTable.data[index-1].models.free.transactionSum + metricsTable.data[index-1].models.paid.transactionSum) / metricsTable.data[index-1].models.modelNames.length),
                                'text-green-400':((metric.models.free.transactionSum + metric.models.paid.transactionSum) / metric.models.modelNames.length)
                                    > ((metricsTable.data[index-1].models.free.transactionSum + metricsTable.data[index-1].models.paid.transactionSum) / metricsTable.data[index-1].models.modelNames.length)}">
                                $ {{addSpace(parseFloat((metric.models.free.transactionSum + metric.models.paid.transactionSum) / metric.models.modelNames.length).toFixed(2))}}
                            </td>
                        </tr>
                    </slot>
                </slot>

                <tr v-if="selectedModel != null && addNewLine == true">
                    <td><datepicker v-model="add.date" @click="submited=false" v-bind:class="[{'bg-red-400': submited == true && add.date == null}, 'bg-gray-300']" class="text-black p-1 m-0 px-2 center ml-5" /></td>
                    <td><input v-model="add.free.fans" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td><input v-model="add.free.expfans" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td></td>
                    <td></td>
                    <td><input v-model="add.paid.fans" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td><input v-model="add.paid.expfans" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td></td>
                    <td></td>
                    <td v-if="blockedColumns.renewOn == 1"><input v-model="add.renewOn" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td><input v-model="add.free.massDM" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td><input v-model="add.paid.massDM" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td v-if="blockedColumns.subsPaid == 1"><input v-model="add.subsEarnings" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td><input v-model="add.free.trans" class="bg-gray-300 text-black p-1 m-0 px-2" /></td>
                    <td><input v-model="add.paid.trans" class="bg-gray-300 text-black p-1 m-0 px-2" v-on:keyup.enter="saveNew()" /></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="text-gray-300 absolute right-5 mt-2 text-right">* the difference in the earnings can be due to referals</div>

    <div class="absolute right-8" v-if="1==2 && editable == true">
        <div v-if="selectedModel != null && addNewLine == true" @click="saveNew()" class="bg-gray-300 w-max p-1 px-3 cursor-pointer rounded-lg mt-2 bg-green-600 text-white float-left"><i class="fas fa-save"></i> &nbsp;Save</div>
        <div v-else-if="selectedModel != null" @click="addNewLine = true" class="bg-gray-300 w-max text-gray-800 p-1 px-3 cursor-pointer rounded-lg mt-2 mr-4 float-left"><i class="fas fa-plus"></i> &nbsp;Add a new record</div>
    </div>

</template>

<script setup>
    import metricsComment from "@/components/metrics/metricsComment.vue"
    import addComment from "@/components/metrics/metricsAddComment.vue"
    import summaryLine from "@/components/metrics/metricsSummaryLine.vue"
    import averageLine from "@/components/metrics/metricsAverageLine.vue"
</script>

<script>
    export default {
        props: ['summary','hideF','changedComment','changedData','selectedModelN','selectedGroupN','selectedBranch','selectedModel','selectedGroup','dateFrom','dateTo','permissions','blockedColumns'],
        emits: ['lrIndex','lrData'],
        components() {
            metricsComment,
            addComment,
            summaryLine,
            averageLine
        },
        data() {
            return {
                metricsTable: [],
                hideCom:false,
                lrIndex:null,
                eFansF: {editing:null,value:null},
                exFansF: {editing:null,value:null},
                eFansP: {editing:null,value:null},
                exFansP: {editing:null,value:null},
                eDMF: {editing:null,value:null},
                eDMP: {editing:null,value:null},
                eEarnF: {editing:null,value:null},
                eEarnP: {editing:null,value:null},
                eRen: {editing:null,value:null},
                sbP: {editing:null,value:null},
                loading: false,
                displayComments:true,
                displayRenewOn: true,
                displaySubs: true,
                editable: false,
                hoverModelsIndex:null,
                hoverCommentIndex:null,
                mounted: false,
                addNewLine: false,
                add: {date: null, free: {fans:null,massDM:null,trans:null}, paid: {fans:null,massDM:null,trans:null}},
                submited: false,
                showOptions:{index:0,options:1},
                optionPosition:{x:null,y:null},
                displayColumns: {free:false,paid:false},
            }
        },
        watch: { 
            changedData: function() {
                this.metricsTable.data[this.changedData[1]].models[this.changedData[2]][this.changedData[3]] = this.changedData[0];
            },
            changedComment: function() {
                this.metricsTable.data[this.changedComment[1]].models[this.changedComment[2]].comments[this.changedComment[3]] = this.changedComment[0];
            },
            hideF: function() {
                this.hideCom = this.hideF[1];
            },
            selectedBranch: function(n,o) {
                this.loading = true;
                this.metricsTable = [];
                if (this.selectedBranch !== null) {
                    this.getMetricsData(this.selectedBranch,'0');
                } else {
                    this.getMetricsData();
                }
            },
            selectedModel: function(newVal, oldVal) { // watch it
                this.loading = true;
                this.metricsTable = [];
                this.getMetricsData();
            },
            dateFrom: function(newVal, oldVal) { // watch it
                this.loading = true;
                this.getMetricsData();
            },
            dateTo: function(newVal, oldVal) { // watch it
                this.loading = true;
                this.getMetricsData();
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                this.loading = true;
                this.getMetricsData(null,null,1);
            },
        },
        methods: {
            openReverseAlert(alertMessage) {
                if (alertMessage>0) {
                    alert("Reverse summary for this day: $ " + alertMessage);
                }
            },
            resetComments() {
                this.hideCom = false;
            },
            showMetricsOptions(ind,index) {
                if (this.selectedModel != null && index!=this.metricsTable.data.length-1) {
                    this.hideCom = true;
                    this.showOptions.options = 1;
                    this.showOptions.index = ind
                    this.optionPosition.x = pointerX+50;
                }
            },
            getMetricsData(cID = null,smm = null,modelId = null) {
                let cpID = cID !== null ? cID : this.$cookies.get('c_id');
                let summary = smm !== null ? smm : this.summary;
                let modelID = modelId === null ? this.selectedModel : null;
                axios.get(baseURL+"metrics/", {
                    params: {
                        table: "daily",
                        groupId: this.selectedGroup,
                        modelId: modelID,
                        dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                        dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                        userToken: this.$cookies.get('userToken'),
                        companyID: cpID,
                        summary: summary 
                    }
                }).then((result) => {
                    if (!result.data.message) {
                        this.metricsTable = result.data
                    } else {
                        this.metricsTable = []
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            refresh() {
                this.loading = true;
                this.metricsTable = [];
                this.getMetricsData();
            },
            saveNew() {
                this.submited = true
                if (this.add.date)  {
                    let dateVar = moment(this.add.date).format('YYYY-MM-DD');
                    axios.post(baseURL+"metricsUpdate/", {                    
                        action: "addMetrics",
                        Model_pair_id: this.selectedModel,
                        addData: this.add,
                        dataDate: dateVar,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data != 'duplicate') {
                            this.addNewLine = false;
                            this.metricsTable.push({
                                date: moment(this.add.date).format('DD.MM.YYYY'), 
                                free: {
                                    fans_active: result.data.free.fans,
                                    fans_expired: 0,
                                    messages: result.data.free.massDM,
                                    transactionSum: parseFloat(result.data.free.trans.replace(",",".")),
                                },
                                paid: {
                                    fans_active: result.data.paid.fans,
                                    fans_expired: 0,
                                    messages: result.data.paid.massDM,
                                    transactionSum: parseFloat(result.data.paid.trans.replace(",",".")),
                                }
                            });
                            this.add = {date: null, free: {fans:null,massDM:null,trans:null}, paid: {fans:null,massDM:null,trans:null}};
                            this.submited = false
                        } else {
                            alert("Data for this date already exists");
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
            updateMetrics(which,index,newVal) {
                newVal = parseFloat(newVal.replace(",","."));
                axios.post(baseURL+"metricsUpdate/", {                    
                    action: "updateMetrics",
                    Model_pair_id: this.selectedModel,
                    newValue: newVal,
                    column: which,
                    date: this.metricsTable[index].date,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                }, (error) => {
                    console.log(error);
                });
            }
        },
        mounted() {
            this.loading = true;
            if (this.permissions && this.permissions[1] == '1') {
                this.editable = true;
            }
            this.getMetricsData();
        }
    }
</script>

<style scoped>
    @import "@/assets/css/metrics.css"
</style>