<template>
  <div v-if="$cookies.get('userToken')">
    <div class="rounded-t-3xl hidden sm:block sm:rounded-none bg-gray-800/50 text-center w-full sm:w-20 px-2 h-20 sm:h-full fixed bottom-0 sm:top-0 text-white sm:justify-items-center z-20">
      <div class="logo mt-6 w-full mb-0 hidden"></div>
      <section class="hidden sm:block truncate whitespace-nowrap w-16 text-xs mb-2 mt-4 cursor-pointer hover:overflow-hidden" v-if="userName">
        <span @mouseover="usernameOver = true" @mouseout="usernameOver = false">{{userName}}</span>
        <span class="absolute bg-white text-black w-max leading-loose rounded-lg px-2 block -mt-5 ml-16 whitespace-nowrap" v-bind:class="{block: usernameOver, hidden: !usernameOver}">
          <i class="fas fa-caret-left absolute -ml-3 mt-2 text-white"></i> {{ userName }}
        </span><br />
        <div class="mt-1" @mouseover="branchNameOver = true" @mouseout="branchNameOver = false">{{branchName}}</div>
        <span class="absolute bg-white text-black w-max leading-loose rounded-lg px-2 block -mt-5 ml-20 whitespace-nowrap" v-bind:class="{block: branchNameOver, hidden: !branchNameOver}">
          <i class="fas fa-caret-left absolute -ml-3 mt-2 text-white"></i> {{ branchName }}
        </span><br />
      </section>

      <slot v-if="menuOptions">
        <slot v-for="(option,index) in menuOptions.slice(0, -1)" :key="index">
          <!-- Pridať child options -->
          <div v-if="option.children" class="menuItem sm:w-full cursor-pointer"
            @mouseover="option.over = true" 
            @mouseout="option.over = false">
            <i :class="option.icon"></i>
            <span class="absolute bg-gray-900 float-left sm:clear-both border border-gray-600 text-white w-40 rounded-lg p-2 sm:leading-8 -mt-10 ml-14 whitespace-nowrap" v-bind:class="{block: option.over, hidden: !option.over}">
              <slot v-for="(child,chI) in option.children" :key="chI">
                <router-link :to="'../'+child.link"><div @mouseover="child.over = true" 
                @mouseout="child.over = false" class="block px-2 sm:w-full cursor-pointer" :class="{'font-bold':child.over == true}">
                  {{child.name}}
                </div></router-link>
              </slot>
            </span><br />
          </div>
          <router-link v-else
            class="menuItem p-4 sm:p-0 sm:w-full cursor-pointer float-left sm:clear-both "
            :to="option.link"
            @mouseover="option.over = true" 
            @mouseout="option.over = false"
            v-bind:class="{hidden: option.active == 0}">
            <i :class="option.icon"></i>
            <span class="hidden sm:contents">
              <span class="absolute bg-white text-black w-max sm:leading-loose rounded-lg px-2 block -mt-10 ml-14 whitespace-nowrap" v-bind:class="{block: option.over, hidden: !option.over}">
                <i class="fas fa-caret-left absolute -ml-3 mt-2 text-white"></i> {{ option.name }}
              </span><br />
            </span>
          </router-link>
        </slot>
      </slot>

      <div v-if="compSelector.show" class="p-4 sm:p-0 float-left sm:clear-both absolute sm:relative right-20 sm:right-none w-max z-50 menuItem sm:w-full sm:left-0 text-green-400 cursor-pointer"
        @mouseover="compSelector.over = true" 
        @mouseout="compSelector.over = false" @click="changeComp">
          <i class="fas fa-random"></i>
          <span class="hidden sm:contents">
            <span class="sm:absolute bg-white text-black w-max sm:leading-loose rounded-lg px-2 block -mt-10 ml-14 whitespace-nowrap" v-bind:class="{block: compSelector.over, hidden: !compSelector.over}">
              <i class="fas fa-caret-left absolute -ml-3 mt-2 text-white"></i> Change company
            </span><br />
          </span>
      </div>
      
      <div @click="changeBG()" class="p-4 sm:p-0 sm:clear-both absolute right-5 sm:right-none sm:bottom-20 sm:w-full sm:left-0 cursor-pointer">
        <small class="text-xs">Background</small><br /><i class="fa-xl fa-solid " :class="{'fa-toggle-on':backgroundOn=='1','fa-toggle-off':backgroundOn!='1'}"></i>
      </div>

      <slot v-if="menuOptions">
        <div class="p-4 sm:p-0 menuItem float-left sm:clear-both absolute right-5 sm:right-none sm:bottom-4 sm:w-full sm:left-0 cursor-pointer"
          v-for="(option,index) in menuOptions.slice(-1)" :key="index"
          @mouseover="option.over = true" 
          @mouseout="option.over = false" @click="logout()">
            <i :class="option.icon"></i>
            <span class="absolute bg-white text-black w-max sm:leading-loose rounded-lg px-2 block -mt-10 ml-14 whitespace-nowrap" v-bind:class="{block: option.over, hidden: !option.over}">
              <i class="fas fa-caret-left absolute -ml-3 mt-2 text-white"></i> {{ option.name }}
            </span><br />
        </div>
      </slot>
    </div>

    <div class="z-40 sm:hidden fixed bottom-0 left-0 bg-gray-800 rounded-t-2xl w-full p-3 px-5 text-white">
      <i @click="displayMenu = !displayMenu" class="fa-solid fa-bars"></i>
      <i @click="logout()" class="fa-solid fa-arrow-right-from-bracket absolute right-4 p-1"></i>
    </div>

    <section v-if="displayMenu" class="z-50 w-full h-full fixed text-white p-5 top-0 left-0 bg-gray-800 uppercase">
      <i @click="displayMenu = false" class="fa-solid fa-xmark absolute right-0 top-0 fa-lg top-1 p-5"></i>
      <div class="container p-3 pt-5 mx-auto leading-loose">
        <section class="whitespace-nowrap" v-if="userName">
          <span>Welcome back {{userName}}</span>
          <div class="mt-1"><strong>Branch:</strong> {{branchName}}</div>
        </section>
        <hr class="my-5 border-0 border-t border-gray-600" />

        <slot v-if="menuOptions">
          <slot v-for="(option,index) in menuOptions.slice(0, -1)" :key="index">
            <!-- Pridať child options -->
            <router-link @click="displayMenu = false" v-if="option.link" :to="option.link"><div><i :class="option.icon" class="mr-2 w-5 text-center"></i> {{option.name}}</div></router-link>
            <div v-if="option.children">
              <router-link @click="displayMenu = false" :to="child.link" v-for="(child,chI) in option.children" :key="chI"><div class="pl-10"><i class="fa-2xs mr-2 fa-solid fa-circle"></i> {{child.name}}</div></router-link>
              <hr class="my-2 border-0 border-t border-gray-600"/>
            </div>
          </slot>
        </slot>
        <hr class="my-5 border-0 border-t border-gray-600" />
        <div v-if="compSelector.show" class="mt-5 text-green-400 cursor-pointer" @click="changeComp">
          <i class="mr-2 w-8 fas fa-random"></i> Switch between branches
        </div>
        <div @click="changeBG()" class="cursor-pointer">
          <i class="mr-2 w-8 fa-xl fa-solid " :class="{'fa-toggle-on':backgroundOn=='1','fa-toggle-off':backgroundOn!='1'}"></i> <small class="text-base">Display background</small>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        menuOptions: [],
        menuOpened: false,
        compSelector:{show:false,over:false},
        userName: null,
        usernameOver:false,
        branchNameOver:false,
        branchName:null,
        backgroundOn:'0',
        displayMenu:false
      }
    },
    methods: {
      changeBG() {
        this.backgroundOn = (parseInt(this.backgroundOn)+1) % 2;
        if (this.backgroundOn != '1') {
          document.body.classList.add('no-bg');
        } else {
          document.body.classList.remove('no-bg');
        }
        this.$cookies.set('bgOn',this.backgroundOn);
      },
      openMenu(index) {                
        menuOptions[index].over = true
      },
      closeMenu() {
        this.menuOpened = false;        
      },
      changeComp() {
        console.log('change branch')
        this.$cookies.set('c_id','');
        location.reload();
      },
      logout() {
        axios.get(baseURL+"logout/", {
          params: {
            userToken: this.$cookies.get('userToken')
          }
        }).then((result) => {
          console.log(result);
          this.$cookies.set('userToken','');
          this.$cookies.set('c_id','');
          window.location.href = '/';
          this.compSelector.show = false;
        }, (error) => {
          console.log(error);
        })
      }
    },
    created() {
      if (this.$cookies.get('bgOn') == null) {
        this.$cookies.set('bgOn','1');
        this.backgroundOn = '1';
      } else {
        this.backgroundOn = this.$cookies.get('bgOn');
      }
      if (this.$cookies.get('bgOn')!='1') {
        document.body.classList.add('no-bg');
      } else {
        document.body.classList.remove('no-bg');
      }
    },
    mounted() {
        if (!(this.$cookies.get('c_id'))) {
            var companyID = null;
        } else {
            var companyID = this.$cookies.get('c_id');
        }
        axios.get(baseURL+"menu/", {
          params: {
            userToken: this.$cookies.get('userToken'),
            companyID: companyID
          }
        }).then((result) => {
          this.menuOptions = result.data.list
          this.userName = result.data.username
          this.branchName = result.data.branchName
          if (this.$cookies.get('c_id')) {
            this.compSelector.show = true
          }
        }, (error) => {
          console.log(error);
        })
    }
  }
</script>

<style scoped>
  .router-link-exact-active.menuItem {    
    color:rgba(255,255,255,0.3);
  }
  .menuItem {
    line-height: 50px;
    transition: all 0.5s;
  }

  .logo {
    height:44px;
    background-size:50px;  
    background-repeat: no-repeat;      
    background-position: center;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.7));
    background-image:url("/logo.png");
  }  
</style>
