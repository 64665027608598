<template>
    <div>
        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

        <headerfilter pageTitle="Claim summary" />
        <div class="whitespace-nowrap float-left cursor-pointer w-min p-1 px-3 mt-24 ml-24 rounded-full text-gray-800 bg-indigo-300" @click="refresh()" title="Refresh"><i class="fas fa-redo"></i> Refresh</div>

    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        components() {
            headerfilter
        },
        data() {
            return {
                claimsData: {},
                loading:false
            }
        },
        methods: {
            getClaimsData() {
                this.loading = true;
                axios.get(baseURL+"marketingStats/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        fromDate: this.selectedMonth,
                        companyID: companyID,
                        build:'claimSummary',
                    }
                }).then((result) => {
                    this.stexting = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            }
        },
        created() {
            this.getClaimsData();
        }
    }
</script>