<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">Saved</div>

    <div class="w-full mb-4 relative">
        <router-link :to="'/Settings'">
            <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
        </router-link>
      
        <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Marketing assistant teams</h2>

        <div>
            <ol class="list-outside list-disc text-white mt-3 pl-6 ml-2 w-80 leading-10" v-if="groups">
                <li v-for="(hodnota,groupIndex) in groups" v-bind:id="hodnota.id" :key="groupIndex" class="cursor-pointer">                            
                    <article class="float-left" v-if="!hodnota.editing">{{ hodnota.name }}</article>
                    <input type="text" v-if="hodnota.editing" @blur="hodnota.editing = false" autofocus v-model="editGroupVal" @keydown.enter="hodnota.editing = false, editGroup(hodnota.id,groupIndex)"/>
                    <div v-if="!hodnota.editing"
                        class="bg-indigo-300 display-inline-block rounded-full text-xs leading-none p-2 text-black text-center cursor-pointer float-left ml-4 mt-2" 
                        @click="addInput(groupIndex)"><i class="fas fa-pencil-alt"></i></div>
                    <div v-if="!hodnota.editing"
                        class="groupToTrash p-2 text-center cursor-pointer float-left text-white ml-4 mt-2" 
                        @click="removeGroup(hodnota.id,groupIndex)"><i class="fas fa-trash-alt"></i></div>                            
                </li>
                <div class="cursor-text mt-2">
                    <label>Name of a new team</label><br />
                    <input type="text" class="new_group_name" v-model="newGroup" ref="new" @keydown.enter="addGroup()" placeholder="Name" autofocus /><br />                                
                    <small>Press enter to save</small>
                </div>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                newGroup: null,
                loading: false,
                saved: false,
                groups:null
            }
        },
        methods: {
            addGroup() {
                console.log("adding a group");
                axios.post(baseURL+"settingspost/", {
                    teamName: this.newGroup,
                    action: 'addAssistantTeam',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(result => {
                    this.groups.push({id:result.data,name:this.newGroup})
                    this.newGroup = null;
                }, (error) => {
                    console.log(error);
                })
            },
            addInput(index) {
                for (let i = 0; i < this.groups.length; i++) {
                    this.groups[i].editing = false;
                }
                this.groups[index].editing = true;
                this.editGroupVal = this.groups[index].name;
            },
            removeGroup(groupID,groupIndex) {
                console.log("remove team with id: " + groupID);
                if (confirm("Are you sure you want to remove this group?")) {
                    axios.post(baseURL+"settingspost/", {
                        teamID: groupID,
                        action: 'removeAssistantTeam',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then(result => {
                    }, (error) => {
                        console.log(error);
                    })
                    this.groups.splice(groupIndex,1);
                }
            }
        },
        created() {
            this.loading = true;
            axios.get(baseURL+"settingsget/", {
                params: {
                            action: 'getAssistantTeams',
                            userToken: this.$cookies.get('userToken'),
                            companyID: this.$cookies.get('c_id')
                        }
            })
            .then(result => {
                this.groups = result.data
            })
            .finally(() => this.loading = false)
        }
    }
</script>