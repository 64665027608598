<template>
    <input v-on:keyup.enter="$emit(vmodel,inputValue)" :type="inputType" ref="input" @blur="$emit('editM',false)" autocomplete="off" v-model="inputValue" class="inputLogin w-full p-2 px-3 "/>
</template>

<script>
    export default {
        props: ['inputType','pholder','vmodel','vvalue'],
        emits: ['editM','editValue'],
        data() {
            return {
                inputValue:null
            }
        },
        mounted() {
            if (this.vvalue) {
                this.inputValue = this.vvalue;
            }
            this.$refs.input.focus();
        }
    }
</script>