<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
    <div class="w-full items-center flex justify-center text-white">
        <div class="w-full min-h-screen mt-0 flex bg-gray-800/50 p-8 sm:w-96 sm:min-h-0 sm:rounded-2xl sm:mt-10">
            <div class="max-w-md w-full">
                <div class="mt-2 mb-2 sm:mt-6 sm:mb-6 bg-center bg-cover relative" style="background-image: url('/logo.png'); width:120px;height:120px;margin-left:calc(50% - 60px);"></div>
                <form>
                    <div class="w-full"><label class="font-semibold">Email address </label><br><input type="text" name="username" v-model="emailInput" placeholder="Email" class="w-full sm:w-80" autocomplete="username"></div>
                    <div class="w-full"><label class="font-semibold">Password </label><br>
                        <input v-if="showPassword" type="text" name="password" v-model="passwordInput" placeholder="Password" class="w-full sm:w-80" autocomplete="current-password">
                        <input v-else type="password" v-on:keyup.enter="login()" name="password" v-model="passwordInput" placeholder="Password" class="w-full sm:w-80" autocomplete="current-password">
                    </div>
                </form>
                <div class="text-center text-red-600 font-semibold" v-bind:class="{hidden: !errorMessage, block: errorMessage}">{{errorMessageContent}}</div>
                <div class="cursor-pointer select-none mb-10 mt-2" @click="showPassword = !showPassword"><i v-bind:class="{'far fa-square': !showPassword, 'fas fa-check-square': showPassword}"></i> &nbsp;Show password</div>
                <div class="bg-indigo-300 rounded-lg cursor-pointer p-2 px-3 text-black text-center font-semibold" @click="login()">Sign in</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showPassword:false,
                emailInput: null,
                passwordInput: null,
                loading: false,
                errorMessage: false,
                errorMessageContent: null,
                opravnenia:null
            }
        },
        methods: {
            login() {
                this.loading = true;
                axios.post(baseURL+"login/", {
                    email: this.emailInput,
                    password: this.passwordInput
                }).then(result => {              
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}

                    if (result.data.success == true) {
                        this.$cookies.set('userToken',result.data.token);
                        if (this.opravnenia != null && this.opravnenia[0] == '1') {
                            window.location.href = 'Metrics';
                        } else {
                            if (result.data && result.data.position) {
                                if (result.data.position == 'sexter' || result.data.position == 'team_leader') {
                                    window.location.href = 'Shifts';
                                } else {
                                    window.location.href = 'Metrics';
                                }
                            } else {
                                window.location.href = 'Shifts';
                            }
                        }
                    } else {
                        this.errorMessage = true;
                        if (result.data.error == 'account-deactivated') {
                            this.errorMessageContent = 'Your account is not active';
                        } else {
                            this.errorMessageContent = 'Wrong email or password';
                        }
                    }
                }, (error) => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => this.loading = false)
            },
        },
        created() {
            if (this.$cookies.get('userToken')) {
                if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
                } else {
                    var companyID = this.$cookies.get('c_id');
                }
                
                axios.get(baseURL+"opravnenia/userToken.php", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        companyID: companyID
                    }
                    }).then((result) => {
                        if (result.data.selectBranch) {
                            let prev = this.$route.path;
                            this.$router.push({name:'Branches', params: { prevRoute: prev } });
                        } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                            this.$cookies.set('userToken','');
                        }
                        if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                        else {this.opravnenia = '00';}

                        if (this.opravnenia != null && this.opravnenia[0] == '1') {
                            window.location.href = 'Metrics';
                        } else {
                            window.location.href = 'Shifts';
                        }
                    }, (error) => {
                    console.log(error);
                })
            }
        }
    }
</script>

