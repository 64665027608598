<template>
    <div>
        <router-link :to="'../Settings'">
            <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
        </router-link>
        <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center mb-10">Employee vs Branch</h2>

        <label>Branch</label><br />
        <select class="mt-1" @change="getAllEmployees(null), addUser = false" v-model="selectedBranch">
            <option value="0">Choose a branch</option>
            <option v-for="(branch,index) in branch_list" :key="index" :value="branch.id">{{branch.name}}</option>
        </select>

        <div @click="addNew" v-if="addUser == false && selectedBranch>0" class="bg-indigo-300 text-black w-max p-1 px-5 rounded-lg cursor-pointer mt-2">
            <i class="fa-solid fa-plus"></i> Add new
        </div>

        <div v-if="addUser && selectedBranch>0">
            <label>User</label><br />

            <input type="search" class="float-left w-min mr-4" placeholder="Search user" v-model="selectedUser" list="users" />
            <datalist id="users">
                <option v-for="(employee,index) in empl_select" :key="index" :value="employee.name" :class="employee.id"></option>
            </datalist>
            <div @click="addUserToBranch" class="bg-indigo-300 float-right ml-3 text-black w-max p-1 px-5 rounded-lg cursor-pointer mt-2">
                <i class="fa-solid fa-floppy-disk"></i> Save
            </div>
        </div>
        <div class="clear-both"></div>

        <table class="text-center p-1 mt-5" v-if="selectedBranch>0">
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Position</th>
                <th>Remove</th>
            </tr>
            <tr v-for="(empl,index) in empl_list" :key="index">
                <td class="p-0.5 px-2">{{empl.id}}</td>
                <td class="p-0.5 px-2">{{empl.name}}</td>
                <td class="p-0.5 px-2">{{empl.position}}</td>
                <td class="p-0.5 px-2 text-center cursor-pointer" @click="remove_user_from_branch(empl.id,index)"><i class="fa-solid fa-trash-can"></i></td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                branch_list:[],
                selectedBranch:0,
                empl_list: [],
                addUser:false,
                selectedUser:null,
                empl_select:[]
            }
        },
        methods: {
            remove_user_from_branch(emplID,index) {
                if (confirm('Are you sure?')) {
                    axios.post(baseURL+"settingspost/", {
                        action: 'userToBranch',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id'),
                        branch: this.selectedBranch,
                        userID: emplID,
                        activity: "remove"
                    }).then((result) => {
                        this.empl_list.splice(index,1);
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            get_branches() {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'branches',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.branch_list = result.data
                }, (error) => {
                    console.log(error);
                });
            },
            getAllEmployees(filter) {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'employees',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id'),
                        branch: this.selectedBranch,
                        filter:filter
                    }
                }).then((result) => {
                    if (filter == 'except') {
                        this.empl_select = result.data
                    } else {
                        this.empl_list = result.data
                    }
                }, (error) => {
                    console.log(error);
                });
            },
            addNew() {
                if (this.selectedBranch>0) {
                    this.addUser = true;
                    this.getAllEmployees("except");
                }
            },
            addUserToBranch() {
                const user = this.empl_select.find(b => b.name === this.selectedUser)
                axios.post(baseURL+"settingspost/", {
                    action: 'userToBranch',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    branch: this.selectedBranch,
                    userID: user.id,
                    activity: "add"
                }).then((result) => {
                    if (result.data) {
                        this.empl_list.unshift({id:user.id,name:result.data.userName,position:result.data.position})
                    }
                    this.selectedUser = null;
                }, (error) => {
                    console.log(error);
                });
            }
        },
        created() {
            //block to be accessible only to super admin
            this.get_branches();
        }
    }
</script>