<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Archived models</h2>
    </div>

    <div>
        <table class="text-white bg-gray-700" id="modelTable">
            <tr>
                <th onclick="sortTable(0,'modelTable')" class="cursor-pointer"  @click="sortColumn=0">Code &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 0}"></i></th>
                <th onclick="sortTable(2,'modelTable')" class="w-1/ cursor-pointer" @click="sortColumn=2">Group &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 2}"></i></th>
                <th onclick="sortTable(1,'modelTable')" class="w-1/4 cursor-pointer" @click="sortColumn=1">Name &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 1}"></i></th>
                <th class="w-1/5">Return from archive</th>
            </tr>
            <tr v-for="(model, index) in archivedModels" :key="index" v-bind:class="{archived:model.free.active == 1}">
                <td>{{ model.code }}</td>
                <td>{{ model.identifier }}</td>
                <td>{{ model.free.name }}</td>
                <td class="cursor-pointer" @click="modelDeArchive(index)" v-if="model.free.active == 0">Return from archive <i class="fas fa-undo"></i></td>
                <td class="cursor-pointer" @click="modelArchive(index)" v-else>Archive <i class="fas fa-archive"></i></td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                archivedModels: null,
                emplID: null,
                sortColumn: null,
                loading: false
            }
        },
        methods: {
            archFunction(index,state) {
                console.log('arch function in progress');
                axios.post(baseURL+"settingspost/", {                    
                    action: "modelArchiveDearchive",
                    modelPairID: this.archivedModels[index].pair_id,
                    active: state,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                }, (error) => {
                    console.log(error);
                });
            },
            modelArchive(index) {
                if (confirm("Are you sure you want to archive this model?")) { 
                    this.archFunction(index,0);
                    this.archivedModels[index].free.active=0 
                }
            },
            modelDeArchive(index) {
                if (confirm("Are you sure you want to return this model from archive?")) { 
                    console.log('running arch function');
                    this.archFunction(index,1);
                    this.archivedModels[index].free.active=1;
                }
            }
        },
        created() {
            this.loading = true;
            axios.get(baseURL+"settingsget/", {
                params: {
                    searchFor: '',
                    action: 'archivedModels',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                console.log(result.data)
                this.archivedModels = result.data
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
        },
    }
</script>

<style scoped>
  tr:nth-child(odd) {
    background-color: rgb(75, 85, 99);
  }
  td {
      padding:5px;
  }
</style>