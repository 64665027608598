<template>
    <div v-if="employee && employee.emplData">
        <Transition name="fade">
            <div class="bg-green-300 text-black fixed z-50 top-0 left-0 text-center p-2 w-full" v-if="saved">Saved</div>
        </Transition>

        <div v-if="(employee.shifts && employee.shifts.length>0) || selectedEmpl" class="justify-center bg-gray-700/80 m-4 ml-0 w-min rounded" :style="'border-top:5px solid '+employee.emplColor">
            <div class="col-span-12 p-4 w-200">
                <div class="overflow-auto lg:overflow-visible relative">
                    <div class="grid grid-cols-3">
                        <h2 class="text-gray-400"><slot v-if="layout==1">{{employee.emplData.positionName}}</slot><slot v-else>{{employee.salarySystem}}</slot></h2>
                        <div class="font-bold mt-0 mb-5 text-gray-200 w-72 text-center truncate">{{employee.emplData.emplName}}</div>
                        <h2 class="text-gray-400 text-right">{{employee.emplData.team}}</h2>
                    </div>
                    <slot v-if="layout == 1">
                        <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded"><label class="text-gray-400">Hours:</label><br />{{employee.hours}}</section>
                        <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded mb-5 "><label class="text-gray-400">Shifts:</label><br />{{employee.shiftsT}}<span class="text-gray-300">/{{employee.allShifts}}</span></section>
                        <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded" v-if="employee.earnings"><label class="text-gray-400">Earnings:</label><br />$ {{String(parseFloat(employee.earnings.toFixed(2)).toLocaleString('en-US')).replace(/,/g,' ')}}</section>
                        <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded mb-5 "><label class="text-gray-400">Salary:</label><br />$ {{ employee.salary.toFixed(2)}}</section>
                        <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded mb-5 " v-if="employee.costRatio"><label class="text-gray-400">Cost ratio:</label><br />{{employee.costRatio.toFixed(2)}} %</section>
                        <section class="float-left bg-green-500 p-3 mr-1 text-black rounded mb-5" title="Bonus is already calculated into salary" v-if="employee.bonus"><label class="text-black">Bonus: <i class="fa-solid fa-circle-question"></i></label><br />$ {{parseFloat(employee.bonus).toFixed(2)}}</section>
                        
                        <i @click="displayOptions=!displayOptions" class="fa-xl fa-solid fa-ellipsis text-white absolute right-0 top-5 cursor-pointer p-5"></i>
                        <section v-if="displayOptions" class="bg-gray-100 z-10 absolute p-3 px-5 leading-loose rounded-xl right-0 select-none top-16" style="filter: drop-shadow(0px 4px 5px rgba(0,0, 0, 0.4));">
                            <i class="fa-sharp fa-solid fa-caret-up absolute right-5 fa-xl top-0 text-white"></i>
                            <div v-if="opravnenia[21] == 1 || opravnenia[22] == 1" @click="openPayrollModal()" class="cursor-pointer"><i class="fa-solid fa-user-lock mr-1 w-5"></i> Payroll</div>
                            <div v-if="!employee.locked && parseInt(employee.costRatio) > -1" class="cursor-pointer" @click="recalcShifts(employee.emplData.emplID)">
                                <slot v-if="loading"><i class="fa-solid fa-spinner animate-spin mr-1 w-5 text-center"></i> Loading</slot>
                                <slot v-else><i class="fa-solid fa-arrows-rotate mr-1 w-5 text-center"></i> Recalc</slot>
                            </div>
                        </section>

                        <table @click="displayOptions=false" class="text-white w-full border-separate space-y-4 text-sm whitespace-nowrap overflow-x-auto h-80 block border-0">
                            <thead class="text-gray-800 bg-white sticky top-1">
                                <tr>
                                    <th class="p-3 border-0 border-r-1">Date</th>                            
                                    <th class="p-3 text-center border-0 border-r-1">no.</th>
                                    <th class="p-3 text-center border-0 border-r-1">h.</th>
                                    <th class="p-3 text-center border-0 border-r-1">Models</th>
                                    <th class="p-3 text-center border-0 border-r-1">Free</th>
                                    <th class="p-3 text-center border-0 border-r-1">Paid</th>
                                    <th class="p-3 text-center border-0 border-r-1">Total</th>
                                    <th class="p-3 text-center border-0 border-r-1">SUM</th>
                                </tr>
                            </thead>
                            <tbody class="border-0">
                                <tr class="text-white bg-gray-800 border-0 mt-1" v-for="(shift,index) in employee.shifts" :key="index">
                                    <td class="p-3 border-0 border-r-1">
                                        <span class="text-gray-400">{{shift.dayName}}.</span><br />{{shift.date}}
                                    </td>
                                    <td class="p-3 font-bold border-0 border-r-1">
                                        {{shift.shiftNo}}
                                    </td>
                                    <td class="p-3 font-bold border-0 border-r-1">
                                        {{shift.hours}}
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1">
                                        <div class="text-white font-semibold px-2 w-32 truncate" v-for="(model,modelInd) in shift.models" :key="modelInd">{{model.models_name}}</div>
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1">
                                        <slot v-for="(model,modelInd) in shift.models" :key="modelInd">
                                            <span v-if="model.earningsFree" class="px-2">$ {{parseFloat(model.earningsFree).toFixed(2)}}</span>
                                            <span v-else class="text-red-400 px-2">NaN</span>
                                            <br />
                                        </slot>
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1">
                                        <slot v-for="(model,modelInd) in shift.models" :key="modelInd">
                                            <span v-if="model.earningsPaid" class="px-2">$ {{parseFloat(model.earningsPaid).toFixed(2)}}</span>
                                            <span v-else class="text-red-400 px-2">NaN</span>
                                            <br />
                                        </slot>
                                    </td>
                                    <td class="p-2 leading-loose font-bold border-0 border-r-1">
                                        <slot v-for="(model,modelInd) in shift.models" :key="modelInd">
                                            <span v-if="model.earningsSum" class="px-2">$ {{parseFloat(model.earningsSum).toFixed(2)}}</span>
                                            <span v-else class="text-red-400 px-2">NaN</span>
                                            <br />
                                        </slot>
                                    </td>
                                    <td class="p-2 leading-loose font-bold border-0 border-r-1">
                                        <span v-if="shift.shiftSum" class="px-2">$ {{parseFloat(shift.shiftSum).toFixed(2)}}</span>
                                        <span v-else class="px-2">$ 0.00</span>
                                        <br />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </slot>
                    <slot v-else>
                        <span class="text-sm">
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-24 text-center pt-6 pb-5">1. half</section>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-20 text-center"><label class="text-gray-400">Hours:</label><br /> {{employee.summary.first.hours}}</section>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded mb-2 w-20 text-center"><label class="text-gray-400">Shifts:</label><br /> {{employee.summary.first.shifts}}</section>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-32 text-center" v-if="employee.earnings"><label class="text-gray-400">Earnings:</label><br /> $ {{employee.summary.first.earnings.toFixed(2)}}</section>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-24 text-center" v-if="employee.earnings"><label class="text-gray-400">Salary:</label><br /> $ {{employee.summary.first.salary.toFixed(2)}}</section>
                            <section class="float-left bg-green-500 p-3 mr-1 text-black rounded w-24 text-center" v-if="employee.earnings"><label class="text-black" title="Bonus is already calculated into salary">Bonus: <i class="fa-solid fa-circle-question"></i></label><br /> $ {{employee.summary.first.bonus.toFixed(2)}}</section>
                            <div class="clear-both"></div>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-24 text-center pt-6 pb-5">2. half </section>    
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-20 text-center"><label class="text-gray-400">Hours:</label><br /> {{employee.summary.second.hours}}</section>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded mb-2 w-20 text-center"><label class="text-gray-400">Shifts:</label><br /> {{employee.summary.second.shifts}}</section>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-32 text-center"><label class="text-gray-400">Earnings:</label><br /> $ {{employee.summary.second.earnings.toFixed(2)}}</section>
                            <section class="float-left bg-gray-600 p-3 mr-1 text-white rounded w-24 text-center" v-if="employee.earnings"><label class="text-gray-400">Salary:</label><br />$ {{employee.summary.second.salary.toFixed(2)}}</section>
                            <section class="float-left bg-green-500 p-3 mr-1 text-black rounded w-24 text-center" v-if="employee.earnings"><label class="text-black" title="Bonus is already calculated into salary">Bonus: <i class="fa-solid fa-circle-question"></i></label><br />$ {{employee.summary.second.bonus.toFixed(2)}}</section>
                            <div class="clear-both"></div>
                            <section class="float-left bg-gray-800 p-3 mr-1 text-white rounded w-24 text-center pt-6 pb-5">Monthly</section>
                            <section class="float-left bg-gray-800 p-3 mr-1 text-white rounded w-20 text-center"><label class="text-gray-400">Hours:</label><br /> {{employee.summary.first.hours + employee.summary.second.hours}}</section>
                            <section class="float-left bg-gray-800 p-3 mr-1 text-white rounded mb-5 w-20 text-center"><label class="text-gray-400">Shifts:</label><br /> {{employee.summary.first.shifts + employee.summary.second.shifts}}</section>
                            <section class="float-left bg-gray-800 p-3 mr-1 text-white rounded w-32 text-center"><label class="text-gray-400">Earnings:</label><br /> $ {{(employee.summary.first.earnings + employee.summary.second.earnings).toFixed(2)}}</section>
                            <section class="float-left bg-gray-800 p-3 mr-1 text-white rounded w-24 text-center" v-if="employee.earnings"><label class="text-gray-400">Salary:</label><br /> $ {{(employee.summary.first.salary + employee.summary.second.salary).toFixed(2)}}</section>
                            <section class="float-left bg-green-800 p-3 mr-1 text-white rounded w-24 text-center"><label title="Bonus is already calculated into salary">Bonus: <i class="fa-solid fa-circle-question"></i></label><br /> $ {{(employee.summary.first.bonus + employee.summary.second.bonus).toFixed(2)}}</section>
                            <div class="clear-both"></div> 
                        </span>
                    </slot>
                </div>
            </div>
        </div>
        <section v-if="dialogBG" @click="dialog = false" class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40">
        </section>
        <section v-if="dialog" class="text-white fixed bg-gray-700 p-5 rounded-xl inset-1/3 z-50 w-1/3 h-max pb-10">
            <i @click="dialog = false,dialogBG = false" class="fa-solid fa-times"></i>
            <div>
                <div class="text-center mb-2">
                    <h2 class="font-semibold text-lg">{{employee.emplData.emplName}}</h2>
                    <span class="text-sm">*By locking this record you'll disable further changes to it.</span>
                </div>
                <span class="text-indigo-300">Current salary:</span> <strong>$ {{employee.salary.toFixed(2)}}</strong><br />
                <span class="mt-2 text-indigo-300">Soft bonus:</span> $ <input type="number" class="w-20 mt-1" placeholder="soft bonus" v-model="softBonus" /><br />
                <span class="text-indigo-300">New salary:</span> <strong>$ {{softBonus ? (parseFloat(employee.salary)+parseFloat(softBonus)).toFixed(2) : employee.salary.toFixed(2)}}</strong><br />
            </div>
            <button @click="lockSalary()" class="delay-75 duration-75 transition-all float-right right-10 bottom-10 p-2 mt-5 px-7 text-lg rounded-full text-black font-medium bg-indigo-300 uppercase"><i class="fa-solid fa-lock"></i> Lock</button>
        </section>

        <section v-if="dialogSalary" class="text-white fixed bg-gray-700 p-5 rounded-xl top-60 z-50 w-1/3 left-1/3 h-max pb-10">
            <i @click="dialogSalary=false,dialogBG = false" class="fa-solid fa-times"></i>
            <div class="h-max clear-both">
                <div class="text-center mb-2">
                    <h2 class="font-semibold text-lg">{{employee.emplData.emplName}}</h2>
                    <span v-if="displayFirstHalf || displaySecondHalf || SalaryLmonthly" class="text-sm">*By locking the salary you'll disable further changes</span>
                </div>
                <slot v-if="(displayFirstHalf || displaySecondHalf || SalaryLmonthly) && opravnenia[21] == 1">
                    <section v-if="displayLock" class="my-4 mx-auto grid grid-cols-2 w-4/5 cursor-pointer" @click="SalaryLmonthly=!SalaryLmonthly" style="filter: drop-shadow(0px 4px 5px rgba(0,0, 0, 0.4));">
                        <div class="text-center rounded-l-full p-2"  :class="{'bg-indigo-300 text-black font-bold':SalaryLmonthly,'bg-gray-200 text-black':!SalaryLmonthly}">Monthly</div>
                        <div class="text-center rounded-r-full p-2" :class="{'bg-indigo-300 text-black font-bold':!SalaryLmonthly,'bg-gray-200 text-black':SalaryLmonthly}">2 weeks</div>
                    </section>
                    <section v-else class="text-indigo-300 my-2 font-bold">
                        Locking by 2 weeks (1/2 month)
                    </section>
                    <slot v-if="SalaryLmonthly && displayLock">
                        <span class="text-indigo-300">Current salary:</span> <strong>$ {{employee.salary.toFixed(2)}}</strong><br />
                        <span class="mt-2 text-indigo-300">Soft bonus:</span> $ <input type="number" class="w-20 mt-1" placeholder="soft bonus" v-model="softBonus" /><br />
                        <span class="text-indigo-300">New salary:</span> <strong>$ {{softBonus ? (parseFloat(employee.salary)+parseFloat(softBonus)).toFixed(2) : employee.salary.toFixed(2)}}</strong><br />
                        <button @click="lockPayroll('full',null)" class="w-full p-1 mt-2 text-center text-base rounded-full text-black font-medium bg-indigo-300 uppercase"><i class="fa-solid fa-lock"></i> Lock</button>
                    </slot>
                    <slot v-else>
                        <slot v-if="displayFirstHalf">
                            <h2 class="text-lg p-y-1 font-bold">First Half</h2>
                            <span class="text-indigo-300">Current salary:</span> <strong>$ {{employee.summary.first.salary.toFixed(2)}}</strong><br />
                            <span class="mt-2 text-indigo-300">Soft bonus:</span> $ <input type="number" class="w-20 mt-1" placeholder="soft bonus" v-model="softBonus" /><br />
                            <span class="text-indigo-300">New salary:</span> <strong>$ {{softBonus ? (parseFloat(employee.summary.first.salary)+parseFloat(softBonus)).toFixed(2) : employee.summary.first.salary.toFixed(2)}}</strong><br />
                            <button @click="lockPayroll('half',1)" class="w-full p-1 mt-2 text-center text-base rounded-full text-black font-medium bg-indigo-300 uppercase"><i class="fa-solid fa-lock"></i> Lock 1. half</button>
                            <br />
                        </slot>
                        <slot v-if="displaySecondHalf">
                            <hr class="my-3 border-0 border-t border-gray-500" />
                            <h2 class="text-lg p-y-1 font-bold">Second Half</h2>
                            <span class="text-indigo-300">Current salary:</span> <strong>$ {{employee.summary.second.salary.toFixed(2)}}</strong><br />
                            <span class="mt-2 text-indigo-300">Soft bonus:</span> $ <input type="number" class="w-20 mt-1" placeholder="soft bonus" v-model="softBonus" /><br />
                            <span class="text-indigo-300">New salary:</span> <strong>$ {{softBonus ? (parseFloat(employee.summary.second.salary)+parseFloat(softBonus)).toFixed(2) : employee.summary.second.salary.toFixed(2)}}</strong><br />
                            <button @click="lockPayroll('half',2)" class="w-full p-1 mt-2 text-center text-base rounded-full text-black font-medium bg-indigo-300 uppercase"><i class="fa-solid fa-lock"></i> Lock 2. half</button>
                        </slot>
                    </slot>
                </slot>
                <slot v-else-if="opravnenia[22] == 1">
                    <div class="text-indigo-300 text-center">Salary already locked</div>
                    <div @click="unlockSalary()" class="delay-75 duration-75 transition-all w-max right-10 bottom-10 p-1 mt-3 cursor-pointer mx-auto px-5 text-base rounded-full text-black font-medium bg-indigo-300 uppercase"><i class="fa-solid fa-lock-open mr-1"></i> Unlock</div>
                </slot>
                <div v-else class="text-center text-red-400">
                    With your permissions there is no activity allowed at this point
                </div>
            </div>

        </section>
    </div>
</template>

<script>
    export default {
        props: ['employeeData','layout','selectedEmpl','gIndex','i','groupID','selectedMonth','opravnenia'],
        data() {
            return {
                loading:false,
                employee: {},
                dialog: false,
                dialogBG: false,
                dialogSalary: false,
                dialogData: null,
                softBonus:0,
                unlocked: true,
                displayOptions:false,
                SalaryLmonthly:true,
                payrollLocks:[],
                saved:false
            }
        },
        computed: {
            displayLock() { 
                this.SalaryLmonthly = false;
                let firstHalf = this.searchPayroll(moment(this.selectedMonth).format("YYYY-MM-")+'01',moment(this.selectedMonth).format("YYYY-MM-")+'15');
                let secondHalf = this.searchPayroll(moment(this.selectedMonth).format("YYYY-MM-")+'15',moment(this.selectedMonth).format("YYYY-MM-")+moment(this.selectedMonth).endOf('month').format("DD"));
                if (moment().format("YYYY-MM") !== moment(this.selectedMonth).format("YYYY-MM") && !firstHalf && !secondHalf) {
                    return true;
                } else {
                    return false;
                }
            },
            displayFirstHalf() {
                let firstHalf = this.searchPayroll(moment(this.selectedMonth).format("YYYY-MM-")+'01',moment(this.selectedMonth).format("YYYY-MM-")+'15');
                let wholeMonth = this.searchPayroll(moment(this.selectedMonth).format("YYYY-MM-")+'01',moment(this.selectedMonth).format("YYYY-MM-")+moment(this.selectedMonth).endOf('month').format("DD"));
                return !firstHalf && !wholeMonth;
            },
            displaySecondHalf() {
                if (this.payrollLocks.length>0) {
                    if (this.payrollLocks) {
                        console.log(this.payrollLocks);
                        let secondHalf = this.searchPayroll(moment(this.selectedMonth).format("YYYY-MM-")+'16',moment(this.selectedMonth).format("YYYY-MM-")+moment(this.selectedMonth).endOf('month').format("DD"))
                        let wholeMonth = this.searchPayroll(moment(this.selectedMonth).format("YYYY-MM-")+'01',moment(this.selectedMonth).format("YYYY-MM-")+moment(this.selectedMonth).endOf('month').format("DD"));
                        console.log(secondHalf,wholeMonth);
                        if (moment().format("YYYY-MM-DD")>moment(this.selectedMonth).format("YYYY-MM-")+'15') {
                            return !secondHalf && !wholeMonth;
                        } else {
                            return false;
                        }
                    }
                } else {
                    return moment().format("YYYY-MM-DD")>moment(this.selectedMonth).format("YYYY-MM-")+'15';
                }
            }
        },
        watch: {
            employeeData: function(oldV,newV) {
                this.employee = this.employeeData;
            }
        },
        methods: {
            searchPayroll(dateFrom, dateTo) {
                if (this.payrollLocks) {
                    return this.payrollLocks.filter(function(obj) {
                        return obj.dateFrom === dateFrom && obj.dateTo === dateTo;
                    }).length > 0;
                } 
                return 0;
            },
            openPayrollModal() {
                this.softBonus = null;
                this.dialogBG=true
                this.dialogSalary=true
                axios.get(baseURL+"finance/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        empl_id: this.employee.emplData.emplID,
                        selectedMonth: this.selectedMonth,
                        build:'checkForLocks',
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.payrollLocks = result.data;
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            },
            lockPayroll(type,which) {
                axios.post(baseURL+"finance/",{
                    action:'payroll',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    type:type,
                    halfNo:which,
                    date:this.selectedMonth,
                    correction: this.softBonus,
                    data:this.employee
                }).then((result) => {
                    console.log(result);
                }, (error) => {console.log(error)});
                //display saved bubble
                this.saved = true;
                setTimeout(() => (this.saved = false),2000);
                this.dialogBG=false
                this.dialogSalary=false
            },
            unlockSalary() {
                if (parseInt(this.employee.costRatio) > -1) {
                    if (confirm("Unlock?")) {
                        axios.delete(baseURL+"finance/", {
                            params: {
                                action: "deleteSalary",
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id'),
                                selectedMonth: this.selectedMonth,
                                empl_id: this.employee.emplData.emplID
                            }
                        }).then((result) => {
                            if (result.data.success == true) {
                                this.unlocked = true;
                                this.employee.locked = false;
                            }
                            this.dialogBG = false
                            this.dialogSalary = false
                        }, (error) => {
                            console.log(error);
                        });
                    }
                }
            },
            lockShiftsModal() {
                this.dialog = true;
                this.dialogBG = true;
            },
            lockSalary() {
                axios.post(baseURL+"finance/", {                    
                    action: "lockSalary",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    date: this.selectedMonth,
                    correction: this.softBonus,
                    data:this.employee
                }).then((result) => {
                    if (result.data.success === true) {
                        this.dialog = false;
                        this.unlocked = false;
                        this.employee.locked = true;
                    } else {
                        alert(result.data.error);
                    }
                }, (error) => {
                    console.log(error);
                });
            },
            recalcShifts(sextar) {
                this.loading = true;
                axios.get(baseURL+"sexting/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        sextarID: sextar,
                        fromDate: this.selectedMonth,
                        build:'recalcShifts',
                        TZoffset: moment().utcOffset(),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    if (result.data && result.data.success == true) {
                        axios.get(baseURL+"sexting/", {
                            params: {
                                userToken: this.$cookies.get('userToken'),
                                groupId: this.groupID,
                                emplId: sextar,
                                fromDate: this.selectedMonth,
                                build:'sextingDataRef',
                                companyID: this.$cookies.get('c_id')
                            }
                        }).then((result) => {
                            this.employee = result.data[0];
                        }, (error) => {
                            console.log(error);
                        }).finally()
                    }

                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            }
        },
        mounted() {
            this.employee = this.employeeData;
        }
    }
</script>