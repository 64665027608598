<template>
    <div>
        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
        <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">Saved</div>

        <div class="w-full mb-4 relative">
            <router-link :to="'/Settings'">
                <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
            </router-link>
        
            <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Marketing assistants</h2>
            <div @click="addAssistant()" class="absolute cursor-pointer -mt-7 -right-5 p-1 px-2.5 rounded-full bg-indigo-300 text-black"><i class="fa-solid fa-plus"></i></div>
            <div @click="modal.visible = true" class="absolute hidden cursor-pointer -mt-7 right-7 p-1 px-2 rounded-full bg-indigo-300 text-black"><i class="fa-solid fa-arrow-right-arrow-left"></i></div>
            <section class="mt-5 leading-9 w-max">
                <table>
                    <tr>
                        <th>Assistant name</th>
                        <th>Models count</th>
                        <th>Assistant team</th>
                        <th>Edit</th>
                        <th>Archive</th>
                    </tr>
                    <slot v-if="parseInt(assistantsList.length) > 0">
                        <tr v-for="(assist,index) in assistantsList" :key="index" v-bind:class="{'text-white':assist.active == 1}">
                            <td>{{assist.name}}</td>
                            <td v-if="assist.modelsList">{{assist.modelsList.length}}</td>
                            <td v-if="assist.teamName">{{assist.teamName}}</td>
                            <td class="cursor-pointer" @click="editAssistant(index)">Edit <i class="fas fa-external-link-alt"></i></td>
                            <td v-if="assist.active == 1" class="cursor-pointer" @click="archiveAssistant(index,0)">Archive <i class="fa-solid fa-box-archive"></i></td>
                            <td v-else class="cursor-pointer" @click="archiveAssistant(index,1)">Return from archive <i class="fa-solid fa-arrow-rotate-left"></i></td>
                        </tr>
                    </slot>
                </table>
            </section>
        </div>

        <section v-if="modal.visible" @click="modal.visible = false" class="bg-black bg-opacity-70 fixed w-full h-full left-0 top-0 z-50">
        </section>
        <section v-if="modal.visible" class="bg-gray-700 fixed w-2/3 max-h-1/2 inset-x-1/12 top-36 z-50 rounded">
            <i class="absolute p-5 top-2 fa-lg right-0 fa-solid fa-xmark cursor-pointer" @click="modal.visible = false"></i>
            <div v-if="modalType==1" class="px-5">
                <h1 class="text-center text-xl font-semibold mt-5 mb-5">{{modal.headline}}</h1>
                <div class="float-left mr-10">
                    <label>Assistant name</label><br />
                    <input type="text" v-model="assistantName" />
                </div>
                <div class="float-left mr-10">
                    <label>Assistant team</label><br />
                    <select class="clear-both" v-model="selTeam">
                        <option v-for="(team,index) in assistantTeams" :key="index" :value="team.id">{{ team.name }}</option>
                    </select>
                </div>
                <section class="float-left">
                    <label>Branch</label><br />
                    <select class="mt-1 clear-both" v-model="selBranch" @change="getModels()">
                        <option v-for="(branch,index) in branchList" :key="index" :value="branch.id">{{ branch.name }}</option>
                    </select><br />
                </section>
                <section class="float-left ml-5" v-if="selBranch">
                    <label>Model</label><br />
                    <input type="search" class="float-left w-min mr-4" placeholder="Search model" v-model="sModel" list="models" @click="sModel = null" @change="modelSelected()" />
                    <datalist id="models">
                        <option v-for="(model,index) in modelsList" :key="index" :value="model.code + ' (' + model.name + ')'" :class="model.id"></option>
                    </datalist>
                    <!--<select class="mt-1 clear-both" v-model="model">
                        <slot v-for="(model,index) in modelsList" :key="index+1">
                            <option v-if="model.visible" :value="index+1">{{model.code}} {{ model.name }}</option>
                        </slot>
                    </select>-->
                </section>
                <div v-if="selBranch" class="float-left cursor-pointer bg-indigo-300 mt-8 ml-3 text-black rounded-full p-1 px-5 w-min" @click="addModelToAssistant()">Add</div>
                <div class="clear-both"></div>
                <ul class="py-5">
                    <li v-for="(sModel,index) in selectedModels" :key="index">{{sModel.name}} <i @click="removeModel(index)" class="cursor-pointer fa-solid fa-xmark ml-2"></i></li>
                </ul>
                <div v-bind:class="{'bg-gray-500': !assistantName, 'bg-indigo-300': assistantName}" class="cursor-pointer font-semibold right-5 absolute -mt-14 text-black rounded-full p-1 px-5 w-min" @click="saveAssistant()">
                    <slot v-if="modal.type==2">Save changes</slot>
                    <slot v-else>Save</slot>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                assistantsList: [],
                assistantTeams: [],
                assistantName:null,
                branchList:null,
                copied:false,
                editing:null,
                editingValue:null,
                loading:false,
                linkCategories:null,
                modal: {visible:false, type: 1, headline: "Add an assistant"},
                model:null,
                modalType:1,
                modelsList:null,
                newCategory:null,
                saved:false,
                selBranch:null,
                selTeam:null,
                selectedModels:[],
                sModel:null,
                selModel:null,
                sModelIndex:0,
                openedModel:null,
            }
        },
        methods: {
            modelSelected() {
                const model = this.modelsList.find(b => b.code + ' (' + b.name + ')' === this.sModel)
                this.selModel = model;
            },
            editAssistant(i) {
                this.openedModel = i;
                this.modal.visible = true;
                this.modal.type = 2;
                this.selectedModels = this.assistantsList[i].modelsList;
                this.assistantName = this.assistantsList[i].name;
                this.modal.headline = "Edit assistant " + this.assistantsList[i].name;
                this.selTeam = this.assistantsList[i].teamID;
            },
            addAssistant() {
                this.modal.visible = true;
                this.modal.type = 1;
                this.modal.headline = "Add an assistant";
            },
            archiveAssistant(i,archVal) {
                if (confirm('Are you sure you want to archive assistant ' + this.assistantsList[i].name + '?')) {
                    axios.post(baseURL+"settingspost/", {                    
                        action: "archiveAssistant",
                        active: archVal,
                        assistantID: this.assistantsList[i].id,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (!result.data) {
                            this.assistantsList[i].active = archVal;
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            removeModel(e) {
                this.selectedModels.splice(e, 1)
                //this.modelsList[e].visible = true
            },
            saveAssistant() {
                // save new asistant
                if (this.assistantName) {
                    if (this.modal.type == 1) {
                        axios.post(baseURL+"settingspost/", {                    
                            action: "saveAssistant",
                            modelsList: this.selectedModels,
                            assistantName: this.assistantName,
                            assistantTeam: this.selTeam,
                            userToken: this.$cookies.get('userToken'),
                            companyID: this.$cookies.get('c_id')
                        }).then((result) => {
                            if (result.data > 0) {
                                this.assistantsList.push({id:result.data,name:this.assistantName,teamName:'pending...',active:1,modelsCount:this.selectedModels.length})
                                this.modal.visible = false;
                                this.assistantName = null;
                                this.selBranch = null
                                this.model = null
                                this.selectedModels = []
                            }
                        }, (error) => {
                            console.log(error);
                        });
                    } else {
                        axios.post(baseURL+"settingspost/", {                    
                            action: "updateAssistant",
                            modelsList: this.selectedModels,
                            assistantID: this.assistantsList[this.openedModel].id,
                            assistantName: this.assistantName,
                            assistantTeam: this.selTeam,
                            userToken: this.$cookies.get('userToken'),
                            companyID: this.$cookies.get('c_id')
                        }).then((result) => {
                            if (result.data > 0) {
                                this.assistantsList[this.openedModel].name = this.assistantName;
                                this.assistantsList[this.openedModel].modelsCount = this.selectedModels.length;
                                this.assistantsList[this.openedModel].teamName = 'pending...';
                                this.modal.visible = false;
                                this.assistantName = null;
                                this.selBranch = null
                                this.selTeam = null
                                this.model = null
                                this.selectedModels = []
                            }
                        }, (error) => {
                            console.log(error);
                        });
                    }
                } else {
                    alert("Please fill out assistant's name")
                }
            },
            addModelToAssistant() {
                if (this.selModel) {
                    this.selectedModels.push({id: this.selModel.id, name: this.selModel.name})
                    this.selModel = null;
                }
            },
            getModels() {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'getModelsFromBranch',
                        branchID: this.selBranch,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.modelsList = result.data;
                    console.log(this.modelsList);
                }, (error) => {
                    console.log(error);
                })
            }
        },
        mounted() {
            axios.get(baseURL+"lists/", {
                params: {
                    build: 'branches',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.branchList = result.data;
            }, (error) => {
                console.log(error);
            })

            axios.get(baseURL+"lists/", {
                params: {
                    build: 'assistants',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.assistantsList = result.data;
            }, (error) => {
                console.log(error);
            })

            axios.get(baseURL+"lists/", {
                params: {
                    build: 'assistant_teams',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.assistantTeams = result.data;
            }, (error) => {
                console.log(error);
            })  
        }
    }
</script>

<style scoped>
    tr:nth-child(odd) {
        background-color: rgb(75, 85, 99);
    }
    td {
        padding:0 5px 0 5px;
    }
</style>