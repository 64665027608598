<template>
    <headerfilter :pageTitle="'Shifts'" @selectedModel="selectedModel = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event" />
    <div class="mt-3">
        <section>
            <div class="text-white border border-white p-1 px-3 rounded cursor-pointer hover:text-gray-900 hover:bg-white hidden"><i class="fas fa-file-export"></i> EXCEL</div>
        </section>
        <shiftsTable :selectedModel="selectedModel" :myself="myself" :permissions="opravnenia" :selectedTeam="selectedTeam" :selectedGroup="selectedGroup" :dateFrom="dateFrom" :dateTo="dateTo"/>
    </div>    
</template>

<script setup>
    import shiftsTable from "@/components/shifts/shiftsTable.vue"
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        props: ['selectedModel','selectedGroup','dateFrom','dateTo','selectedTeam'],
        components() {
            shiftsTable,
            headerfilter
        },
        data() {
            return {
                groupList: [],
                modelsList: [],
                unselectGroups: false,
                opravnenia:'00000',
                myself:null
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: "header"
                }
                }).then((result) => {
                    console.log(result.data)
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                    this.myself = result.data.myself;
                }, (error) => {
                console.log(error);
            })
        }
    }
</script>

<style scoped>
.v3dp__datepicker {
    float:left;
    margin-left:10px;
    margin-top:-7px;
}
</style>