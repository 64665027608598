import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import Datepicker from 'vue3-datepicker'
import '@/assets/css/main1.css'
import '@/assets/css/vueTimepicker.css'
import '@/assets/css/tailwind.css'
import VueCookies from 'vue3-cookies'
import timePicker from "@/components/timePicker.vue"

createApp(App)
.use(router).use(VueCookies)
.component('datepicker', Datepicker).component('timePicker', timePicker)
.mount('#app')