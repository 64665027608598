<template>
    <div class="">
        <headerfilter pageTitle="Sexting costs" @selectedMonth="selectedMonth = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @selectedEmpl="selectedEmpl = $event" @dateFrom="dateFrom = $event"/>

        <section class="sm:ml-24 ml-5 mb-20 mt-24 bg-gray-800/80 p-1 px-7 rounded-xl">
            <h1 class="m-5 ml-0 text-white font-bold text-xl">Sexting costs per model</h1>
            <div class="mt-5 text-white pb-5">
                <table v-if="sextingCosts.length>0">
                    <thead>
                        <tr class="bg-gray-400 text-black">
                            <td class="text-left p-1 pl-4 rounded-l-lg">Name</td>
                            <td class="text-left p-1">Team</td>
                            <td class="text-left p-1">Cost</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(costData,index) in sextingCosts" :key="index" :class="index % 2 == 0 ? 'bg-gray-700' : 'bg-gray-600'">
                            <td class="text-left p-2 pl-4">{{costData.modelName}}</td>
                            <td class="text-left p-1">{{costData.teamName}}</td>
                            <td class="text-left p-1 font-bold">$ {{parseFloat(costData.cost).toFixed(2)}}</td>
                        </tr>
                    </tbody>
                </table>
                <span v-else>
                    I didn't find any data for this filter
                </span>
            </div>
        </section>
    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue";
</script>

<script>
    export default {
        props: ['dateFrom','selectedEmpl','selectedGroup','selectedMonth','selectedTeam'],
        data() {
            return {
                sextarSalaries:[],
                sextingCosts: [],
                mounted:false
            }
        },
        components() {
            headerfilter
        },
        watch: {
            selectedMonth: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.getSextingCosts();
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.getSextingCosts();
                }
            },
            selectedEmpl: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    this.getSextingCosts();
                }
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    this.getSextingCosts();
                }
            },
        },
        methods: {
            markAsPaid(salary_id,salary_index) {
                if (confirm("Mark as paid?")) {
                    axios.patch(baseURL+"finance/", {
                        userToken: this.$cookies.get('userToken'),
                        action:'markAsPaid',
                        salaryID: salary_id,
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.sextarSalaries[salary_index].paid = true;
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            getSextingCosts() {
                axios.get(baseURL+"finance/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        build:'sextingCosts',
                        selectedMonth: this.selectedMonth,
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    console.log(result);
                    if (result.status == 200) {
                        this.sextingCosts = result.data;
                    }
                }, (error) => {
                    console.log(error);
                });
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                }, (error) => {
                console.log(error);
            })
            this.getSextingCosts();
        }, 
        mounted() {
            this.mounted = true;
        }
    }
</script>