<template>
    <div>
        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

        <headerfilter pageTitle="Sexting" @selectedMonth="selectedMonth = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @selectedEmpl="selectedEmpl = $event" @dateFrom="dateFrom = $event"/>
        <div class="whitespace-nowrap float-left cursor-pointer w-min p-1 px-3 mt-24 ml-4 sm:ml-24 rounded-full text-gray-800 bg-indigo-300" @click="refresh()" title="Refresh"><i class="fas fa-redo"></i> Refresh</div>
        <div class="float-right mr-5 mt-16 text-white w-max ml-5 pt-8">
            Display options: 
            <i @click="layout=1" :class="{'opacity-70':layout != 1}" class="hover:opacity-100 ml-3 cursor-pointer fa-solid fa-table-cells-large p-2 px-3 mr-3 text-black bg-indigo-300 rounded-lg"></i>
            <i @click="layout=2" :class="{'opacity-70':layout != 2}" class="hover:opacity-100 fa-solid fa-bars cursor-pointer p-2 px-3 text-black bg-indigo-300 rounded-lg"></i>
        </div>

        <div class="clear-both"></div>

        <slot v-if="stexting.company">
            <slot v-if="layout == 1">
                <summary-panel :panelData="stexting.company" headline="Total" :lineSeparator="true" :layout="layout" class="sm:ml-20" />
            </slot>
            <slot v-else>
                <summary-panel :panelData="stexting.company.first" headline="1. half" :lineSeparator="false" :layout="layout" class="sm:ml-20" />
                <summary-panel :panelData="stexting.company.second" headline="2. half" :lineSeparator="true" :layout="layout" class="sm:ml-20" />
            </slot>
        </slot>

        <section class="relative sm:ml-20">
            <section class="flex h-screen">
                <section class="float-left" v-for="(g, gIndex) in stexting.values" :key="gIndex" >
                    
                    <section v-if="g.employeeCards && g.employeeCards.length > 0" class="border-r border-gray-500 pr-3">
                        <div class="h-max -mt-5 sm:mt-0">
                            <slot v-if="layout == 1">
                                <summary-panel :panelData="g.summary" :headline="g.groupName" :layout="layout" :lineSeparator="false" />
                            </slot>
                            <slot v-else>
                                <summary-panel :panelData="g.summary.first" :headline="g.groupName" subHeadline="1. half" :layout="layout" :lineSeparator="false" />
                                <summary-panel :panelData="g.summary.second" :headline="g.groupName" subHeadline="2. half" :layout="layout" :lineSeparator="false" />
                            </slot>
                        </div>
                        <section v-if="g.employeeCards && g.employeeCards.length > 0" class="relative clear-both">
                            <div class="ml-3 overflow-x-scroll overflow-y-hidden mb-16 grid grid-cols-1 w-200">
                                <slot v-for="(employee,i) in g.employeeCards" :key="i">
                                    <sexting-bubble :opravnenia="opravnenia" :employeeData="employee" :layout="layout" :selectedEmpl="selectedEmpl" :gIndex="gIndex" :i="i" :groupID="g.groupID" :selectedMonth="selectedMonth" />
                                </slot>
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </div> 
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
    import sextingBubble from "@/components/sexting/sextingBubble.vue"
    import summaryPanel from "@/components/sexting/summaryPanel.vue"
</script>

<script>
    export default {
        props: ['dateFrom','selectedEmpl','selectedGroup','selectedMonth','selectedTeam'],
        components() {
            headerfilter,
            sextingBubble,
            summaryPanel
        },
        data() {
            return {
                stexting: [],
                layout: 1,
                groups: [],
                singleGroup: null,
                loading: false,
                calcLoad: null,
                mntd: false,
                opravnenia:'0'
            }
        },
        watch: {
            selectedMonth: function(newVal, oldVal) { // watch i
                if (this.mntd) {
                    this.getSexting();
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                if (this.mntd) {
                    this.getSexting();
                }
            },
            selectedEmpl: function(newVal, oldVal) { // watch it
                this.loading = true;
                this.getSexting();
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                this.loading = true;
                this.getSexting();
            },
        },
        methods: {
            getSexting() {
                this.loading = true;
                axios.get(baseURL+"sexting/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        groupId: this.selectedGroup,
                        teamId: this.selectedTeam, 
                        emplId: this.selectedEmpl,
                        build:'sextingData',
                        fromDate: this.selectedMonth,
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.stexting = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            refresh() {
                var groupID = 0;
                if ((this.selectedTeam == 0) || (this.selectedTeam == null)) {
                    groupID = this.selectedGroup;
                } else {
                    groupID = this.selectedTeam;
                }
                this.loading = true;
                axios.get(baseURL+"sexting/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        groupId: groupID,
                        emplId: this.selectedEmpl,
                        fromDate: this.selectedMonth,
                        build:'sextingData',
                        companyID: this.$cookies.get('c_id')
                    }
                    }).then((result) => {
                        this.stexting = result.data
                    }, (error) => {
                        console.log(error);
                    }).finally(() => this.loading = false)
            },
        },
        mounted() {
            this.loading = true
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                }, (error) => {
                console.log(error);
            })

            axios.get(baseURL+"sexting/", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    fromDate: this.selectedMonth,
                    companyID: companyID,
                    build:'sextingData',
                }
            }).then((result) => {
                this.stexting = result.data
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false)
            
            console.log('mounted? ' + this.mntd)
            this.mntd = true
        }
    }
</script>

<style scoped>
	.table {
		border-spacing: 0 15px;
        border:0;
	}

	i {
		font-size: 1rem !important;
	}

	.table tr {
		border-radius: 5px;
	}

	tr td:nth-child(n+8),
	tr th:nth-child(n+8) {
		border-radius: 0 5px 5px 0;
	}

	tr td:nth-child(1),
	tr th:nth-child(1) {
		border-radius: 5px 0 0 5px;
	}

    tbody tr:nth-child(odd) {
        background:#1a212c;
    }

    tbody tr:nth-child(even) {
        background:#2c333d;
    }
</style>