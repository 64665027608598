<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Move models between branches</h2>
    </div>

    <label>Select a model</label><br />
    <input type="search" class="w-min mt-2 mb-5" placeholder="Search" v-model="selectedModelName" list="models" @change="loadModelGroups()" @click="selectedModelName = null" />
    <datalist id="models">
        <option v-for="(model,index) in allModels" :key="index" :value="model.code + ' (' + model.name + ')'" :class="model.pair_id"></option>
    </datalist>

    <div>
        <h2 class="text-lg font-semibold mb-5">History</h2>
        <table class="text-white bg-gray-700">
            <tr class="text-center">
                <th class="cursor-pointer text-center" >Group id &nbsp;</th>
                <th class="cursor-pointer text-center" >Model code &nbsp;</th>
                <th class="cursor-pointer">Company &nbsp; </th>
                <th class="cursor-pointer">Group &nbsp; </th>
                <th class="cursor-pointer">Date from &nbsp; </th>
                <th class="w-1/5">Date to</th>
                <th class="w-1/5">Action</th>
            </tr>
            <tr v-for="(group, index) in activeGroups" :key="index">
                <td>{{ group.groupID }}</td>
                <td>{{ group.modelCode }}</td>
                <td>{{ group.companyName }}</td>
                <td>{{ group.groupName }}</td>
                <td>{{ group.dateFrom }}</td>
                <td>{{ group.dateTo }}</td>
                <td></td>
            </tr>
        </table>
    </div>

    <section v-if="sModel">
        <h2 class="mt-7 text-lg font-semibold mb-3">Move to another company: </h2>
        
        <label>Moving date (date when the model is starting in the new branch)</label><br />
        <datepicker v-model="dateTo" class="mt-1 datepickerInput float-left"></datepicker><br />
        <div class="clear-both"></div>

        <label>Company</label><br />
        <select v-model="moveTo.company" @change="loadGroups()" class="mt-1 mb-3">
            <option v-for="(company,index) in companiesList" :key="index" :value="company.id">{{company.name}}</option>
        </select>
        <div v-if="moveTo.company && groupsList">
            <label>Group</label><br />
            <select v-model="moveTo.group"  @change="switchView($event, $event.target.selectedIndex)" class="mt-1 mb-3">
                <option v-for="(group,index) in groupsList" :key="index" :value="group.id">{{group.name}}</option>
            </select>
        </div>
        <div v-else-if="moveTo.company && !groupsList">
            No groups in this company
        </div>
        <slot class="mt-2 float-left" v-if="moveTo.company && groupsList && (groupsList.length>0 && groupIndex!=null) || moveTo.team">
            <div class="mt-1" v-if="(groupIndex!=-1 && groupsList[groupIndex].hasChildren == true) || moveTo.team">
                <label>Team</label><br />
                <select class="mt-1 clear-both" v-model="moveTo.team">
                    <option value="0">No team</option>
                    <option v-for="(team,index) in groupsList[groupIndex].children" :key="index" :value="team.id">{{ team.name }}</option>
                </select>
            </div>
        </slot>

        <button v-if="moveTo.company && groupsList" @click="moveModel()" class="delay-75  duration-75 transition-all absolute right-20 bottom-10 p-4 px-7 text-lg rounded-lg text-black font-medium bg-indigo-300 uppercase">
            <div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div> {{buttonValue}}
        </button>
    </section>

</template>

<script>
    export default {
        data() {
            return {
                loading:false,
                modelData:null,
                sortColumn: null,
                allModels:null,
                sModel:null,
                activeGroups: null,
                companiesList:null,
                groupsList:null,
                teamsList:null,
                groupIndex:null,
                dateTo:new Date(),
                buttonValue: "Save changes",
                moveTo: {company:null,group:null,team:0},
                selectedModelName:null
            }
        },
        methods: {
            moveModel() {
                this.loading = true;
                axios.post(baseURL+"settingspost/", {                    
                    action: "moveModel",
                    moveTo: this.moveTo,
                    moveDate: moment(this.dateTo).format('YYYY-MM-DD'),
                    m_pair_id: this.sModel,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.buttonValue = "Saved";
                    setTimeout(() => this.loadModelGroups(), this.moveTo = {company:null,group:null,team:0}, this.buttonValue = "Save changes", 1000);
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            listAllModels() {
                this.loading = true;
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'getAllModels',
                        access: 'd4sa6dd489asd7as84d9as8fas',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.allModels = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            listCompanies() {
                this.loading = true;
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'getAllCompanies',
                        access: 'd4sa6dd489asd7as84d9as8fas',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.companiesList = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            loadModelGroups() {
                const model = this.allModels.find(b => b.code + ' (' + b.name + ')' === this.selectedModelName)
                if (model) {
                    this.sModel = model.pair_id;
                }
                this.loading = true;
                axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'getActiveGroups',
                        modelID: this.sModel,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.activeGroups = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            loadGroups() {
                this.loading = true;
                this.groupsList = null;
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'groups',
                        access: 'd4sa6dd489asd7as84d9as8fas',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.moveTo.company
                    }
                }).then((result) => {
                    if(result.data.length) {
                        this.groupsList = result.data
                    } else {
                        this.groupsList = null;
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            switchView(event, selectedIndex) {
                this.groupIndex = selectedIndex;
                this.moveTo.team = null;
            },
        },
        created() {
            this.listAllModels();
            this.listCompanies();
        }
    }
</script>

<style scoped>
    tr:nth-child(odd) {
        background-color: rgb(75, 85, 99);
    }
    td {
        padding:5px 10px;
        text-align:left
    }
</style>