<template>
    <headerfilter :pageTitle="'Transactions'" @selectedEmpl="selectedEmpl = $event" @selectedModel="selectedModel = $event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event"/>

    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 ml-60" v-if="saved">Saved</div>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>

    <section class="mx-auto w-max mt-24 grid grid-cols-2 text-center font-semibold cursor-pointer">
        <div class="p-1 px-4 rounded-l" :class="{'bg-indigo-300 text-black':displayTab == 1, 'border border-indigo-300 text-indigo-300': displayTab == 2}" @click="dTab(1)">Transakcie</div>
        <div class="p-1 px-4 rounded-r" :class="{'bg-indigo-300 text-black':displayTab == 2, 'border border-indigo-300 text-indigo-300': displayTab == 1}" @click="dTab(2)">Reverse</div>
    </section>
    <div class="w-full h-full z-40 bg-opacity-30 bg-black fixed top-0" v-if="openIndex" @click="openIndex = null"></div>

    <slot v-if="opravnenia != null && (opravnenia[14] == '1' || opravnenia[15] == '1')">
        <slot v-if="displayTab == 1">
            <div v-if="transactionsStats" class="hidden sm:block color-white ml-2 sm:ml-28 h-52 text-white">
                <div class="clear-both"></div>
                <section class="text-white rounded-full p-1 px-3 bg-indigo-400 cursor-pointer text-white w-max text-sm" @click="getWholeGraph()">Display full graph</section>
                <div class="mr-5 mt-2">
                    <h1 class="text-white uppercase font-semibold mb-3">free</h1>
                    <section style="transform: scaleY(-1)" class="mt-4 h-20">
                        <section v-for="(statPart,ind) in transactionsStats.free" :key="ind" class="h-full align-baseline mr-2 float-left">
                            <div class="w-4 bg-red-400" :style="'height:' + (statPart*100)/transactionsStats.maxF + '%'"></div>
                        </section>
                    </section>
                    <div class="clear-both"></div>
                    <section v-for="(statPart,ind) in transactionsStats.free" :key="ind" class="mr-2 w-4 text-center float-left">
                        <div class="text-white text-base leading-none">{{ind.substr(0,2)}}</div>
                    </section>
                </div>
                <div class="clear-both mr-5 mt-10">
                    <h1 class="text-white uppercase font-semibold mb-3">paid</h1>
                    <section style="transform: scaleY(-1)" class="h-20">
                        <section v-for="(statPart,ind) in transactionsStats.paid" :key="ind" class="align-baseline mr-2 mb-2 float-left h-full">
                            <div class="w-4 bg-red-400" :style="'height:' + (statPart*100)/transactionsStats.maxP + '%'"></div>
                        </section>
                    </section>
                    <div class="clear-both"></div>
                    <section v-for="(statPart,ind) in transactionsStats.paid" :key="ind" class="mr-2 w-4 float-left">
                        <div class="text-white text-base leading-none">{{ind.substr(0,2)}}</div>
                    </section>
                </div>
            </div>
            <div class="clear-both"></div>

            <section v-if="filteredFree" class="bg-gray-100 p-5 mr-5 rounded ml-2 sm:ml-28 text-black mt-5 w-42">
                <h1 class="text-lg mb-2">Search for transactions</h1>
                <div class="float-left">
                    <label>By username</label><br />
                    <niceInput v-if="searchBy == 0 || filterType == 1" vvalue="" vmodel="srchUser" pholder="Username" inputType="text" class="transition-all duration-500 ease-in-out text-white mb-2" @srchUser="srchUser = $event" />
                    <i class="fa-solid fa-xmark cursor-pointer absolute right-4 top-3.5"></i>
                </div>
                <div class="float-left ml-5 w-36">
                    <label>By type</label><br />
                    <filterSelect v-if="searchBy == 1 || filterType == 1" vvalue="" vmodel="srchType" :data="transTypes" @srchType="srchType = $event" class="transition-all duration-500 ease-in-out" />
                </div>
                <div class="float-left ml-5 w-24">
                    <label>Net from</label><br />
                    <niceInput v-if="searchBy == 2 || filterType == 1" vvalue="" vmodel="srchNetFrom" pholder="From" inputType="number" class="text-white mb-2 w-24 transition-all duration-500 ease-in-out" @srchNetFrom="srchNetFrom = $event"/>
                </div>
                <div class="float-left ml-5">
                    <label>Net to</label><br />
                    <niceInput v-if="searchBy == 2 || filterType == 1" vvalue="" vmodel="srchNetTo" pholder="To" inputType="number" class="text-white mb-2 w-24 transition-all duration-500 ease-in-out" @srchNetTo="srchNetTo = $event"/>
                </div>
                <div class="clear-both"></div>
            </section>

            <section class="ml-2 sm:ml-28">
                <div class="text-white float-left w-max sm:mr-5 mb-10 block max-h-200 sm:pr-5 overflow-x-hidden overflow-y-scroll" v-bind:class="{'mt-7':filteredFree, 'mt-5': !filteredFree}">
                    <h1 class="uppercase pl-2 pb-3 text-2xl font-semibold sticky top-0 w-full py-1">FREE</h1>
                    <table class="text-white bg-gray-700 w-max text-sm sm:text-base" id="transactionsTable" v-if="filteredFree">
                        <thead class="sticky top-12">
                            <tr>
                                <th class="cursor-pointer bg-gray-400 text-gray-700">Date</th>
                                <th class="cursor-pointer bg-gray-400 text-gray-700">Time</th>
                                <th class="bg-gray-400 text-gray-700 relative">Net</th>
                                <th class="bg-gray-400 text-gray-700">Username</th>
                                <th class="bg-gray-400 text-gray-700">Type</th>
                                <th class="bg-gray-400 text-gray-700"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction, index) in filteredFree" :key="index">
                                <td class="text-center p-1 px-1">{{ formatDate(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                <td class="text-center p-1 px-1">{{ formatTime(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                <td class="text-center p-1 px-1">$ {{ transaction.net }}</td>
                                <td class="text-center p-1 px-1">{{ transaction.follower_user_name}}</td>
                                <td class="text-center p-1"><span class="hidden sm:contents">{{transaction.TransType}}</span><transaction-type class="sm:hidden" :t="transaction.TransType" /></td>
                                <td lass="opacity-100 cursor-pointer p-1 px-3 text-center" @click="changeCharge('free',index)"><i v-bind:class="{'text-gray-500':openIndex=='free'+index,'text-gray-500':openIndex!='free'+index,'text-red-300':transaction.reverse && transaction.reverseTimeStamp}" class="px-3 cursor-pointer fa-solid fa-rotate-left"></i></td>
                                <div v-if="!(transaction.reverse && transaction.reverseTimeStamp) && openIndex=='free'+index" 
                                    class="absolute bg-gray-100 z-50 rounded p-2 -ml-36 mt-9 text-black cursor-pointer" v-bind:class="{'hidden' :!transaction.reverse}">
                                    <dInput vmodel="changeNet" vvalue='' @changeNet="changeNet = $event" />
                                    <button @click="saveTimeStamp('free',index,transaction.transactionID,transaction.modelID),openIndex=null" class="bg-green-400 text-black rounded-full mt-1 p-1 px-4 text-base float-right">Save</button>
                                </div>
                                <div v-else-if="transaction.reverse && transaction.reverseTimeStamp && openIndex=='free'+index"
                                    @click="removeTimeStamp('free',index,transaction.transactionID,transaction.modelID), openIndex=null"
                                    class="absolute bg-gray-100 z-50 rounded p-2 pr-4 -ml-16 mt-9 text-black cursor-pointer">
                                    <i class="fa-solid fa-xmark mr-2 ml-2"></i> Remove 
                                </div>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="text-white">
                        Please select a model
                    </div>
                </div>

                <div class="text-white float-left w-max sm:pr-5 pb-24 max-h-200 overflow-y-scroll" v-bind:class="{'mt-7':filteredPaid, 'mt-5': !filteredPaid}">
                    <h1 class="uppercase pl-2 pb-3 text-2xl font-semibold sticky top-0 w-full py-1">PAID</h1>
                    <table class="text-white bg-gray-700 w-max text-sm sm:text-base" id="transactionsTable" v-if="filteredPaid">
                        <thead class="sticky top-12">
                            <tr>
                                <th class="cursor-pointer bg-gray-400 text-gray-700">Date</th>
                                <th class="cursor-pointer bg-gray-400 text-gray-700">Time</th>
                                <th class="bg-gray-400 text-gray-700 relative">Net</th>
                                <th class="bg-gray-400 text-gray-700">Username</th>
                                <th class="bg-gray-400 text-gray-700">Type</th>
                                <th class="bg-gray-400 text-gray-700"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction, index) in filteredPaid" :key="index">
                                <td class="text-center p-1">{{ formatDate(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                <td class="text-center p-1">{{ formatTime(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                <td class="text-center p-1">$ {{ transaction.net }}</td>
                                <td class="text-center p-1">{{ transaction.follower_user_name}}</td>
                                <td class="text-center p-1"><span class="hidden sm:contents">{{transaction.TransType}}</span><transaction-type class="sm:hidden" :t="transaction.TransType" /></td>
                                <td lass="opacity-100 cursor-pointer p-1 px-3 text-center" @click="changeCharge('paid',index)"><i v-bind:class="{'text-gray-500':openIndex=='paid'+index,'text-gray-500':openIndex!='paid'+index,'text-red-300':transaction.reverse && transaction.reverseTimeStamp}" class="px-3 cursor-pointer fa-solid fa-rotate-left"></i></td>
                                <div v-if="!(transaction.reverse && transaction.reverseTimeStamp) && openIndex=='paid'+index" 
                                    class="absolute bg-gray-100 z-50 rounded p-2 -ml-36 mt-9 text-black cursor-pointer" v-bind:class="{'hidden' :!transaction.reverse}">
                                    <dInput vmodel="changeNet" vvalue='' @changeNet="changeNet = $event" />
                                    <button @click="saveTimeStamp('paid',index,transaction.transactionID,transaction.modelID),openIndex=null" class="bg-green-400 text-black rounded-full mt-1 p-1 px-4 text-base float-right">Save</button>
                                </div>
                                <div v-else-if="transaction.reverse && transaction.reverseTimeStamp && openIndex=='paid'+index"
                                    @click="removeTimeStamp('paid',index,transaction.transactionID,transaction.modelID), openIndex=null"
                                    class="absolute bg-gray-100 z-50 rounded p-2 pr-4 -ml-16 mt-9 text-black cursor-pointer">
                                    <i class="fa-solid fa-xmark mr-2 ml-2"></i> Remove 
                                </div>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="text-white">
                        Please select a model
                    </div>
                </div>
            </section>
        </slot>

        <!-- REVERSE -->
        <slot v-else>
            <table class="ml-28 w-min bg-gray-700 mb-10 mt-5" v-if="reverseObj && reverseObj.metrics">
                <thead>
                    <tr class="bg-gray-500">
                        <th></th>
                        <th colspan="2">FREE</th>
                        <th colspan="2">PAID</th>
                        <th colspan="2">Together</th>
                    </tr>
                    <tr class="text-white bg-gray-600">
                        <th class="p-1 px-3">Date</th>
                        <th class="p-1 px-3">Amount</th>
                        <th class="p-1 px-3">Price</th>
                        <th class="p-1 px-3">Amount</th>
                        <th class="p-1 px-3">Price</th>
                        <th class="p-1 px-3">Amount</th>
                        <th class="p-1 px-3">Price</th>
                    </tr>
                </thead>
                <tr class="text-white whitespace-nowrap" v-for="(stats,index) in reverseObj.metrics" :key="index">
                    <td class="p-1 px-3">{{stats.date}}</td>
                    <td class="p-1 px-3">{{stats.freeAmount.toFixed(2)}}</td>
                    <td class="p-1 px-3">$ {{stats.freePrice.toFixed(2)}}</td>
                    <td class="p-1 px-3">{{stats.paidAmount.toFixed(2)}}</td>
                    <td class="p-1 px-3">$ {{stats.paidPrice.toFixed(2)}}</td>
                    <td class="p-1 px-3">{{stats.togetherAmount.toFixed(2)}}</td>
                    <td class="p-1 px-3">$ {{stats.togetherPrice.toFixed(2)}}</td>
                </tr>
                <tfoot v-if="reverseObj.metricsSummary">
                    <tr class="text-center whitespace-nowrap">
                        <td class="bg-indigo-300 text-black p-1 px-3">Summary</td>
                        <td class="bg-indigo-300 text-black p-1 px-3">{{reverseObj.metricsSummary.amountF.toFixed(2)}}</td>
                        <td class="bg-indigo-300 text-black p-1 px-3">$ {{reverseObj.metricsSummary.priceF.toFixed(2)}}</td>
                        <td class="bg-indigo-300 text-black p-1 px-3">{{reverseObj.metricsSummary.amountP.toFixed(2)}}</td>
                        <td class="bg-indigo-300 text-black p-1 px-3">$ {{reverseObj.metricsSummary.priceP.toFixed(2)}}</td>
                        <td class="bg-indigo-300 text-black p-1 px-3">{{reverseObj.metricsSummary.amountT.toFixed(2)}}</td>
                        <td class="bg-indigo-300 text-black p-1 px-3">$ {{reverseObj.metricsSummary.priceT.toFixed(2)}}</td>
                    </tr>
                </tfoot>
            </table>
            <section class="ml-28 container flex gap-10" v-if="reverseObj">
                <div class="text-white bold mb-10" v-for="(reverse,ind) in reverseObj.values" :key="ind" v-bind:class="{'mt-5':reverse, 'mt-5': !reverse}">
                    <h1 class="uppercase ml-2 mb-2 text-2xl font-semibold">{{ind}}</h1>
                    <table class="text-white bg-gray-700 w-max" id="transactionsTable" v-if="reverse">
                        <thead>
                            <tr>
                                <th onclick="sortTable(0,'transactionsTable')" class="cursor-pointer" @click="sortColumn=0" >Date &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 0}"></i></th>
                                <th onclick="sortTable(1,'transactionsTable')" class="cursor-pointer" @click="sortColumn=1" >Time &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 0}"></i></th>
                                <th onclick="sortTable(3,'transactionsTable')" class="cursor-pointer" @click="sortColumn=3" >Net &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 2}"></i></th>
                                <th>Username</th>
                                <th>Type</th>
                                <th>Reversed at</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="h-max overflow-y-scroll">
                            <tr v-for="(transaction, index) in reverse" :key="index">
                                <td class="text-center p-1 px-3">{{ formatDate(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                <td class="text-center p-1 px-3">{{ formatTime(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                <td class="text-center p-1 px-3">$ {{ transaction.net }}</td>
                                <td class="text-center p-1 px-3">{{ transaction.follower_user_name}}</td>
                                <td class="text-center p-1 px-3">{{ transaction.TransType}}</td>
                                <td class="text-center p-1 px-3">{{ transaction.reverseTimeStamp}}</td>
                                <td class="opacity-100 cursor-pointer p-1 px-3 text-center" @click="changeCharge(ind,index)"><i v-bind:class="{'text-gray-500':openIndex==ind+index,'text-gray-500':openIndex!=ind+index,'text-red-300':transaction.reverse && transaction.reverseTimeStamp}" class="px-3 cursor-pointer fa-solid fa-rotate-left"></i></td>
                                <div v-if="!(transaction.reverse && transaction.reverseTimeStamp) && openIndex==ind+index" 
                                    class="absolute bg-gray-100 z-50 rounded p-2 -ml-36 mt-9 text-black cursor-pointer" v-bind:class="{'hidden' :!transaction.reverse}">
                                    <dInput vmodel="changeNet" vvalue='' @changeNet="changeNet = $event" />
                                    <button @click="saveTimeStamp(ind,index,transaction.transactionID,transaction.modelID),openIndex=null" class="bg-green-400 text-black rounded-full mt-1 p-1 px-4 text-base float-right">Save</button>
                                </div>
                                <div v-else-if="transaction.reverse && transaction.reverseTimeStamp && openIndex==ind+index"
                                    @click="removeTimeStamp(ind,index,transaction.transactionID,transaction.modelID), openIndex=null"
                                    class="absolute bg-gray-100 z-50 rounded p-2 pr-4 -ml-16 mt-9 text-black cursor-pointer">
                                    <i class="fa-solid fa-xmark mr-2 ml-2"></i> Remove 
                                </div>
                            </tr>
                            <tr v-if="reverse.length>0" class="text-white">
                                <td class="text-center text-gray-300 py-2">Summary:</td>
                                <td v-if="ind == 'free'" class="text-center" colspan="3"><span class="text-gray-300">Net:</span> $ {{reverseObj.netF.toFixed(2)}}</td>
                                <td v-else class="text-center" colspan="3"><span class="text-gray-300">Net:</span> $ {{reverseObj.netP.toFixed(2)}}</td>
                                <td class="text-center" colspan="3">
                                    <span class="text-gray-300">Count: </span>
                                    <span v-if="ind == 'free'">{{reverseObj.noF}}</span>
                                    <span v-else>{{reverseObj.noP}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="text-white">
                        Please select a model
                    </div>
                </div>
            </section>
        </slot>
    </slot>
    <div v-else class="text-center mt-52 text-white">It seems you don't have access to this service</div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
    import niceInput from "@/components/niceInput.vue"
    import filterSelect from "@/components/transactions/filterSelect.vue"
    import dInput from "@/components/inputTransactions.vue"
    import transactionType from "@/components/transactionType.vue"
</script>

<script>
export default {
    props:{
        selectedModel: {default() {return null;}},
        changeNet: {default() {return null;}},
        selectedEmpl: {default() {return null;}},
        selectedGroup: {default() {return null;}},
        dateFrom:{default() {return null;}},
        dateTo: {default() {return null;}},
        srchUser: {default() {return '';}},
        srchType: {default() {return null;}},
        srchNetFrom: {default() {return 0;}},
        srchNetTo: {default() {return 0;}}
    },
    watch: {
        selectedModel: function(newVal, oldVal) { // watch it
            if (this.mounted) {
                console.log("display: ", this.displayTab)
                if (this.displayTab === 1) {
                    this.getData(true);
                } else {
                    this.getReverse();
                }
            }
        },
        selectedGroup: function(newVal, oldVal) { // watch it
            if (this.mounted) {
                console.log("group was changed");
                if (this.displayTab == 2) {
                    this.getReverse('group');
                }
            }
        },
        dateFrom: function(newVal, oldVal) { // watch it
            if (this.mounted && this.selectedModel) {
                if (this.displayTab == 1) {
                    this.getData(true);
                } else {
                    this.getReverse();
                }
            }
        },
        dateTo: function(newVal, oldVal) { // watch it
            if (this.mounted && this.selectedModel) {
                if (this.displayTab == 1) {
                    this.getData(true);
                } else {
                    this.getReverse();
                }
            }
        },
        srchType: function() { this.searchBy = 1; },
        srchUser: function() { this.searchBy = 0; },
        srchNetFrom: function() { this.searchBy = 2; },
        srchNetTo: function() { this.searchBy = 2; }
    },
    components() {
      headerfilter,
      dInput,
      niceInput,
      filterSelect,
      transactionType
    },
    data() {
        return { 
            opravnenia:'0000000000000000000',
            loading: false,
            saved: false,
            displayFilter:null,
            mounted:false,
            sortColumn: null,
            transactionsObj: null,
            reverseObj: null,
            transactionsStats: null,
            openIndex: null,
            timeNow: null,
            timeZone: null,
            displayTab:1,
            transTypes: ['All','Message','Tip','Subscription','Post'],
            searchBy:0,
            filterType:0
        } 
    },
    computed: {
        filteredPaid() {
            if (this.transactionsObj) {
                if (this.searchBy == 1 && this.srchType>0) {
                    this.filterType = 0;
                    setTimeout(() => {
                        this.filterType = 1;
                    }, 2);
                    return this.transactionsObj.paid.filter(item => {
                        return item.tType.indexOf(parseInt(this.srchType)) > -1
                    })
                } else if (this.searchBy == 2 && (this.srchNetFrom>0 || this.srchNetTo>0)) {
                    this.filterType = 0;
                    setTimeout(() => {
                        this.filterType = 1;
                    }, 2);
                    var pomocna = [];
                    this.transactionsObj.paid.forEach(element => {
                        if (this.srchNetTo > this.srchNetFrom) {
                            if ((parseFloat(element.net) >= this.srchNetFrom) && (parseFloat(element.net) <= this.srchNetTo)) {
                                pomocna.push(element);
                            }
                        } else {
                            if (parseFloat(element.net) >= this.srchNetFrom) {
                                pomocna.push(element);
                            }
                        }
                    })
                    return pomocna;
                } else {
                    this.filterType = 0;
                    setTimeout(() => {
                        this.filterType = 1;
                    }, 2);
                    return this.transactionsObj.paid.filter(item => {
                        return item.follower_user_name.indexOf(this.srchUser.toLowerCase()) > -1
                    })
                }
            }
        },
        filteredFree() {
            if (this.transactionsObj) {
                if (this.searchBy == 1 && this.srchType>0) {

                    return this.transactionsObj.free.filter(item => {
                        return item.tType.indexOf(parseInt(this.srchType)) > -1
                    })
                } if (this.searchBy == 2 && (this.srchNetFrom>0 || this.srchNetTo>0)) {
                    var pomocna = [];
                    this.transactionsObj.free.forEach(element => {
                        if (this.srchNetTo > this.srchNetFrom) {
                            if ((parseFloat(element.net) >= this.srchNetFrom) && (parseFloat(element.net) <= this.srchNetTo)) {
                                pomocna.push(element);
                            }
                        } else {
                            if (parseFloat(element.net) >= this.srchNetFrom) {
                                pomocna.push(element);
                            }
                        }
                    })
                    return pomocna;
                } else {
                    return this.transactionsObj.free.filter(item => {
                        return item.follower_user_name.indexOf(this.srchUser.toLowerCase()) > -1
                    })
                }
            }
        }
    },
    methods: {
        dFilter(filter) {
            this.displayFilter = filter
            this.srchUser = ''
        },
        dTab(w) {
            this.displayTab = w
            if (w == 2) {
                this.getReverse();
            } else {
                this.getData(true);
            }
        },
        changeCharge(type,index) {
            if (this.opravnenia['14'] == 1) {
                if (this.displayTab == 1) {
                    console.log(this.transactionsObj);
                    this.transactionsObj[type][index].reverse = 1;
                    this.openIndex = type+index
                } else {
                    console.log('reverse:', this.reverseObj.values[type][index]);
                    this.reverseObj.values[type][index].reverse = 1;
                    this.openIndex = type+index
                }
            }
        },
        removeTimeStamp(type,index,transactionID,modelID) {
            if (this.opravnenia['14'] == 1) {
                if (confirm('Are you sure?')) {
                    //odošli cez Axios.
                    axios.post(baseURL+"transactions/postTransactions.php", {
                        action: "removereverse",
                        modelID: modelID,
                        transactionID: transactionID,
                        companyID: this.$cookies.get('c_id'),
                        userToken: this.$cookies.get('userToken')
                    }).then((result) => {
                        if (this.displayTab == 1) {
                            this.transactionsObj[type][index].reverse = null;
                            this.transactionsObj[type][index].reverseTimeStamp = null;
                        } else {
                            this.reverseObj.values[type].splice(index,1);
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            }
        },
        saveTimeStamp(type,index,transactionID,modelID) {
            if (this.opravnenia['14'] == 1) {
                if (this.changeNet) {
                    //odošli cez Axios.
                    axios.post(baseURL+"transactions/postTransactions.php", {
                        action: "savereverse",
                        modelID: modelID,
                        timeStamp: this.changeNet,
                        transactionID: transactionID,
                        companyID: this.$cookies.get('c_id'),
                        userToken: this.$cookies.get('userToken')
                    }).then((result) => {
                        this.transactionsObj[type][index].reverse = 1;
                        this.transactionsObj[type][index].reverseTimeStamp = moment(this.changeNet).format('YYYY.MM.DD HH:mm');
                    }, (error) => {
                        console.log(error);
                    });
                }
            }
        },
        determinZoneDST(original) {
            var tzone = 0;
            if (moment().isDST()) {
                if (moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                    tzone = parseInt(this.timeZone)
                } else {
                    tzone = (parseInt(this.timeZone)/60-1)*60
                }
            } else {
                if (moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                    tzone = (parseInt(this.timeZone)/60+1)*60
                } else {
                    tzone = parseInt(this.timeZone)
                }
            }
            return tzone;
        },
        formatTime(original) {
            var tzone = this.determinZoneDST(original);
            if (tzone<0) {
                return moment(original,"YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
            } else {
                return moment(original,"YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
            }
        },
        formatDate(original) {
            var tzone = this.determinZoneDST(original);
            if (parseInt(this.timeZone)<0) {
                return moment(original,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
            } else {
                return moment(original,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
            }
        },        
        getWholeGraph() {
            axios.get(baseURL+"transactions/", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    build: "statistics",
                    selectedModel: this.selectedModel,
                    fromDate: null,
                    toDate: null,
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.transactionsStats = result.data
            }, (error) => {
                console.log(error);
            });
        },
        getData(stats) {
            this.loading = true;
            //load transactions of this model
            axios.get(baseURL+"transactions/", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    build: "getModelsTransactions",
                    selectedModel: this.selectedModel,
                    fromDate: moment(this.dateFrom).format("YYYY-MM-DD"),
                    toDate: moment(this.dateTo).format("YYYY-MM-DD"),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.transactionsObj = result.data
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
            
            if (stats) {
                axios.get(baseURL+"transactions/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        build: "statistics",
                        selectedModel: this.selectedModel,
                        fromDate: moment(this.dateFrom).format("YYYY-MM-DD"),
                        toDate: moment(this.dateTo).format("YYYY-MM-DD"),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.transactionsStats = result.data
                }, (error) => {
                    console.log(error);
                });
            }
        },
        getReverse(target) {
            this.loading = true;
            let sModel = null;
            if (target != 'group') {
                sModel = this.selectedModel;
            }
            //load transactions of this model
            axios.get(baseURL+"transactions/", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    build: "getReverses",
                    selectedGroup: this.selectedGroup,
                    selectedModel: sModel,
                    fromDate: moment(this.dateFrom).format("YYYY-MM-DD"),
                    toDate: moment(this.dateTo).format("YYYY-MM-DD"),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.reverseObj = result.data;
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
        }
    },
    created() {
        this.timeNow = moment().format("HH:mm")
        this.timeZone = moment().utcOffset();
    },
    mounted() {
        this.loading = true;
        this.mounted = true;

        if (!(this.$cookies.get('c_id'))) {
            var companyID = null;
        } else {
            var companyID = this.$cookies.get('c_id');
        }
        axios.get(baseURL+"opravnenia/userToken.php", {
            params: {
                userToken: this.$cookies.get('userToken'),
                companyID: companyID,
                place: 'header'
            }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', params: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                this.loading = false;
            }, (error) => {
            console.log(error);
        })
    }
}

</script>
<style scoped>
  tr:nth-child(odd) {
    background-color: rgb(75, 85, 99);
    color: #D1D5DB;
  }
</style>