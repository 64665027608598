<template>
    <div class="sm:ml-22">
        <div class="fixed p-4 pl-5 top-0 bg-gray-800/50 shadow-lg z-10 border-b-2 border-gray-500" style="width:calc(100% - 5rem);">
            <h1 class="float-left mr-5 text-base sm:text-xl text-white font-bold mt-1"><i class="fa-solid fa-candy-cane text-white/50 mr-2"></i> Finance <i class="fa-solid fa-gift text-white/50 ml-2"></i></h1>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>