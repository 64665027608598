<template>
    <section class="text-left">
        <input :type="inputType" @blur="$emit('editM',false)" autocomplete="off" :placeholder="pholder" @keyup="changeInput()" @change="changeInput()" v-model="inputValue" class="inputLogin w-full mt-1 p-2 px-3 bg-transparent border border-gray-300"/>
    </section>
</template>

<script>
    export default {
        props: ['inputType','pholder','vmodel','vvalue'],
        emits: ['editM','newComment','srchUser','srchNetFrom','srchNetTo'],
        data() {
            return {
                inputValue:null
            }
        },
        watch: {
            inputValue: function(o,n) {
                this.$emit(this.vmodel, this.inputValue);
            }
        },
        methods: {
            changeInput() {
                this.$emit(this.vmodel, this.inputValue);
            }
        },
        mounted() {
            if (this.vvalue) {
                this.inputValue = this.vvalue;
            }
        }
    }
</script>