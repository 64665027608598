<template>
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">Saved</div>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>

    <div class="w-full mb-4 relative">
      <router-link :to="'../../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Shifts</h2>
    </div>
    
    <div class="float-left ml-5 px-4 p-1 bg-indigo-300 text-black rounded-full">Change</div>
    <div class="clear-both"></div>
 
    <div class="mt-5 mb-6 ml-0">
        <div class="mr-2 leading-normal">
            <label>Shift length </label><br />
            <select v-model="shiftPeriodicity" @change="calcShifts()" class="w-20 rounded p-1">
                <option value="6">6</option>
                <option value="8">8</option>
                <option value="12">12</option>
            </select>
        </div>
        
        <div class="leading-normal">
            <label class="font-semibold ">Set a new starting point </label><br />
            <select class="w-20 rounded p-1" v-model="firstShift" @change="calcShifts()">
                <option v-for="index in 24" :key="index" :value="index">{{index+':00'}}</option>
            </select>
            <!--<timePicker v-model="firstShift" v-if="firstShift" @change="calcShifts()" format="HH:mm" close-on-complete></timePicker><br />-->
        </div>
        <br />

        <slot v-if="nodata == false">
            <ol class="mr-2 leading-normal w-96 text-lg">
                <li v-for="(shifts, index) in shiftsData" :key="index" >
                    <span class="mr-2 text-gray-500">{{index+1}}.</span>
                    {{ formatTime(shifts.from) }} <span class="text-gray-400"> - </span> {{ formatTime(shifts.to)}}
                </li>
            </ol>
        </slot>
        <div v-else class="mt-10 mb-20">No data to display</div>
    </div>
   <button class="p-2 px-4 bg-indigo-300 rounded-full text-lg text-black ml-80 font-semibold" @click="saveChange()">SAVE</button>
</template>

<script>
    export default {
        data() {
            return { 
                shiftsData: [],
                loading: false,
                nodata: false,
                firstShift: 0,
                shiftPeriodicity: "8",
                saved:false,
                mounted:false,
                timeNow: null,
            } 
        },
        components() {
            timePicker
        },
        methods: {
            formatTime(original) {
                return moment.utc(original,"HH:mm:ss").local().format("HH:mm");
            },
            calcShifts() {
                console.log(this.firstShift)
                if (this.shiftPeriodicity && this.firstShift) {
                    this.nodata = false;
                    this.shiftsData = [];
                    var from = moment.utc(moment(this.firstShift+':00','HH:mm')).format("HH");

                    for (var i=1;i<=24/this.shiftPeriodicity;i++) {
                        this.shiftsData.push({id:i, hours: this.shiftPeriodicity, from: moment(moment(from+':00',"H:mm")).format("HH:mm"), to: moment(from,"H").add(this.shiftPeriodicity, 'hours').format("HH:mm")})
                        from = moment(from,"H").add(this.shiftPeriodicity, 'hours').format("HH:mm");
                    }
                }
            },
            saveChange() {
                this.loading = true;
                axios.post(baseURL+"settingspost/", {
                    shiftData: this.shiftsData,
                    action: 'saveShiftsChanges',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(result => {
                    this.saved = true;
                    setTimeout(() => (this.saved = false), 1000)
                    if (result.data) {
                        this.shiftsData[this.shiftsData.length-1].id = result.data;
                    }
                }, (error) => {
                    console.log(error);
                })
                .finally(() => this.loading = false)
            }
        },
        created() {
            this.timeNow = moment().format("HH:mm")
            axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'shiftsSettings',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then(result => {
                    if (result.data.length > 0) {
                        this.shiftsData = result.data;
                        this.shiftPeriodicity = String(result.data[0].hours);
                        let hArray = result.data[0].from.split(":");
                        this.firstShift = moment.utc(parseInt(hArray[0])+parseInt(hArray[1]),"HH:mm").local().format("HH");
                    } else {
                        this.nodata = true;
                    }
            }, (error) => {
                console.log(error);
            })
        }
    }
</script>


