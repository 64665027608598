<template>
  <leftMenu />
  <router-view></router-view>
</template>

<script setup>
  import leftMenu from '@/components/LeftMenu.vue'  
</script>

<script>
export default {
  components: { 
    leftMenu,
  },
  data() {
    return {
      showHeader: false,
      settingID: 0
    }
  },
  methods: {
  },
  created(){
  }
}
</script>

<style>
#app {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  color: #2c3e50;  
}
</style>