<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
    <h3 class="text-white font-bold text-lg sm:text-2xl m-2">{{period.charAt(0).toUpperCase() + period.substring(1)}}ly stats</h3>
    <div id="parent1">
        <table class="bg-gray-700/90 text-gray-200 whitespace-nowrap rounded-2xl font-semibold border-0" style="max-height:450px">
            <thead :id="'myhead'+period.charAt(0).toUpperCase()" class="sticky top-0">
                <tr class="firstTableLine">
                    <th class="date bg-gray-700 z-9" v-bind:colspan="1"></th>
                    <th class="" v-if="selectedModel == null"></th>
                    <th class="activeFans" v-bind:colspan="displayColumns.free == true ? '4' : '2'" style="border-left: 2px solid black;">Fans FREE <i @click="displayColumns.free = !displayColumns.free" v-bind:class="{'fa-circle-minus text-red-400':displayColumns.free,'fa-circle-plus text-green-400':!displayColumns.free}" class="cursor-pointer fa-solid ml-2"></i></th>
                    <th class="activeFans" v-bind:colspan="displayColumns.paid == true ? (blockedColumns.renewOn == 1 ? '5' : '4') : blockedColumns.renewOn == 1 ? '3' : '2'" style="border-left: 2px solid black;">Fans PAID <i @click="displayColumns.paid = !displayColumns.paid" v-bind:class="{'fa-circle-minus text-red-400':displayColumns.paid,'fa-circle-plus text-green-400':!displayColumns.paid}" class="cursor-pointer fa-solid ml-2"></i></th>            
                    <th class="messagesCount" colspan="2" style="border-left: 2px solid black;">Mass DM</th>            
                    <th class="transactionsValue" colspan="4" style="border-left: 2px solid black;">Earnings</th>            
                    <th class="average_fans" v-bind:class="{hidden: selectedModel != null}" colspan="3" style="border-left: 2px solid black;"></th>
                </tr>
                <tr>
                    <th class="date bg-gray-700 z-9">Date</th>
                    <th class="models" v-bind:class="{hidden: selectedModel != null}" title="Average number of models">AVG Models</th>
                    <th style="border-left: 2px solid black;">Active</th>
                    <slot v-if="displayColumns.free == true">
                        <th>Expired</th>
                        <th>NEW</th>
                    </slot>
                    <th>Change</th>
                    <th class="activeFans" style="border-left: 2px solid black;">Active</th>
                    <slot v-if="displayColumns.paid == true">
                        <th class="expiredFans">Expired</th>
                        <th>NEW</th>
                    </slot>
                    <th>Change</th>
                    <th>Renew</th>
                    <th class="messagesCount" style="border-left: 2px solid black;">Free</th>
                    <th class="messagesCount PAID">Paid</th>
                    <th style="border-left: 2px solid black;">Subs. PAID</th>
                    <th class="transactionsValue FREE">FREE</th>
                    <th class="transactionsValue PAID">PAID</th>
                    <th>Together</th>
                    <th class="average_fans whitespace-nowrap" v-bind:class="{hidden: selectedModel != null}" style="border-left: 2px solid black;" title="Fans FREE growth/ prospect">Growth</th>
                    <th class="average_sales whitespace-normal" v-bind:class="{hidden: selectedModel != null}">Earnings/ prospect</th>
                    <th class="whitespace-normal" v-bind:class="{hidden: selectedModel != null}">AVG Earnings/ prospect</th>
                </tr>
            </thead>
            <tbody :id="'mybody'+period.charAt(0).toUpperCase()" :onscroll="'fixscrollMetrics(\'' + 'myhead'+period.charAt(0).toUpperCase() + '\', \''+ 'mybody'+period.charAt(0).toUpperCase() + '\')'">
                <tr v-for="(metric,index) in metricsTable" :key="index" v-bind:class="{'metricsGreen text-gray-700': index % 2 == 0 && period == 'week', 'metricsYellow text-black': index % 2 == 0 && period == 'month','bg-gray-300 text-gray-800':index % 2 == 1}">
                    <td v-bind:class="{'metricsGreen text-gray-700': index % 2 == 0 && period == 'week', 'metricsYellow text-black': index % 2 == 0 && period == 'month','bg-gray-300 text-gray-800':index % 2 == 1}">{{metric.periodName}}</td>
                    <td v-bind:class="{hidden: selectedModel != null}">{{ metric.models }}</td>
                    
                    <!--FREE active fans-->
                    <td style="border-left: 2px solid black;">{{addSpace((metric.free.fans_active).toFixed(0))}}</td>
                    
                    <slot v-if="displayColumns.free == true">
                        <!--FREE Daily change expired fans-->
                        <td v-if="index == 0">0</td>
                        <td v-else 
                            v-bind:class="{'text-red-800': (metric.free.fans_expired - metricsTable[index-1].free.fans_expired) > 0,
                                            'text-green-800': (metric.free.fans_expired - metricsTable[index-1].free.fans_expired) < 0}">
                            {{ addSpace(((metric.free.fans_expired - metricsTable[index-1].free.fans_expired)).toFixed(0)) }}
                        </td>
                        
                            <!--FREE NEW fans-->
                        <td v-if="index == 0">0</td>
                        <td v-else 
                            v-bind:class="{'text-green-800': (metric.free.fans_active - metricsTable[index-1].free.fans_active + metric.free.fans_expired - metricsTable[index-1].free.fans_expired) > 0,
                                            'text-red-800': (metric.free.fans_active - metricsTable[index-1].free.fans_active + metric.free.fans_expired - metricsTable[index-1].free.fans_expired) < 0}">
                            {{ addSpace(((metric.free.fans_active - metricsTable[index-1].free.fans_active + metric.free.fans_expired - metricsTable[index-1].free.fans_expired)).toFixed(0)) }}
                        </td>
                    </slot>

                    <!--FREE Daily change in fans-->
                    <td v-if="index == 0">0</td>
                    <td v-else 
                        v-bind:class="{'text-green-800': (metric.free.fans_active - metricsTable[index-1].free.fans_active) > 0,
                                        'text-red-800': (metric.free.fans_active - metricsTable[index-1].free.fans_active) < 0}">
                        {{ addSpace(((metric.free.fans_active - metricsTable[index-1].free.fans_active)).toFixed(0)) }}
                    </td>
                    
                        <!--PAID active fans-->
                    <td style="border-left: 2px solid black;">{{addSpace((parseFloat(metric.paid.fans_active)).toFixed(0))}}</td>

                    <slot v-if="displayColumns.paid == true">
                        <!--PAID Daily change expired fans-->
                        <td v-if="index == 0">0</td>
                        <td v-else 
                            v-bind:class="{'text-red-800': (metric.paid.fans_expired - metricsTable[index-1].paid.fans_expired) > 0,
                                            'text-green-800': (metric.paid.fans_expired - metricsTable[index-1].paid.fans_expired) < 0}">
                            {{ addSpace(((metric.paid.fans_expired - metricsTable[index-1].paid.fans_expired)).toFixed(0)) }}
                        </td>

                            <!--PAID NEW fans-->
                        <td v-if="index == 0">0</td>
                        <td v-else 
                            v-bind:class="{'text-green-900': (metric.paid.fans_active - metricsTable[index-1].paid.fans_active + metric.paid.fans_expired - metricsTable[index-1].paid.fans_expired) > 0,
                                            'text-red-900': (metric.paid.fans_active - metricsTable[index-1].paid.fans_active + metric.paid.fans_expired - metricsTable[index-1].paid.fans_expired) < 0}">
                            {{ addSpace(((metric.paid.fans_active - metricsTable[index-1].paid.fans_active + metric.paid.fans_expired - metricsTable[index-1].paid.fans_expired)).toFixed(0)) }}
                        </td>
                    </slot>
                    
                        <!--PAID Daily change in fans-->
                    <td v-if="index == 0">0</td>
                    <td v-else 
                        v-bind:class="{'text-green-900': (metric.paid.fans_active - metricsTable[index-1].paid.fans_active) > 0,
                                        'text-red-900': (metric.paid.fans_active - metricsTable[index-1].paid.fans_active) < 0}">
                        {{ addSpace(metric.paid.fans_active - metricsTable[index-1].paid.fans_active) }}
                    </td>

                    <td>{{addSpace(metric.paid.renewOn)}}</td>
                    
                    <td style="border-left: 2px solid black;">{{ addSpace(parseFloat(metric.free.messages)) }}</td>
                    <td>{{ addSpace(parseFloat(metric.paid.messages)) }}</td>

                    <td style="border-left: 2px solid black;">$ {{addSpace(parseFloat(metric.paid.subsEarnings).toFixed(2))}}</td>

                    <td>$ {{ addSpace((parseFloat(metric.free.transactionSum)-metric.free.reverseSum).toFixed(2)) }}</td>
                    <td>$ {{ addSpace((parseFloat(metric.paid.transactionSum)-metric.paid.reverseSum).toFixed(2)) }}</td>
                    <td>$ {{ addSpace(parseFloat(parseFloat(metric.free.transactionSum)-metric.free.reverseSum + parseFloat(metric.paid.transactionSum)-metric.paid.reverseSum).toFixed(2)) }}</td>
                    
                        <!--FREE NEW fans / growth-->
                    <td style="border-left: 2px solid black;" v-bind:class="{hidden: selectedModel != null}" v-if="index == 0">0</td>
                    <td style="border-left: 2px solid black;" v-else 
                        v-bind:class="{hidden: selectedModel != null, 'text-green-900': (metric.free.fans_active - metricsTable[index-1].free.fans_active + metric.free.fans_expired - metricsTable[index-1].free.fans_expired) > 0,
                                        'text-red-900': (metric.free.fans_active - metricsTable[index-1].free.fans_active + metric.free.fans_expired - metricsTable[index-1].free.fans_expired) < 0}">
                        {{ addSpace((((metric.free.fans_active - metricsTable[index-1].free.fans_active)/7) / metric.models).toFixed(2)) }}
                    </td>
                    <td v-bind:class="{hidden: selectedModel != null}">$ {{ addSpace(parseFloat((metric.free.transactionSum+metric.paid.transactionSum) / metric.models).toFixed(2)) }}</td>
                    <td v-bind:class="{hidden: selectedModel != null}">$ {{ addSpace(parseFloat((metric.free.transactionSum+metric.paid.transactionSum) / metric.models / metric.periodDays).toFixed(2)) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['summary','period','blockedColumns', 'selectedBranch', 'selectedModel','selectedGroup'],
        data() {
            return {
                metricsTable: [],
                loading:false,
                displayColumns: {free:false,paid:false}
            }
        },
        watch: {
            selectedModel: function(newVal, oldVal) { // watch it
                this.getMetricData();
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                this.getMetricData();
            },
            selectedBranch: function(n,o) {
                this.metricsTable = [];
                if (this.selectedBranch !== null) {
                    this.getMetricData(this.selectedBranch,'0');
                } else {
                    this.getMetricData();
                }
            },
        },
        methods: {
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
            getMetricData(cID = null,smm = null) {
                this.loading = true;
                let cpID = cID !== null ? cID : this.$cookies.get('c_id');
                let summary = smm !== null ? smm : this.summary;
                axios.get(baseURL+"metrics/", {
                params: {
                    table: this.period,
                    groupId: this.selectedGroup,
                    modelId: this.selectedModel,
                    dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                    userToken: this.$cookies.get('userToken'),
                    companyID: cpID,
                    summary: summary
                }
                }).then((result) => {
                    if (!result.data.message) {
                        this.metricsTable = result.data
                    } else {
                        this.metricsTable = []
                    }
                }, (error) => {
                    console.log(error);
                }).finally(()=>this.loading = false);
            }
        },
        mounted() {
            this.getMetricData();
        }
    }
</script>

<style scoped>
    #parent1 {
        position: relative;
        margin-left:5px;
        width: 100%;
        overflow: hidden;
    }

    table {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        max-height:100%;
        border-collapse: collapse;
        overflow: hidden;
    }

    thead {
    /*
    Grow thead automatically to fit content, don't shrink it
    proportionately to the body.
    */
    flex: 1 0 auto;
    display: block;
    /* x-scrolling will be managed via JS */
    overflow: hidden;
    /*
    Keep header columns aligned with useless scrollbar.
    For IE11, use "dead area" color to hide scrollbar functions
    */
    scrollbar-base-color: #ccc;
    scrollbar-face-color: #ccc;
    scrollbar-highlight-color: #ccc;
    scrollbar-track-color: #ccc;
    scrollbar-arrow-color: #ccc;
    scrollbar-shadow-color: #ccc;
    scrollbar-dark-shadow-color: #ccc;
    }

    /*
    For Webkit, use "dead area" color to hide scrollbar functions
    TODO: on Chrome/Safari for Mac, scrollbars are not shown anyway and
    this creates an extra block. No impact on iOS Safari.
    */
    thead::-webkit-scrollbar { display: block; background-color: #ccc; }
    thead::-webkit-scrollbar-track { background-color: #ccc; }

    /* Scroll the actual tbody (second child on all browsers) */
    tbody {
        display: block;
        overflow-x: scroll;
    }

    /* IE11 adds an extra tbody, have to hide it */
    tbody:nth-child(3) { display: none; }

    /* The one caveat, a hard-set width is required. */
    td, th {
        width: 7em;
        min-width: 7em;
        padding: 0.3em;
    }

    td:first-child,th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left:0;
    }

tr td {
    padding: 5px;
    text-align: center;
    user-select: none;
}
tr td input {
    width: 100px;    
    text-align: center;
    color:inherit;
    background:none;
    font-size:inherit;
}
</style>