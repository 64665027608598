<template>
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">Saved</div>

    <div class="w-full mb-4 relative">
      <router-link :to="'../../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-3 text-xl text-gray-200 font-semibold text-center">Permission settings</h2>
    </div>

    <ol class="float-left mt-4 pl-6 ml-2 w-80 leading-8">
        <li v-for="(userType, index) in userTypesData" class="cursor-pointer" :key="index" v-bind:class="{'text-white': selectedOption == index}" @click="permissions(index,userType.id)">
            {{ userType.name }}
        </li>
    </ol>

    <div class="float-right rounded shadow-2xl bg-gray-600 m-4 p-6  mt-1 text-white opacity-90 h-128" v-if="permiss_open">
        <div class="h-full w-full overflow-y-scroll pr-10">
            <div class="cursor-pointer mb-2 pr-5" v-for="(permiss, indexP) in permissionsData" :key="indexP" @click="changeTF(indexP)">
                <i v-bind:class="{'fas fa-check-square': permiss_field[indexP]==1, 'far fa-square': permiss_field[indexP]==0}"></i>&nbsp; &nbsp;{{ permiss.name }}
            </div>
        </div>
    </div>
     <div class="clear-both"></div>
</template>

<script>
    export default {
        data() {
            return { 
                userTypesData: [],
                permissionsData: [],
                permiss_field: [],
                permiss_open: false,
                selectedOption: null,
                userTypeId: null,
                saved:false
            }
        },
        methods: {
            permissions(index,typeId) {
                this.permiss_field = this.userTypesData[index].permissions;
                this.selectedOption = index;
                this.userTypeId = typeId;
                this.permiss_open = true; 
            },
            replaceAt(original, index, replacement) {
                return original.substr(0, index) + replacement + original.substr(index + 1);
            },
            changeTF(indexP) {
                this.permiss_field = this.replaceAt(this.permiss_field, indexP, (parseInt(this.permiss_field[indexP]) - 1)*(-1));
                this.userTypesData[this.selectedOption].permissions = this.permiss_field;
                axios.post(baseURL+"settingspost/", {                    
                    action: "savePermissions",
                    permissionString: this.permiss_field,
                    positionID: this.userTypeId,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.saved = true;
                    setTimeout(() => (this.saved = false), 500)
                }, (error) => {
                    console.log(error);
                });
            }
        },
        created() {
            axios.get(baseURL+"lists/", {
                params: {
                    build: 'userTypes',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.userTypesData = result.data
            }, (error) => {
                console.log(error);
            });
            axios.get(baseURL+"lists/", {
                params: {
                    build: 'permissionsNames',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.permissionsData = result.data
            }, (error) => {
                console.log(error);
            }) 
        }
    }
</script>