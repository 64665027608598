<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-3 px-6 left-1/2" v-if="saved">Saved</div>

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center mb-10">Metrics settings</h2>

      <div v-for="(checkbox,chI) in checkboxes" :key="chI" @click="checkbox.value = (checkbox.value-1)*(-1), saveSettings()" class="cursor-pointer mt-3 text-white text-lg select-none">
          <i v-bind:class="{'fas fa-check-square':checkbox.value == 1,'far fa-square':checkbox.value==0}"></i> &nbsp;{{checkbox.title}}
      </div>
    </div>
    
</template>

<script>
    export default {
        data() {
            return {
                saved: false,
                loading: false,
                checkboxes: {}
            }
        },
        methods: {
            saveSettings() {
                axios.post(baseURL+"settingspost/", {                    
                    action: "metricsSettings",
                    checkboxes: this.checkboxes,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    console.log(result.data);
                    this.saved = true;
                    setTimeout(() => (this.saved = false), 700)
                }, (error) => {
                    console.log(error);
                })
           },
        },
        created() {
            axios.get(baseURL+"settingsget/", {
                params: {                 
                    action: "metricsSettings",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.checkboxes = result.data;
                console.log(this.checkboxes)
            }, (error) => {
                console.log(error);
            })
        }
    }
</script>

renew = !renew