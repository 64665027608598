<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="copied">Copied</div>
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">Saved</div>

    <div class="w-full mb-4 relative">
        <router-link :to="'/Settings'">
            <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
        </router-link>
      
        <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Marketing settings</h2>
        <section class="mt-5 leading-9">
            <ol class="">
                <li class="bg-gray-600 m-1 my-1.5 px-2 rounded" v-for="(categ,index) in linkCategories" :key="index">
                    
                    <span class="text-gray-100">
                        <input v-if="editing == index" v-on:keyup.enter="saveChange(index)" class="text-sm py-1 w-48" v-model="editingValue"/>
                        <span v-else>{{categ.name}}</span>
                    </span> 
                    <i v-if="editing == index" @click="saveChange(index)" class="text-green-500 p-2.5 pl-2 px-1.5 float-right cursor-pointer fa-solid fa-floppy-disk"></i>
                    <i v-else @click="editCategory(index)" class="p-2.5 px-1.5 float-right cursor-pointer fa-solid fa-pen-to-square"></i> 
                    <i title="Partners link" @click="copyLink(index)" class="cursor-pointer float-right px-1.5 p-2.5 fa-solid fa-link"></i></li>
            </ol>
            <div class="mt-3">
                <label>New category</label><br />
                <input v-on:keyup.enter="saveCategory()" class="float-left" placeholder="Name" v-model="newCategory" />
                <div @click="saveCategory()" class="cursor-pointer bg-indigo-300 float-left w-max leading-none ml-3 text-gray-800 p-2 px-2.5 mt-0.5 rounded-full"><i class="fa-solid fa-check"></i></div>
                <div class="clear-both"></div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading:false,
                linkCategories:null,
                newCategory:null,
                copied:false,
                saved:false,
                editing:null,
                editingValue:null
            }
        },
        methods: {
            editCategory(index) {
                this.editing = index;
                this.editingValue = this.linkCategories[index].name
                console.log("editing"+index);
            },
            saveChange(index) {
                this.editing = null;
                console.log(this.linkCategories[index].id)
                axios.post(baseURL+"settingspost/", {
                    newVal: this.editingValue,
                    categID: this.linkCategories[index].id,
                    action: 'saveChangedMarketingCateg',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(result => {
                    this.linkCategories[index].name = this.editingValue
                    this.saved = true;
                }, (error) => {
                    console.log(error);
                }).finally(() => this.newCategory = null, setTimeout(() => {this.saved=false;}, 1000))

                console.log("Saving a change" + this.editingValue)
            },
            copyLink(index) {
                axios.get(baseURL+"settingsget/", {
                    params: {
                        action: "getLinkSecret",
                        linkID: this.linkCategories[index].id,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((res) => {
                    navigator.clipboard.writeText(res.data);
                    this.copied = true;
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false, setTimeout(() => {this.copied=false;}, 1000))
            },
            saveCategory() {
                axios.post(baseURL+"settingspost/", {
                    categoryName: this.newCategory,
                    action: 'saveMarketingCategory',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(result => {
                    this.linkCategories.push({id:result.data,name:this.newCategory})
                }, (error) => {
                    console.log(error);
                }).finally(() => this.newCategory = null)
            }
        },
        created() {
            axios.get(baseURL+"marketingStats/", {
                params: {
                    action: "getLinkCategories",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((res) => {
                this.linkCategories = res.data
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false)
        }
    }
</script>
