<template>
    <div class="w-full mb-4 relative">
      <router-link :to="'../../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Logs</h2>

        <div class="text-indigo-300 mb-1 float-right -mt-6">Viewing in timezone <strong class="text-indigo-100">UTC {{(timeZone/60)<0 ? '-'+timeZone/60 : '+'+timeZone/60}} ({{timeNow}})</strong></div>
        <div class="clear-both"></div>

        <section class="w-full absolute " >
            <div class="overflow-x-scroll w-full flex cursor-pointer">
                <slot v-for="(log,indexB) in logsCategories" :key="indexB">
                    <div v-if="log.date" v-bind:class="{'bg-indigo-300' :selectedLog == indexB,'text-indigo-300':selectedLog != indexB}" class="border-2 border-indigo-300 p-0.5 px-3 mt-5 mr-4 mb-2 text-base font-normal rounded-lg text-black float-left" 
                        @click="setLogOption(indexB)">
                        {{log.name}}
                    </div>
                </slot>
                <div class="clear-both"></div>
            </div>
        </section>

        <div class="mt-14 block w-full"><br /></div>
        <div class="float-right relative">
            <datepicker v-model="dateFrom" v-if="dateFrom" class="datepickerInput" :lowerLimit="lowerLimit"></datepicker>
            <datepicker v-model="dateTo" v-if="dateTo" class="datepickerInput" :upperLimit="new Date()"></datepicker>
        </div>
        <div class="clear-both"></div>

        <div class="mt-3 overflow-y-scroll max-h-128">
            <table class="text-black bg-gray-400" style="min-width:1000px">
                <thead class="sticky top-0 bg-gray-800 text-white border-t-0">
                    <tr class="-mt-1">
                        <th class="">Change by</th>
                        <th>Date</th>
                        <th class="">Time</th>
                        <th class="">Change on</th>
                        <th>Original value</th>
                        <th>New value</th>
                        <th>Note</th>
                    </tr>
                </thead>
                <tbody v-if="logsCategories[selectedLog] && logsCategories[selectedLog].logs">
                    <tr class="text-center" v-for="(log_td, ind) in logsCategories[selectedLog].logs" :key="ind" v-bind:class="{silverRow: ind % 2 == 0}">
                        <td>{{ log_td.employee }}</td>
                        <td>{{ log_td.datum }}</td>
                        <td>{{ formatTime(log_td.cas) }}</td>
                        <td>{{ log_td.Changed_on }}</td>
                        <td v-if="selectedLog == 'metrics'" >{{ isNaN(log_td.Original_Value) ? log_td.Original_Value : log_td.Original_Value === '' ? '' : parseFloat(log_td.Original_Value).toFixed(2) }}</td>
                        <td v-else>{{log_td.Original_Value}}</td>
                        <td>{{ log_td.New_Value }}</td>
                        <td>{{ log_td.Note }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return { 
                logsData: [],
                timeNow: null,
                timeZone: null,
                selectedLog: 1,
                dateFrom: null,
                dateTo: new Date(),
                lowerLimit: null,
                mounted:false,
                logsCategories:[]
            }
        }, 
        watch: {
            dateFrom: function () {
                if (this.mounted === true) {
                    this.getLogs(this.logsCategories[this.selectedLog].name)
                }
            },
            dateTo: function () {
                if (this.mounted === true) {
                    this.getLogs(this.logsCategories[this.selectedLog].name)
                }
            },
        },
        methods: {
            setLowerLimit() {
                this.lowerLimit = this.logsData[this.selectedLog].length>0 ? new Date(this.logsData[this.selectedLog][this.logsData[this.selectedLog].length-1].date) : null;
                if (this.dateFrom < this.lowerLimit) {this.dateFrom = this.lowerLimit;}
            },
            setLogOption(index) {
                this.selectedLog = index;
                this.getLogs(this.logsCategories[this.selectedLog].name);
                this.lowerLimit = new Date(this.logsCategories[this.selectedLog].date)
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            formatTime(original) {
                if (parseInt(this.timeZone)<0) {
                    return moment(original,"HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format("HH:mm:ss");
                } else {
                    return moment(original,"HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format("HH:mm:ss");
                }
            },
            getLogs(filter) {
                axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'getLogs',
                        filter: filter,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id'),
                        dateFrom: moment(this.dateFrom).format("YYYY-MM-DD"),
                        dateTo: moment(this.dateTo).format("YYYY-MM-DD")
                    }
                }).then((result) => {
                    this.logsCategories[this.selectedLog].logs = result.data
                }, (error) => {
                    console.log(error);
                })
            }
        },
        created() {
            axios.get(baseURL+"settingsget/", {
                params: {
                    action: 'logs_category',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    dateFrom: moment(this.dateFrom).format("YYYY-MM-DD"),
                    dateTo: moment(this.dateTo).format("YYYY-MM-DD")
                }
            }).then((result) => {
                this.logsCategories = result.data
                this.lowerLimit = new Date(this.logsCategories[this.selectedLog].date)
            }, (error) => {
                console.log(error);
            })
            
            this.timeNow = moment().format("HH:mm");
            this.timeZone = moment().utcOffset();

            var dateF = new Date();
            dateF.setDate(dateF.getDate() - 60);
            this.dateFrom = dateF;
        },
        mounted() {
            this.mounted = true;
        }
    }
</script>

<style scoped>
    td {
        padding:5px;
        text-align:center;
    }
</style>