<template>
    <div class="w-full z-40 top-0 fixed h-screen mb-10 bg-gray-800">
    
    <h1 class="text-white text-center mt-5 font-semibold text-3xl">Please select a company</h1><br />
    <div class="w-96 mx-auto z-50 relative h-full overflow-y-scroll">
        <ol class="h-max w-full mt-0 pb-20">
            <li v-for="(branch, index) in branches" :key="index" 
                @click="selectedCompany(branch.companyID)"
                class="bg-gray-600 text-white p-1 px-4 rounded w-full cursor-pointer font-semibold text-center whitespace-nowrap m-1 mt-2">
                {{branch.companyName}}
            </li>
        </ol>
    </div>
    </div>
</template>

<script>
    export default {
        props: ['branches'],
        data() {
            return {
                branch:null
            }
        },
        methods: {
            selectedCompany(compID) {
                console.log(compID);
                this.$cookies.set('c_id',compID)
                location.reload();
            }
        },
    }
</script>