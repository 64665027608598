<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div> 
    <headerfilter :pageTitle="'Promo'" @selectedModel="selectedModel = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event" />
    
    <section v-if="opravnenia != null && opravnenia[16] == 1 && myself && myself.ownMarketing == 1">
        
        <div class="whitespace-nowrap cursor-pointer w-min p-1 px-3 mt-24 ml-24 rounded-full text-gray-800 bg-indigo-300" @click="refresh()" title="Refresh"><i class="fas fa-redo"></i> Refresh</div>

        <section class="relative ml-20">
            <section class="flex">
                <section class="float-left" v-for="(category, gIndex) in marketingStats" :key="gIndex" >
                    <slot>
                        <section class="text-white tracking-widest uppercase block bg-transparent relative my-5 ml-4 w-max text-3xl font-bold float-left mr-4">{{gIndex}}</section>
                    </slot>
                    <section v-if="gIndex == 'organized'" class="relative clear-both w-max">
                        <div class="ml-3 overflow-x-scroll overflow-y-hidden mb-16 grid grid-cols-1">
                            <div class="float-left justify-center p-4 rounded mb-5 mr-8 ml-0 bg-gray-700" v-for="(partner,i) in category" :key="i">       
                                <div class="col-span-10">
                                    <div class="overflow-auto lg:overflow-visible relative">
                                        <div>
                                            <h2 class="text-white absolute bg-gray-800 p-1 px-2 rounded">Claims: {{partner.claims}}</h2>
                                            <h2 class="text-center font-bold mt-0 mb-5 text-gray-200">{{partner.name}}</h2>
                                        </div>
                                        <div @click="addFromClipboard(gIndex,i)" class="whitespace-nowrap cursor-pointer w-min p-1 -mt-11 px-3 absolute right-0 rounded-full text-gray-800 bg-indigo-300 text-base"><i class="fa-solid fa-paste"></i> Add from clipboard</div>
                                        <div class="text-white w-144" v-if="!partner.links.length>0">No links yet</div>
                                        <table v-else class="text-white border-collapse w-full border-separate space-y-4 text-sm whitespace-nowrap overflow-x-hidden h-80 block border-0">
                                            <thead class="text-gray-800 bg-white sticky top-1 z-9">
                                                <tr>
                                                    <th class="p-3 border-0 border-r-1">ID</th>
                                                    <th class="p-3 border-0 border-r-1">Model</th>
                                                    <th class="p-3 border-0 border-r-1">Model no.</th>
                                                    <th class="p-3 border-0 border-r-1">Branch</th>
                                                    <th class="p-3 border-0 border-r-1">Group</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Url</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Days</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Claims</th>
                                                    <th class="p-3 text-center border-0 border-r-1">Claims <small>total</small></th>
                                                    <th class="p-3 text-center border-0 border-r-1">Created</th>
                                                    <!--<th class="p-3 text-center border-0">Action</th>-->
                                                </tr>
                                            </thead>
                                            <tbody class="border-0">
                                                <tr class="relative text-white border-0 mt-1 bg-gray-800 transition-all tranform"
                                                    @click="openLink(link.linkID,gIndex,i,index)" v-for="(link,index) in partner.links" :key="index">
                                                    
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="absolute bg-red-500 bg-opacity-90 z-10 text-black-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-if="!link.linkID && link.waiting">Validation failed!
                                                            <div class="absolute right-5 rounded-full bg-white text-xs top-2 text-black p-1 px-2 cursor-pointer" @click="deleteLink(gIndex,i,index)">DELETE</div>                                                   </div> 
                                                        <div class="absolute bg-indigo-200 bg-opacity-90 z-10 text-gray-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-else-if="!link.linkID"><i class="fa-solid fa-spinner animate-spin mr-2"></i> Waiting for validation <span class="font-semibold">(can take up to 3 hours)</span></div>
                                                        <span class="text-gray-400">{{link.linkID}}</span>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-24 truncate text-right" :title="link.modelName">{{link.modelName}}</div>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-16 truncate text-right">{{link.modelCode}}</div>
                                                    </td>     
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-16 truncate text-right" :title="link.modelBranch">{{link.modelBranch}}</div>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-16 truncate text-right" :title="link.modelGroup">{{link.modelGroup}}</div>
                                                    </td>                    
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        <div class="w-64 truncate text-right" :title=link.trialLink>{{link.displayLink}}</div>
                                                    </td>
                                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        {{link.subscribeDays}}
                                                    </td>
                                                    <td class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        {{link.claimsPeriod}}
                                                    </td>
                                                    <td class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                                        {{link.claimCount}}
                                                    </td>
                                                    <td class="p-2 leading-loose border-0 border-r-1 text-red-500 font-bold text-center cursor-pointer" v-if="link.expired == 1">LINK EXPIRED</td>
                                                    <td v-else class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">  
                                                        {{link.createdAt}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </section>
                    <section v-else class="mt-10">
                        <table class="text-white border-collapse w-full border-separate space-y-4 text-sm whitespace-nowrap overflow-x-hidden h-full block border-0">
                            <thead class="text-gray-800 bg-white sticky top-1 z-9">
                                <tr>
                                    <th class="p-3 border-0 border-r-1">ID</th>
                                    <th class="p-3 border-0 border-r-1">Model</th>
                                    <th class="p-3 border-0 border-r-1">Model no.</th>
                                    <th class="p-3 border-0 border-r-1">Branch</th>
                                    <th class="p-3 border-0 border-r-1">Group</th>
                                    <th class="p-3 text-center border-0 border-r-1">Url</th>
                                    <th class="p-3 text-center border-0 border-r-1">Days</th>
                                    <th class="p-3 text-center border-0 border-r-1">Claims</th>
                                    <th class="p-3 text-center border-0 border-r-1">Claims T.</th>
                                    <th class="p-3 text-center border-0 border-r-1">Created</th>
                                </tr>
                            </thead>
                            <tbody class="border-0">
                                <tr class="relative text-white border-0 mt-1 bg-gray-800 transition-all tranform"
                                    @click="openLink(link.linkID,gIndex,index,index)" v-for="(link,index) in category" :key="index">
                                    
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="absolute bg-red-500 bg-opacity-90 z-10 text-black-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-if="!link.linkID && link.waiting">Validation failed!
                                            <div class="absolute right-5 rounded-full bg-white text-xs top-2 text-black p-1 px-2 cursor-pointer" @click="deleteLink(gIndex,i,index)">DELETE</div>                                                   </div> 
                                        <div class="absolute bg-indigo-200 bg-opacity-90 z-10 text-gray-800 opacity-100 text-center -mt-5 -ml-3 border border-white rounded w-full font-bold p-2.5" v-else-if="!link.linkID"><i class="fa-solid fa-spinner animate-spin mr-2"></i> Waiting for validation <span class="font-semibold">(can take up to 3 hours)</span></div>
                                        <span class="text-gray-400">{{link.linkID}}</span>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-24 truncate text-right" :title="link.modelName">{{link.modelName}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-16 truncate text-right">{{link.modelCode}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-16 truncate text-right" :title="link.modelBranch">{{link.modelBranch}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-16 truncate text-right" :title="link.modelGroup">{{link.modelGroup}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        <div class="w-64 truncate text-right" :title="link.trialLink">{{link.displayLink}}</div>
                                    </td>
                                    <td class="p-3 border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        {{link.subscribeDays}}
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        {{link.claimsPeriod}}
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">
                                        {{link.claimCount}}
                                    </td>
                                    <td class="p-2 leading-loose border-0 border-r-1 text-red-500 font-bold text-center cursor-pointer" v-if="link.expired == 1">LINK EXPIRED</td>
                                    <td v-else class="p-2 leading-loose border-0 border-r-1" v-bind:class="{'hover:scale-101 cursor-pointer':link.linkID,'opacity-50':link.active!=1}">  
                                        {{link.createdAt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </section>
            </section>
        </section>
        <!-- MODAL WINDOW -->
        <section v-if="openModal" @click="openModal = false" class="fixed bg-gray-500 bg-opacity-80 w-screen h-screen top-0 left-0 z-50">
            <section v-if="openModal && modalData" v-on:click.stop="" class="container px-10 mx-auto h-screen top-0 z-50">
                <section class="p-5 bg-gray-100 relative text-black mx-auto rounded h-5/6 mt-20 overflow-y-scroll">
                    <i class="fa-solid fa-xmark cursor-pointer p-2 pr-5 absolute right-0 top-3" @click="openModal = false"></i>
                    <h1 class="text-center font-semibold text-2xl">Link data</h1>
                    <h2 class="text-center" >{{modalData.modelName}}</h2>
                    <section class="grid grid-cols-2 mt-5 gap-2">
                        <section>
                            <div class="bg-gray-200 rounded p-1 px-3 max-h-80 mb-2" v-if="modalData.claimCount>0">
                                <label class="text-gray-500">Graph</label><br />
                                <section v-if="modalData.linkStats.length>0" class="h-60" style="transform: scale(1,-1)">
                                    <div v-for="(stat,grIndex) in modalData.linkStats" :key="grIndex" :title="stat.claims" class="float-left align-baseline" 
                                    :style="'width:'+(100/modalData.linkStats.length)+'%;height:'+((stat.claims/modalData.max)*100)+'%'">
                                        <div style="transform: scale(1,-1)" class="h-full bg-green-600 bg-opacity-70 mx-0.5 rounded-t align-baseline"></div>
                                    </div>
                                    <div class="clear-both"></div>
                                </section>
                                <section>
                                    <div v-for="(stat,grIndex) in modalData.linkStats" :key="grIndex"
                                    :style="'width:'+(100/modalData.linkStats.length)+'%'" class="mt-4 mb-4 float-left text-center origin-center -rotate-90">
                                        <small class="text-xs truncate mx-0.5">{{stat.date}}</small>
                                    </div>
                                    <div class="clear-both"></div>
                                </section>
                            </div>
                            <div class="mt-2 rounded bg-gray-200 p-1 px-3">
                                <label class="text-gray-500">Claims in period</label><br />
                                {{modalData.claimCount}}
                            </div>
                            <div class="mt-2 rounded bg-gray-200 p-1 px-3">
                                <label class="text-gray-500">Total claims</label><br />
                                {{modalData.claimsTotal}}
                            </div>
                            <div class="mt-2 rounded bg-gray-200 p-1 px-3">
                                <label class="text-gray-500">Subscribe Count</label><br />
                                {{modalData.subscribeCount}}
                            </div>
                        </section>
                        <section class="grid grid-rows-2">
                            <section class="p-3 px-5 rounded bg-gray-200 relative">
                                <label class="text-gray-500">Active</label><br />
                                <div class="relative h-7 mt-1 cursor-pointer" @click="modalData.active = (parseInt(modalData.active)-1)*(-1)">
                                    <div v-bind:class="{'ml-6 bg-green-500':modalData.active==1,'bg-red-400':modalData.active!=1}" class="absolute rounded-full w-5 h-5 z-10 transition-all border border-white duration-500"></div>
                                    <div class="absolute rounded-3xl w-12 px-0.5 -ml-0.5 -mt-0.5 h-6 transition-all bg-gray-300 border border-gray-400"></div>
                                    <div class="text-red-500 font-semibold font-base ml-16" v-if="modalData.expired == 1">Expired</div>
                                </div>

                                <label class="text-gray-500">Category</label><br />
                                <select class="mb-3 mt-1" v-model="modalData.categoryId" v-bind:class="{'bg-red-500 bg-opacity-40':saveClicked && !(modalData.categoryId>0)}">
                                    <option v-for="(category,index) in linkCategories" :key="index" :value="category.id">{{category.name}}</option>
                                </select><br />
                                <label class="text-gray-500">Note</label><br />
                                <textarea class="mt-1" v-model="modalData.linkNote"></textarea>
                            </section>
                            <section>
                                <div class="mt-2 rounded bg-gray-200 p-1 px-3">
                                    <label class="text-gray-500">Subscribe Days</label><br />
                                    {{modalData.subscribeDays}}
                                </div>
                                <div class="mt-2 bg-gray-200 rounded p-1 px-3">
                                    <label class="text-gray-500">Link ID</label><br />
                                    {{modalData.linkID}}
                                </div>
                                <div class="mt-2 rounded bg-gray-200 p-1 px-3">
                                    <label class="text-gray-500">Created At</label><br />
                                    {{modalData.createdAt}}
                                </div>
                                <div class="mt-2 rounded bg-gray-200  p-1 px-3 truncate">
                                    <label class="text-gray-500">Link URL</label><br />
                                    {{modalData.trialLink}}
                                </div>
                            </section>
                            <div class="whitespace-nowrap cursor-pointer w-min p-1.5 px-5 absolute font-semibold bottom-4 right-4 rounded-full text-gray-800 bg-indigo-300" @click="saveChanges()" title="Save"><i class="fa-solid fa-floppy-disk"></i> Save</div>
                        </section>
                    </section>
                </section>    
            </section>
        </section>
    </section>

    <div v-else class="text-center mt-52 text-white">It seems you don't have access to this service</div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        props: ['selectedModel','dateFrom','dateTo'],
        components() {headerfilter},
        data() {
           return {
               loading:false,
               marketingStats: null,
               saveClicked:false,
               modalData:null,
               indexesModal:[0,0,0],               
               linkCategories:null,
               openModal:false,
               opravnenia:'0000000000000000000',
               myself:null,
               mounted: false
           }
        }, 
        watch: {
            dateFrom: function(newVal, oldVal) {
                if (this.mounted) {
                    this.getLinks(this.selectedModel);
                }
            },
            dateTo: function(newVal, oldVal) {
                if (this.mounted) {
                    this.getLinks(this.selectedModel);
                }
            },
            selectedModel: function(nV,oV) {
                if (this.mounted) {
                    this.getLinks(this.selectedModel);
                }
            }
        },
        methods: {
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
            refresh() {
                this.getLinks(this.selectedModel);
            },
            deleteLink(gIndex,i,index) {
                if(confirm('This link will be deleted. Are you sure?')) {
                    axios.post(baseURL+"marketingPOST/", {                    
                        action: "deleteLink",
                        linkURL: this.marketingStats[gIndex].modelsCards[i].links[index].trialLink,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }, {headers: {'Content-type': 'application/x-www-form-urlencoded'}
                    }).then((result) => {
                        this.marketingStats[gIndex].modelsCards[i].links.splice(parseInt(index),1)
                    }, (error) => {
                        console.log(error);
                    })
                }
            },
            saveChanges() {
                this.saveClicked = true;
                if (this.modalData.active == true) {
                    this.modalData.active = 1;
                } else {
                    this.modalData.active = 0;
                }
                if (parseInt(this.modalData.categoryId)>0) {
                    axios.post(baseURL+"marketingPOST/", {                    
                        action: "saveLink",
                        data: this.modalData,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data == 'error - duplicate') {
                            alert('This link is already in use!')
                            this.openModal = false;
                        } else {
                            var i = this.indexesModal;
                            if (i[0] == 'organized') {
                                // moving inside categorized
                                if (this.modalData.categoryId != this.marketingStats['organized'][i[1]].links[i[2]].categId) {
                                    // category change
                                    let categID = this.modalData.categoryId;
                                    var indexKey = null;
                                    this.marketingStats['organized'].forEach(function(value,key) {
                                        if (value.id === parseInt(categID)) {indexKey = key;}
                                    });
                                    if (indexKey !== null) {
                                        this.marketingStats['organized'][i[1]].links[i[2]].categId = this.modalData.categoryId
                                        this.marketingStats['organized'][indexKey].links.push(this.marketingStats['organized'][i[1]].links[i[2]]);
                                    }

                                    // moving from the previous partner
                                    this.marketingStats['organized'][i[1]].links.splice([i[2]],1);
                                } 
                            } else {
                                // moving into the organized
                                let categID = this.modalData.categoryId;
                                var indexKey = null;
                                this.marketingStats['organized'].forEach(function(value,key) {
                                    if (value.id === parseInt(categID)) {
                                        indexKey = key;
                                    }
                                });
                                if (indexKey !== null) {
                                    this.marketingStats['organized'][indexKey].links.push(this.marketingStats['unorganized'][i[1]]);
                                }

                                // moving out of unorganized
                                this.marketingStats['unorganized'].splice(i[1],1);
                            }

                            // closing the modal window
                            this.openModal = false;
                        }
                    }, (error) => {
                        console.log(error);
                    }).finally(()=>this.saveClicked = false)
                    
                }
            },
            addFromClipboard(index,i) {
                setTimeout(async () => {
                    const text = await navigator.clipboard.readText();
                    if (text.substring(0,34) == 'https://onlyfans.com/action/trial/') {
                        this.openModal = true;
                        this.modalData = {id: this.marketingStats[index].modelsCards[i].id,modelName: this.marketingStats[index].modelsCards[i].name,
                            trialLink: text,displayLink: text,linkID: "",subscribeDays: "0",subscribeCount: "0",
                            claimCount: "0",linkNote: "",expiredAt: null,active: true,
                            linkStats: [],max: "0", createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),categoryId: '0',categoryName: ""};
                    } else {
                        alert('Link not valid');
                    }
                }, 200);
            },
            linkData(linkID) {
                axios.get(baseURL+"marketingStats/", {
                    params: {
                        action: "linkDetails",
                        userToken: this.$cookies.get('userToken'),
                        linkID: linkID,
                        companyID: this.$cookies.get('c_id'),
                        dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                        dateTo: moment(this.dateTo).format('YYYY-MM-DD')
                    }
                }).then((res) => {
                    if (res.data.success == true) {
                        this.openModal = true;
                        this.modalData = res.data.values;
                    } else {
                        alert("Access denied");
                    }
                }, (error) => {
                    console.log(error);
                })
            },
            openLink(linkID,gIndex,i,lIndex) {
                if (linkID) {
                    this.indexesModal = [gIndex,i,lIndex]                    
                    this.saveClicked = false;
                    this.linkData(linkID);
                }
            },
            dayFormat(origDate) {
                if (origDate != '0') {
                    return moment(origDate).format("DD.MM.YYYY") 
                } else {return 'Not expired'}
            },
            getLinks(model) {
                if (!(this.$cookies.get('c_id'))) {
                    var companyID = null;
                } else {
                    var companyID = this.$cookies.get('c_id');
                }
                this.loading = true;
                axios.get(baseURL+"marketingStats/", {
                    params: {
                        action: "getTrialLinks",
                        userToken: this.$cookies.get('userToken'),
                        companyID: companyID,
                        dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                        dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                        modelFilter: model
                    }
                }).then((res) => {
                    if (res.data) {
                        this.marketingStats = res.data
                    } else {
                        this.marketingStats = null;
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            }
        },
        created() {
            this.loading = true;
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                    this.myself = result.data.myself;
                }, (error) => {
                console.log(error);
            });

            axios.get(baseURL+"marketingStats/", {
                params: {
                    action: "getLinkCategories",
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID
                }
            }).then((res) => {
                this.linkCategories = res.data
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false)
        },
        mounted() {
            this.getLinks(null);
            this.mounted = true;
        }
    }
</script>

<style scoped>
	.table {
		border-spacing: 0 15px;
        border:0;
	}

	i {
		font-size: 1rem !important;
	}

	.table tr {
		border-radius: 5px;
	}

	tr td:nth-child(n+10),
	tr th:nth-child(n+10) {
		border-radius: 0 5px 5px 0;
	}

	tr td:nth-child(1),
	tr th:nth-child(1) {
		border-radius: 5px 0 0 5px;
	}

    tbody tr:nth-child(odd) {
        background:#1a212c;
    }

    tbody tr:nth-child(even) {
        background:#2c333d;
    }
</style>