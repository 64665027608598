<template>
    <div>
        <select @keyup="changeInput()" @change="changeInput()" v-model="inputValue" class="w-full mt-1 p-1.5">
            <option v-for="(type,tIndex) in data" :key="tIndex" :value="tIndex">{{type}}</option>
        </select>
    </div>
</template>

<script>
    export default {
        props: ['vmodel','data'],
        emits: ['srchType'],
        data() {
            return {
                inputValue:null      
            }
        },
        watch: {
            inputValue: function(o,n) {
                this.$emit(this.vmodel, this.inputValue);
            }
        },
        methods: {
            changeInput() {
                this.$emit(this.vmodel, this.inputValue);
            }
        },
        mounted() {
            if (this.vvalue) {
                this.inputValue = this.vvalue;
            }
        }
    }
</script>