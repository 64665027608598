<template>
    <div>
        <div class="bg-green-300 rounded-full text-black p-2 fixed z-50 top-5 px-6 left-1/2" v-if="saved">Saved</div>

        <div class="w-full mb-4 relative">
        <router-link to="../employees" v-if="$route.params.edit">
            <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
        </router-link>
        <router-link to="../../settings" v-else>
            <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
        </router-link>
        <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center" v-if="$route.params.edit">Edit an employee</h2>
        <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center" v-else>Add an employee</h2>
        </div>
        <section class="grid grid-cols-3 gap-8">
            <div class="mt-5 col-span-2">
                <div>
                    <label>Name</label><br />
                    <input type="text" placeholder="Name" v-model="emplData.name" />
                    <i class="text-red-500 absolute -ml-4 mt-3 fas fa-exclamation" v-if="formSubmit && !emplData.name"></i>
                </div>                
                <div class="float-left mr-5">
                    <label>Email adress</label><br />
                    <input type="text" placeholder="Email" v-model="emplData.email" @blur="validateEmail(emplData.email)" />
                    <i class="text-red-500 absolute -ml-4 mt-3 fas fa-exclamation" v-if="formSubmit && !emplData.email"></i>
                </div>        
                <div class="float-left">
                    <label>Password</label><br />
                    <span v-if="passwordEditing || !emplData.id">
                        <input type="text" placeholder="Password" v-model="emplData.password" >
                        <i class="text-red-500 absolute -ml-4 mt-3 fas fa-exclamation" v-if="formSubmit && !emplData.password"></i>
                    </span>
                    <div v-else class="cursor-pointer bg-indigo-300 p-1 px-3 text-black rounded-full" @click="passwordEditing = true">Edit password</div>
                </div>
                <div class="clear-both"></div>

                <div class="float-left mr-5">
                    <label>Type of account</label><br />
                    <select v-model="emplData.positionID" @change="positionChange()">
                        <option v-for="(type,index) in accountTypeList" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                    <i class="text-red-500 absolute -ml-7 mt-3 fas fa-exclamation" v-if="formSubmit && !emplData.positionID"></i>
                </div>
                <div class="float-left">
                    <label>Hourly Wage</label><br />
                    $ <input type="text" class="w-24 mr-20" placeholder="Wage" v-model="emplData.hourly_wage">
                </div>

                <div class="clear-both"></div>

                <label>Salary System</label><br />
                <input type="text" placeholder="Salary system" v-model="emplData.salarySystem">

                <div class="clear-both"></div>

                <slot v-if="emplData.positionID == 16 || emplData.positionID == 4 || emplData.positionID == 17">
                    <div class="float-left mr-5">
                        <label>Marketing Team</label><br />
                        <select class="mt-1" v-model="emplData.groupID" v-if="noMarkGroups">
                            <option value="0">All teams</option>
                        </select>
                        <select class="mt-1" v-model="emplData.groupID" v-else-if="marketingTeamList.length>1" @change="getAssistantsList(emplData.groupID);">
                            <option value="0">All teams</option>
                            <option v-for="(team,index) in marketingTeamList" :key="index" :value="team.id">{{ team.name }}</option>
                        </select>
                        <span v-else class="text-white mr-5 text-lg mt-1 text-semibold"><br /></span><br />  
                    </div>
                    <div class="float-left" v-if="assistantsList.length>0 && emplData.groupID>0">
                        <div v-if="assistantsList || emplData.teamID">
                            <label>Assistant</label><br />
                            <select class="mt-1 clear-both" v-model="emplData.teamID">
                                <option value="0">All assistants</option>
                                <option v-for="(assistant,index) in assistantsList" :key="index" :value="assistant.id">{{ assistant.name }}</option>
                            </select>
                        </div>
                    </div>
                </slot>
                <slot v-else-if="emplData.positionID == 5 || emplData.positionID == 6">
                    <label>Add models</label><br />
                    <select class="mt-1 float-left" v-model="sm.pID">
                        <option value="0">Select models</option>
                        <slot v-for="(model,index) in modelsList" :key="index">
                            <option v-if="model.active" :value="index">{{model.name}} - {{ model.code }}</option>
                        </slot>
                    </select>
                    <div @click="addModel()" class="cursor-pointer bg-indigo-300 float-left w-max text-black p-1.5 mt-1 ml-3 px-3 rounded-xl"><i class="fa-solid fa-plus"></i> Add</div>
                    <div class="clear-both"></div>
                    <slot v-if="emplData.attachedModels">
                        <div class="mt-1" v-for="(model,index) in emplData.attachedModels" :key="index">- {{model.name}} <i @click="emplData.attachedModels.splice(index,1)" class="text-red-400 ml-2 cursor-pointer fa-solid fa-xmark"></i></div>
                    </slot>
                </slot>
                <slot v-else-if="emplData.positionID && (!(emplData.positionID == 2 || emplData.positionID == 8 || emplData.positionID == 13))">
                    <div class="float-left mr-5">
                        <label>Group</label><br />
                        <select class="mt-1" v-model="emplData.groupID" v-if="noGroups">
                            <option value="0">All groups</option>
                        </select>
                        <select class="mt-1" v-model="emplData.groupID" v-else-if="groupsList.length>1" @change="switchView($event, $event.target.selectedIndex)">
                            <option value="0">All groups</option>
                            <option v-for="(group,index) in groupsList" :key="index" :value="group.id">{{ group.name }}</option>
                        </select>
                        <span v-else class="text-white mr-5 text-lg mt-1 text-semibold">{{ singleGroup.name }}<br /></span><br />  
                    </div>
                    <!-- if group has teams -->
                    <div class="float-left" v-if="(groupsList.length>0 && groupIndex!=null) || emplData.teamID">
                        <div v-if="(groupIndex!=-1 && groupsList[groupIndex] && groupsList[groupIndex].hasChildren == true) || emplData.teamID">
                            <label>Team</label><br />
                            <select class="mt-1 clear-both" v-model="emplData.teamID" v-if="groupsList[groupIndex]">
                                <option value="0">All teams</option>
                                <option v-for="(team,index) in groupsList[groupIndex].children" :key="index" :value="team.id">{{ team.name }}</option>
                            </select>
                        </div>
                    </div>
                </slot>
                    
                <div class="clear-both"></div>
            </div>
            <div class="mt-3 bg-transparent">
                <label class="text-gray-400">Employee color: </label><br />
                <div class="mt-1 relative" v-if="color != null">
                    <ColorPicker
                    theme="dark"
                    :color="color"
                    :sucker-hide="false"
                    :sucker-canvas="suckerCanvas"
                    :sucker-area="suckerArea"
                    @changeColor="changeColor"
                    @openSucker="true"
                    style="box-sizing: content-box;display:block;position:relative;width:200px;height:330px"
                    />
                </div>
                <button @click="saveForm()" class="delay-75  duration-75 transition-all float-right p-2 px-7 mt-5 text-lg rounded-full text-black font-medium bg-indigo-300 uppercase">
                    <i class="fa-solid fa-spinner animate-spin" v-if="loading"></i> {{buttonValue}}
                </button>
                <div class="clear-both"></div>
            </div>
        </section>
    </div>
</template>

<script setup>
    import employees from '@/components/settings/employees.vue'
    import { ColorPicker } from 'vue-color-kit'
    import 'vue-color-kit/dist/vue-color-kit.css'
</script>

<script>
    export default {
        data() {
            return {
                groupsList: [{hasChildren:false,children:[]}],
                marketingTeamList: [],
                assistantsList: [],
                accountTypeList: [],
                loading: false,
                buttonValue: 'Create account',
                emplData: {teamID:0,groupID:0,positionID:null,attachedModels:[]},
                passwordEditing:false,
                formSubmit: false,
                singleGroup: [],
                emplID: this.$route.params.emplID,
                saved:false,
                noGroups:false,
                noMarkGroups:false,
                groupIndex:null,
                timeNow:null,
                color: null,
                suckerCanvas: null,
                suckerArea: [],
                modelsList:[],
                sm:{pID:0}
            }
        },
        components() {
            employees,
            ColorPicker
        },
        methods: {
            addModel() {
                if (this.sm.pID!=0) {
                    console.log(this.modelsList[this.sm.pID]);
                    this.emplData.attachedModels.push(this.modelsList[this.sm.pID])
                    this.modelsList.splice(this.sm.pID,1)
                }
            },
            positionChange() {
                this.emplData.groupID = null;
                this.emplData.teamID = null;
            },
            changeColor(color) {
                const { r, g, b, a } = color.rgba
                this.color = `rgba(${r}, ${g}, ${b}, ${a})`
            },
            calcTimeNow() {
                this.timeNow = moment().utcOffset(this.emplData.timeZone*60).format("HH:mm")
            },
            switchView(event, selectedIndex) {
               this.groupIndex = selectedIndex-1;
               this.emplData.teamID = null;
            },
            validateEmail(email) {
                if (email.length>1) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if(re.test(String(email).toLowerCase())){
                        return true;
                    } else {
                        alert("Invalid email");
                        return false;
                    }
                } else {
                    return true;
                }
            },
            saveForm() {
                this.formSubmit = true
                this.loading = true
                if (this.emplID) {
                    this.buttonValue = 'Updating...'
                    setTimeout(() => (this.loading = false, this.buttonValue = 'Update account'), 1000);
                }else {
                    this.buttonValue = 'Creating...'
                    setTimeout(() => (this.loading = false, this.buttonValue = 'Create account'), 1000);
                }
                if (!(this.groupsList.length>1)) {
                    this.emplData.groupID = this.singleGroup.id;
                }
                if ((!this.emplData.id && this.emplData.password) || this.emplData.id) {
                    if (this.emplData.positionID && this.emplData.name && this.emplData.email && this.validateEmail(this.emplData.email)) {
                        axios.post(baseURL+"settingspost/", {                    
                            action: "saveEmployee",
                            userData: {
                                name: this.emplData.name,
                                email: this.emplData.email,
                                password: this.emplData.password,  
                                groupID: this.emplData.groupID,
                                teamID: this.emplData.teamID,
                                positionID: this.emplData.positionID,
                                hourly_wage: this.emplData.hourly_wage,
                                salarySystem: this.emplData.salarySystem,
                                note: this.emplData.notes,
                                timeZone: this.emplData.timeZone,
                                dst: this.emplData.dst,
                                emplColor: this.color,
                                userId: this.emplData.id,
                                attachedModels: this.emplData.attachedModels
                            },
                            userToken: this.$cookies.get('userToken'),
                            companyID: this.$cookies.get('c_id')
                        }).then((result) => {
                            if(result.data.message) {
                                alert(result.data.message);
                            } else {
                                if (!this.emplID) {
                                    this.emplData = [];
                                }
                                this.formSubmit = false;
                                this.saved = true;
                                setTimeout(() => (this.saved = false),2000);
                            }
                            result.data = null;
                        }, (error) => {
                            console.log(error);
                        })
                    } else {
                        //chyba - position ID nie je zadané
                    }
                } else {
                    //chyba - position ID nie je zadané
                }
            },
            getTeamsAndGroups() {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'groups',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    if (result.data.length) {
                        this.groupsList = result.data;
                        if (this.emplData.positionID != 16 && this.emplData.positionID != 4 && this.emplData.positionID != 17) {
                            this.singleGroup.name = result.data[0].name;
                            this.singleGroup.id = result.data[0].id;
                            for(var i=0;i<this.groupsList.length;i++) {
                                if (this.emplData.groupID == this.groupsList[i].id) {
                                    this.groupIndex = i;
                                }
                            }
                        }
                    } else {
                        this.noGroups = true;
                        this.singleGroup.id = 0;
                    }
                }, (error) => {
                    console.log(error);
                });
            },
            getMarketingTeamList() {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'assistant_teams',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    if (result.data.length) {
                        this.marketingTeamList = result.data;
                        if (this.emplData.positionID == 16 || this.emplData.positionID == 4 || this.emplData.positionID == 17) {
                            this.singleGroup.name = result.data[0].name;
                            this.singleGroup.id = result.data[0].id;
                            for(var i=0;i<this.marketingTeamList.length;i++) {
                                if (this.emplData.groupID == this.marketingTeamList[i].id) {
                                    
                                }
                            }
                        }
                    } else {
                        this.noMarkGroups = true;
                        this.singleGroup.id = 0;
                    }
                }, (error) => {
                    console.log(error);
                });
            },
            getAssistantsList(teamID) {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'assistants',
                        teamFilter: teamID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.assistantsList = result.data
                }, (error) => {
                    console.log(error);
                });
            },
            async getListData(whichData,group,team,mSummary = null) {
                return new Promise(async (resolve, reject) => {
                    try {
                      const result = await axios.get(baseURL+"lists/", {
                            params: {
                                build: whichData,
                                groupFilter: group,
                                teamFilter: team,
                                msummary:mSummary,
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id')
                            }
                        })
                      resolve(result.data);
                    } catch (error) {
                      reject(error);
                    }
                });
            }
        },
        async created() {
            this.accountTypeList = await this.getListData('userTypes',null,null);
            this.modelsList = await this.getListData('models',this.selectedGroup,null);

            if (this.emplID) {
                this.buttonValue = 'Update account'
                axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'editEmployees',
                        emplID: this.emplID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.emplData = result.data[0];
                    if (this.emplData.emplColor) {this.color = this.emplData.emplColor;} else {this.color = '#808080'}
                    if (this.emplData.timeZone == null) {this.emplData.timeZone = moment().utcOffset()/60}
                    this.timeNow = moment().utcOffset(this.emplData.timeZone*60).format("HH:mm")
                    this.groupIndex = result.data[0].groupIndex;
                    if (this.emplData.positionID == 16 || this.emplData.positionID == 4 || this.emplData.positionID == 17) {
                        this.emplData.groupID = this.emplData.marketingTeamID
                        this.emplData.teamID = this.emplData.assistantID
                    }
                    this.getTeamsAndGroups();
                    this.getMarketingTeamList();
                    this.getAssistantsList(this.emplData.groupID);
                }, (error) => {
                    console.log(error);
                })
            } else {
                // add a new employee
                this.emplData.groupID = 0;
                this.color = '#808080';
                this.emplData.timeZone = moment().utcOffset()/60;
                this.timeNow = moment().utcOffset(this.emplData.timeZone*60).format("HH:mm")
                this.getTeamsAndGroups();
                this.getMarketingTeamList();
            }

            
        }   
    }
</script>

<style>
.colors .item {
    float: left !important;
}
</style>