<template>
    <div class="pb-10">
        <div class="bg-green-300 rounded-full text-black p-2 fixed z-50 top-5 px-6 left-1/2" v-if="saved">Saved</div>

        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
        <div class="w-full mb-4 relative">
            <router-link :to="'../Settings'">
                <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
            </router-link>
            <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Task scheduler</h2>
            <p class="mt-2 text-sm text-gray-300 text-center">This visual interface is for scheduling tasks and allows you to manage the movement of models or employees between teams and groups.</p>
        </div>        

        <label for="type">Type:</label><br />
        <select v-model="type" id="type">
        <option value="model">Model</option>
        <option value="user">User</option>
        </select><br />

        <label for="item">{{ type == 'model' ? 'Model' : 'User' }}:</label><br />
        <select v-model="accountID" id="item">
            <slot v-if="type == 'model'">
                <option v-for="(account,index) in modelsList" :key="index" :value="account.pair_id">{{ account.code + " " + account.name }}</option>
            </slot>
            <slot v-else>
                <option v-for="(account,index) in usersList" :key="index" :value="account.id">{{ account.name }}</option>
            </slot>
        </select><br />

        <div class="mt-2 text-gray-300 float-left mr-5">
            <label>Group:</label><br />
            <select class="mt-1" v-model="groupID" v-if="noGroups">
                <option value="0">All groups</option>
            </select>
            <select class="mt-1 clear-both" v-model="groupID" v-if="groupList.length>1" @change="switchView($event, $event.target.selectedIndex)">
                <option v-for="(group,index) in groupList" :key="index" :value="group.id">{{group.name}}</option>
            </select>
        </div>

        <slot class="mt-2 mb-2 float-left" v-if="groupList && (groupList.length>0 && groupIndex!=null) || teamID">
            <div class="mt-2" v-if="(groupIndex!=-1 && groupList[groupIndex].hasChildren == true) || teamID">
                <label>Team:</label><br />
                <select class="mt-1 clear-both" v-model="teamID">
                    <option value="0">All teams</option>
                    <option v-for="(team,index) in groupList[groupIndex].children" :key="index" :value="team.id">{{ team.name }}</option>
                </select>
            </div>
        </slot>
        
        <div class="clear-both"></div>
        
        <div class="mt-3">
            <label for="datetime">Datetime of the move:</label><br />
            <input type="datetime-local" v-model="datetime" id="datetime" :min="minDateTime"><br />
        </div>
        
        <button @click="saveTask()" class="delay-75  duration-75 transition-all absolute right-20 p-3 px-7 text-lg rounded-full text-black font-medium bg-indigo-300 uppercase">
            <i class="fa-solid fa-spinner animate-spin" v-if="loading"></i> Save task
        </button>
    </div>

    <section class="mt-10 ">
        <table>
            <thead>
                <tr>
                    <th>Account Type</th>
                    <th>Account id</th>
                    <th>Run At</th>
                    <th>Task Type</th>
                    <th>Task Change</th>
                    <th>Scheduled By</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(task,index) in scheduledTasks" :key="index">
                <td class="p-0.5 px-2 text-left">{{ task.accountType }}</td>
                <td class="p-0.5 px-2 text-left">{{ task.accountID }}</td>
                <td class="p-0.5 px-2 text-left">{{ formatTimeStamp(task.runAt) }}</td>
                <td class="p-0.5 px-2 text-left">{{ task.taskType }}</td>
                <td class="p-0.5 px-2 text-left">{{ task.taskChange }}</td>
                <td class="p-0.5 px-2 text-left">{{ task.scheduledBy }}</td>
                <td class="p-0.5 px-2 text-left cursor-pointer"><i v-if="task.id" @click="removeTask(task.id,index)" class="fa-regular fa-trash-can"></i></td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                noGroups:false,
                groupID:1,
                groupList: [],
                groupIndex:null,
                teamID:null,
                saved:false,
                loading:false,
                datetime: "",
                type: "model",
                accountID: "",
                modelsList: [],
                usersList:[],
                scheduledTasks:[]
            }
        },
        computed: {
            minDateTime() {
                const currentDateTime = new Date();
                return currentDateTime.toJSON().slice(0, 16);
            },
        },
        methods: {
            formatTimeStamp(timestamp) {
                return moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm:ss');
            },
            removeTask(tID,index) {
                if (confirm("Are you sure?")) {
                    axios.post(baseURL+"settingspost/", {                    
                        action: "deleteTask",
                        taskID: tID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.scheduledTasks.splice(index,1);
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            saveTask() {
                axios.post(baseURL+"settingspost/", {                    
                    action: "scheduleTask",
                    taskData: {
                        type:this.type,
                        accountID:this.accountID,
                        groupID:this.groupID,
                        teamID:this.teamID,
                        runAt: moment(this.datetime, "YYYY-MM-DDTHH:mm").utc().format("YYYY-MM-DDTHH:mm")
                    },
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.scheduledTasks.push({accountType:this.type,accountID:this.accountID,taskType:'move to',runAt:moment(this.datetime, "YYYY-MM-DDTHH:mm").utc().format("YYYY-MM-DDTHH:mm"),scheduledBy:null,taskChange:this.teamID>0 ? this.teamID : this.groupID});
                }, (error) => {
                    console.log(error);
                });
            },
            switchView(event, selectedIndex) {
                this.groupIndex = selectedIndex;
                this.teamID = null;
            },
            getScheduledTasks() {
                axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'getScheduledTasks',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.scheduledTasks = result.data;
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            async getListData(whichData,group,team) {
                return new Promise(async (resolve, reject) => {
                    try {
                      const result = await axios.get(baseURL+"lists/", {
                            params: {
                                build: whichData,
                                groupFilter: group,
                                teamFilter: team,
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id')
                            }
                        })
                      resolve(result.data);
                    } catch (error) {
                      reject(error);
                    }
                });
            },
            getTeamsAndGroups() {
                axios.get(baseURL+"lists/", {
                    params: {
                        build: 'groups',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.groupList = result.data;
                    if(result.data.length) {
                        for(var i=0;i<this.groupList.length;i++) {
                            console.log(this.groupID, this.groupList[i].id);
                            if (this.groupID == this.groupList[i].id) {
                                this.groupIndex = i;
                                break;
                            } else {
                                this.groupIndex = 0;
                            }
                        }
                        this.displayForm = true;
                    } else {
                        this.displayForm = false;
                        this.noGroups = true;
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.createdForm = true);
            }
        },
        async created() {
            this.loading = true;
            this.getTeamsAndGroups();
            this.usersList = await this.getListData('sextarsList',null,null);
            this.modelsList = await this.getListData('models',null,null);
            this.getScheduledTasks();
        }
    }
</script>