<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Edit an employee</h2>
    </div>

    <div>
        <table class="text-white bg-gray-700" id="employeeTable">
            <tr>
                <th class="cursor-pointer" @click="sortTable('id','ASC')">Code &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'id'}"></i></th>
                <th class="w-1/3 cursor-pointer" @click="sortTable('name','ASC')">Name &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'name'}"></i></th>
                <th class="cursor-pointer" @click="sortTable('groupName','ASC')">Group &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'groupName'}"></i></th>
                <th class="w-1/4 cursor-pointer" @click="sortTable('positionName','ASC')">Position &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'positionName'}"></i></th>
                <th class="px-3">Edit</th>
                <th class="px-3">Suspend</th>
                <th class="px-3">Archive</th>
            </tr>
            <tr v-for="(empl, index) in emplData" :key="index" v-bind:class="{archived:empl.active== 0}">
                <td>{{ empl.id }}</td>
                <td><span class="w-4 h-4 rounded-full mt-1 float-left" :style="'background:'+empl.emplColor"></span> {{ empl.name }}</td>
                <td v-if="empl.groupName == ''">All</td>
                <td v-else>{{ empl.groupName }}</td>
                <td>{{ empl.positionName }}</td>
                <td class="cursor-pointer text-center" v-if="((parseInt(empl.orderNo) > parseInt(myself.orderNo)) || (empl.id == myself.id)) && empl.active != 0">
                    <router-link :to="{ name: 'employeesForm', params: {edit:true, emplID: empl.id}}"><i class="fa-regular fa-pen-to-square"></i></router-link>
                </td>
                <td v-else></td>
                <slot v-if="((parseInt(empl.orderNo) > parseInt(myself.orderNo)) || (empl.id == myself.id))">
                    <td class="cursor-pointer text-center" @click="emplUnSuspend(index)" v-if="empl.suspended == 1"><i class="fa-regular fa-circle-play text-red-500"></i></td>
                    <td class="cursor-pointer text-center" @click="emplSuspend(index)" v-else><i class="fa-regular fa-circle-pause text-green-500"></i></td>
                </slot>
                <td v-else></td>
                <slot v-if="((parseInt(empl.orderNo) > parseInt(myself.orderNo)) || (empl.id == myself.id)) && empl.active != 0 && empl.suspended == 1">
                    <td class="cursor-pointer text-center" @click="emplDeArchive(index)" v-if="empl.active == 0">Return from archive <i class="fas fa-undo"></i></td>
                    <td class="cursor-pointer text-center" @click="emplArchive(index)" v-else><i class="fas fa-archive"></i></td>
                </slot>

                <td v-else></td>
            </tr>
        </table>
    </div>
</template>

<script setup>
    import employeesForm from '@/components/settings/employeesForm.vue'
</script>

<script>
    export default {
        props: ['whichSetting'],
        components() {
            employeesForm
        },
        data() {
            return { 
                emplData: [],
                emplID: null,
                sortColumn: null,
                loading: false,
                myself:0
            }
        },
        methods: {
            sortTable(column,order) {
                let orderMultiplier = order === 'ASC' ? 1 : -1;
                if (this.$cookies.get('orderColumnE') != column) {
                    this.$cookies.set('orderColumnE',column);
                }
                this.emplData.sort((a, b) => {
                    const leadingZeroA = a[column].toString().padStart(10, '0');
                    const leadingZeroB = b[column].toString().padStart(10, '0');
                    return order === 'ASC' ? leadingZeroA.localeCompare(leadingZeroB) : leadingZeroB.localeCompare(leadingZeroA);
                });
            },
            archFunction(index,state) {
                this.emplData[index].active=state
                axios.post(baseURL+"settingspost/", {
                    action: "employeeArchiveDearchive",
                    employeeID: this.emplData[index].id,
                    active: state,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(() => {
                }, (error) => {
                    console.log(error);
                });
            },
            emplArchive(index) {
                if (confirm("Are you sure you want to archive this user?")) { 
                    this.archFunction(index,0);
                }
            },
            emplDeArchive(index) {
                if (confirm("Are you sure you want to return this user from archive?")) {
                    this.archFunction(index,1);
                }
            },
            suspendFunction(index,state) {
                this.emplData[index].suspended=state
                axios.post(baseURL+"settingspost/", {
                    action: "employeeSuspend",
                    employeeID: this.emplData[index].id,
                    suspended: state,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(() => {
                }, (error) => {
                    console.log(error);
                });
            },
            emplSuspend(index) {
                if (confirm("Are you sure you want to suspend this user?")) {
                    this.suspendFunction(index,1);
                }
            },
            emplUnSuspend(index) {
                if (confirm("Are you sure you want to suspend this user?")) {
                    this.suspendFunction(index,0);
                }
            }
        },
        mounted() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.myOrder) {this.myself = result.data.myself;}
                }, (error) => {
                console.log(error);
            })

            this.loading = true;
            axios.get(baseURL+"settingsget/", {
                params: {
                    searchFor: '',
                    action: 'editEmployees',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.emplData = result.data
                if (this.$cookies.get('orderColumnE')) {
                    this.sortTable(this.$cookies.get('orderColumnE'),'ASC')
                }
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
        },
    }
</script>

<style scoped>
  tr:nth-child(odd) {
    background-color: rgb(75, 85, 99);
  }
  td {
      padding:5px;
  }
</style>