<template>
    <div>
        <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">Saved</div>

        <div class="w-full mb-4 relative">
            <router-link :to="'../../Settings'">
                <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
            </router-link>
            <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Drop shifts settings</h2>
        </div>

        <section>
            <label>Number of shifts per day</label><br />
            <input type="number" class="w-16 mr-20" placeholder="No." v-model="shiftsCount" min="0">

            <button @click="saveShiftsAmount()" class="delay-75  duration-75 transition-all float-right p-2 px-7 mt-10 text-lg rounded-full text-black font-medium bg-indigo-300 uppercase">
                <i class="fa-solid fa-spinner animate-spin" v-if="loading"></i> Save changes
            </button>

            <div class="clear-both"></div>
        </section>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                shiftsCount:0,
                saved:false
            }
        },
        methods: {
            saveShiftsAmount() {
                //save the new amount of shifts
                axios.post(baseURL+"settingspost/", {                    
                    action: "dropShiftAmount",
                    dropShiftCount: this.shiftsCount,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(() => {
                    this.saved = true;
                    setTimeout(() => {
                        this.saved = false;
                    }, 1000);
                }, (error) => {
                    console.log(error);
                });
            }
        },
        created() {
            //get the actual amount of shifts
            axios.get(baseURL+"settingsget/", {
                params: {
                    action: "dropShiftAmount",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                if (result.data) {
                    this.shiftsCount = parseInt(result.data.shiftsAmount);
                }
            }, (error) => {
                console.log(error);
            });
        }
    }
</script>