<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <i class="absolute fas fa-arrow-left float-left text-lg p-2 text-gray-300 -mt-2"></i>
      </router-link>
      <h2 class="mt-2 text-xl text-gray-200 font-semibold text-center">Archived employees</h2>
    </div>

    <div>
        <table class="text-white bg-gray-700" id="employeeTable">
            <tr>
                <th onclick="sortTable(0,'employeeTable')" class="cursor-pointer"  @click="sortColumn=0">Code &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 0}"></i></th>
                <th onclick="sortTable(1,'employeeTable')" class="w-1/4 cursor-pointer" @click="sortColumn=1">Name &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 1}"></i></th>
                <th onclick="sortTable(2,'employeeTable')" class="w-1/ cursor-pointer" @click="sortColumn=2">Group &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 2}"></i></th>
                <th onclick="sortTable(3,'employeeTable')" class="w-1/6 cursor-pointer" @click="sortColumn=3">Position &nbsp; <i class="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 3}"></i></th>
                <th class="w-1/5">Return from archive</th>
            </tr>
            <tr v-for="(empl, index) in archivedEmpl" :key="index" v-bind:class="{archived:empl.active== 0}">
                <td>{{ empl.id }}</td>
                <td>{{ empl.name }}</td>
                <td v-if="empl.groupName == ''">All</td>
                <td v-else>{{ empl.groupName }}</td>
                <td>{{ empl.positionName }}</td>            
                <td class="cursor-pointer" @click="emplDeArchive(index)" v-if="empl.active == 0">Return from archive <i class="fas fa-undo"></i></td>
                <td class="cursor-pointer" @click="emplArchive(index)" v-else>Archive <i class="fas fa-archive"></i></td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                archivedEmpl: null,
                emplID: null,
                sortColumn: null,
                loading: false
            }
        },
        methods: {
            emplArchive(index) {
                if (confirm("Are you sure you want to archive this user?")) { 
                    this.archivedEmpl[index].active=0 
                    axios.post(baseURL+"settingspost/", {                    
                        action: "employeeArchiveDearchive",
                        employeeID: this.archivedEmpl[index].id,
                        active: 0,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            emplDeArchive(index) {
                if (confirm("Are you sure you want to return this user from archive?")) { 
                    this.archivedEmpl[index].active=1 
                    axios.post(baseURL+"settingspost/", {                    
                        action: "employeeArchiveDearchive",
                        employeeID: this.archivedEmpl[index].id,
                        active: 1,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                    }, (error) => {
                        console.log(error);
                    });
                    
                }
            }
        },
        created() {
            this.loading = true;
            axios.get(baseURL+"settingsget/", {
                params: {
                    searchFor: '',
                    action: 'archivedEmpl',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                console.log(result.data)
                this.archivedEmpl = result.data
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
        },
    }
</script>

<style scoped>
  tr:nth-child(odd) {
    background-color: rgb(75, 85, 99);
  }
  td {
      padding:5px;
  }
</style>