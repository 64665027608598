<template>
    <div>
        <ofdash-button @click="modal=true,modalEdit=false" label="Add company" icon="fa-solid fa-plus" class="fixed right-10 top-5" />
        <div class="text-gray-500 ml-28 mt-16">*changes on the Marketing column require page refresh</div>
        <table class="mt-4 ml-28 mb-10 text-gray-400 bg-gray-700 w-max ">
            <tr>
                <th class="px-2">Company Name</th>
                <th class="px-2">Marketing</th>
                <th class="px-2">RenewOn</th>
                <th class="px-2">SubsPaid</th>
                <th class="px-2">Bonus(sexting)</th>
                <th class=""></th>
            </tr>
            <tr class="ml-20 text-white mt-5" v-for="(company, index) in companies" :key="index">
                <td class="p-1 px-4 text-left">{{company.name}}</td>
                <td class="p-1 px-4">{{company.marketing}}</td>
                <td class="p-1 px-4">{{company.renewOn}}</td>
                <td class="p-1 px-4">{{company.subsPaid}}</td>
                <td class="p-1 px-4">{{company.display_bonus}}</td>
                <td class="px-4 cursor-pointer" @click="editCompany(index)"><i class="fa-solid fa-pen-to-square"></i></td>
            </tr>
        </table>

        <section v-if="modal" @click="modal=false" class="fixed w-screen h-screen top-0 left-0 bg-black/50">
        </section>
        <section v-if="modal" class="fixed top-0 w-full h-1">
            <div class="top-10 w-96 mx-auto bg-gray-800 p-5 rounded-lg relative">
                <i class="fa-solid fa-xmark text-white absolute right-0 top-0 p-5 cursor-pointer" @click="modal=false"></i>
                <p class="text-gray-300 bold text-2xl"><slot v-if="modalEdit">Edit company</slot><slot v-else>New company</slot></p><br />
                <input v-model="form.name" type="text" placeholder="Company name" /><br />
                <slot v-if="!modalEdit">
                    <input type="text" placeholder="User name" v-model="form.user" /><br />
                    <input type="text" placeholder="Email" v-model="form.email" /><br />
                    <input type="text" placeholder="Password" v-model="form.pass" /><br />
                </slot>
                <span class="text-white leading-8">
                    <div class="cursor-pointer transition-all ease-in-out duration-500" @click="formParams.renewOn = !formParams.renewOn"><i class="fa-lg mr-2" :class="{'fa-regular fa-square': !formParams.renewOn, 'fa-solid fa-square-check': formParams.renewOn}"></i> Renew On</div>
                    <div class="cursor-pointer transition-all ease-in-out duration-500" @click="formParams.subsPaid = !formParams.subsPaid"><i class="fa-lg mr-2" :class="{'fa-regular fa-square': !formParams.subsPaid, 'fa-solid fa-square-check': formParams.subsPaid}"></i> Subs Paid</div>
                    <div class="cursor-pointer transition-all ease-in-out duration-500" @click="formParams.display_bonus = !formParams.display_bonus"><i class="fa-lg mr-2" :class="{'fa-regular fa-square': !formParams.display_bonus, 'fa-solid fa-square-check': formParams.display_bonus}"></i> Display bonus</div>
                Marketing:<br />                
                <select v-model="formParams.marketingCo">
                    <option value="0">Own</option>
                    <option v-for="(option,index) in marketingCompanies" :key="index" :value="option.id">{{option.name}}</option>
                </select><br />
                </span>
                <ofdash-button v-if="modalEdit" @click="updateCompany(companies[form.index].id,form.index)" class="mt-5" icon="fa-solid fa-floppy-disk" label="Save" />
                <ofdash-button v-else @click="saveCompany()" class="mt-5" icon="fa-solid fa-floppy-disk" label="Save" />
            </div>
        </section>
    </div>
</template>

<script setup>
    import ofdashButton from "@/components/ofdashButton.vue"
</script>

<script>
    export default {
        data() {
            return {
                companies: [],
                form: {index:null,name:null,user:null,email:null,pass:null},
                loading: false,
                marketingCompanies:[],
                formParams: {renewOn:false,subsPaid:false,marketingCo:0,display_bonus:0},
                modal:false,
                modalEdit:false
            }
        },
        components: {ofdashButton},
        methods: {
            editCompany(index) {
                this.modal = true;
                this.modalEdit = true;
                this.form.name = this.companies[index].name;
                this.form.index = [index];
                this.formParams.renewOn = Boolean(parseInt(this.companies[index].renewOn));
                this.formParams.subsPaid = Boolean(parseInt(this.companies[index].subsPaid));
                this.formParams.marketingCo = this.companies[index].marketingID;
                this.formParams.display_bonus = Boolean(parseInt(this.companies[index].display_bonus));
            },
            updateCompany(branch,index) {
                axios.post(baseURL+"register/registerCompany.php", {                    
                    name: this.form.name,
                    formParams: this.formParams,
                    branch: branch,
                    companyID: this.$cookies.get('c_id'),
                    userToken: this.$cookies.get('userToken')
                }).then((result) => {
                    this.modal = false;
                    this.companies[index].name = this.form.name;
                    this.companies[index].renewOn = this.formParams.renewOn ? 1 : 0;
                    this.companies[index].subsPaid = this.formParams.subsPaid ? 1 : 0;
                    this.companies[index].marketingID = this.formParams.marketingCo;
                    this.companies[index].display_bonus = this.formParams.display_bonus ? 1 : 0;
                    this.form = {name:null,user:null,email:null,pass:null};
                    this.formParams = {renewOn:false,subsPaid:false,marketingCo:0,display_bonus:0};
                }, (error) => {
                    console.log(error);
                });
            },
            saveCompany() {
                axios.post(baseURL+"register/registerCompany.php", {
                    name: this.form.name,
                    userName: this.form.user,
                    email: this.form.email,
                    password: this.form.pass,
                    formParams: this.formParams,
                    companyID: this.$cookies.get('c_id'),
                    userToken: this.$cookies.get('userToken')
                }).then((result) => {
                    this.modal = false;
                    this.companies.unshift({
                        name:this.form.name,
                        renewOn:this.formParams.renewOn ? 1 : 0,
                        subsPaid:this.formParams.subsPaid ? 1 : 0,
                        display_bonus:this.formParams.display_bonus ? 1 : 0
                    });
                    this.form = {name:null,user:null,email:null,pass:null};
                    this.formParams = {renewOn:false,subsPaid:false,marketingCo:0,display_bonus:0};
                }, (error) => {
                    console.log(error);
                });
            },
        },
        created () {
            //block to be accessible only to super admin
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    } else {
                        // get all companies
                        axios.get(baseURL+"lists/", {
                            params: {
                                userToken: this.$cookies.get('userToken'),
                                build: "getAllCompanies",
                                companyID:companyID
                            }
                        }).then((result) => {
                            this.companies = result.data
                        }, (error) => {
                            console.log(error);
                        });
                        
                        // get marketing companies
                        axios.get(baseURL+"settingsget/", {
                        params: {
                            userToken: this.$cookies.get('userToken'),
                            action: "getMarketingCompanies",
                            companyID: companyID
                        }
                        }).then((result) => {
                            this.marketingCompanies = result.data
                        }, (error) => {
                            console.log(error);
                        });
                    }
                }, (error) => {
                console.log(error);
            })
        }
    }
</script>