<template>
    <headerfilter :pageTitle="'Analytics'" />
    <div class="text-white text-center mt-36">
        Page in development
    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
</script>
<script>
    export default {
        components() {
            headerfilter
        }
    }
</script>