<template>
    <div>
        <i :class="icon"></i>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                icon: null
            }
        },
        props: ['t'],
        created() {
            if (this.t == "Subscription") {
                this.icon = "fa-solid fa-heart text-red-300";
            } else if (this.t == "Message") {
                this.icon = "fa-regular fa-comments";
            } else if (this.t == "Tip") {
                this.icon = "fa-solid fa-dollar-sign text-green-300";
            } else {
                this.icon = "fa-solid fa-table-columns text-purple-300";
            }
        }
    }
</script>