<template>
    <div class="sm:ml-22">
        <div class="fixed p-4 pl-5 top-0 bg-gray-800/50 shadow-lg z-10 border-b-2 border-gray-500" style="width:calc(100% - 5rem);">
            <h1 class="float-left mr-5 text-base sm:text-xl text-white font-bold mt-1"><i class="fa-solid fa-candy-cane text-white/50 mr-2"></i> Tools and downloads <i class="fa-solid fa-gift text-white/50 ml-2"></i></h1>
        </div>

        <section class="mt-22 ml-5 mb-20 mt-5 bg-gray-800/80 p-1 px-7 rounded-xl">
            <div class="mt-5">
                <h2 class="text-white font-semibold text-lg">NoLogin</h2>
                <span class="text-red-300 sm:hidden">Not available for a mobile device<br /></span>
                <span class="text-white/50"><strong class="text-white/80">*For NoLogin to work, please have <a class="underline" href="https://www.google.com/chrome/" target="_blank">google chrome browser</a> installed!</strong><br />NoLogin is a tool that lets you open up Onlyfans accounts of models you have access to (based on your team and position permissions).</span>
                <table v-if="nologin_versions_block" class="mt-4 text-white/80 leading-loose bg-white/20 p-3 px-5 w-max rounded">
                    <tr>
                        <th class="p-0.5 px-3">OS <i v-if="loading" class="ml-2 fa-solid fa-spinner animate-spin"></i></th>
                        <th class="p-0.5 px-3">Size</th>
                    </tr>
                    <slot v-for="(version, index) in nologin_versions" :key="index">
                        <tr>
                            <td class="p-1 px-3 text-center"><ofdash-button @click="download(version.name)" :label="version.os_version" class="py-0.5" icon="fa-solid fa-download" /></td>
                            <th class="p-0.5 px-3">{{version.size}}</th>
                        </tr>
                    </slot>
                </table>
                <ofdash-button v-if="!nologin_versions_block" @click="enroll_nologin_versions" icon="fa-solid fa-chevron-down" class="mt-3" label="See versions" />

                <hr class="mt-7 border-0 border-t border-white/30"/>
            </div>
            
            <div class="mt-5" v-if="myself && (myself.position == 1 || myself.position == 2)">
                <h2 class="text-white font-semibold text-lg">Onlyfans scraping extension</h2>
                <p class="text-white/50">
                    Note: This is in a plain text and needs to be copy-pasted into a new script inside the extension of your choosing. If you do not have <a class="text-white/80 underline" target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/greasemonkey/">greasemonkey</a> or <a class="text-white/80 underline" target="_blank" href="https://www.tampermonkey.net/">tampermonkey</a> extension yet, please install it into your browser first.<br />
                </p>
                <ofdash-button @click="download('scraping_extension.txt')" class="mt-3" label="Download" icon="fa-solid fa-download" />

                <hr class="mt-7 border-0 border-t border-white/30"/>
            </div>
            <div class="mt-5" v-if="myself && (myself.position == 1 || myself.position == 2)">
                <h2 class="text-white font-semibold text-lg">Onlyfans login extension</h2>
                <p class="text-white/50">
                    Using this script, you can login into any onlyfans model account without having the credentials. They will be pulled from ofdash automatically. Just login, pick the model and get started.<br />
                    Note: This is a plain script that needs to be copy-pasted into a new script inside a dedicated extension. If you do not have yet <a class="text-white/80 underline" target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/greasemonkey/">greasemonkey</a> or <a class="text-white/80 underline" target="_blank" href="https://www.tampermonkey.net/">tampermonkey</a> extension, please install it into your browser first.
                </p>
                <ofdash-button class="mt-3" @click="download('login_extension.txt')" label="Download" icon="fa-solid fa-download" />

                <hr class="mt-7 border-0 border-t border-white/30"/>
            </div>
            <div class="mt-5" v-if="myself && (myself.position == 1 || myself.position == 2)">
                <h2 class="text-white font-semibold text-lg">Fan & model notes</h2>
                <p class="text-white/50">
                    Note: This is in a plain text and needs to be copy-pasted into a new script inside the extension of your choosing. If you do not have <a class="text-white/80 underline" target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/greasemonkey/">greasemonkey</a> or <a class="text-white/80 underline" target="_blank" href="https://www.tampermonkey.net/">tampermonkey</a> extension yet, please install it into your browser first.<br />
                </p>
                <ofdash-button @click="download('fan_notes_extension.txt')" class="mt-3" label="Download" icon="fa-solid fa-download" />

                <hr class="mt-7 border-0 border-t border-white/30"/>
            </div>
        </section>
    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue"
    import ofdashButton from '@/components/ofdashButton.vue'
</script>

<script>
    export default {
        components() {
            headerfilter,
            ofdashButton
        },
        data() {
            return {
                nologin_versions:[],
                nologin_versions_block:false,
                loading:false,
                myself:null,
                companyID:null
            }
        },
        methods: {
            download(file) {
                window.open('https://api.ofdash.com/githubDownload/?q=download&file='+file)
            },
            get_nologin_versions() {
                this.loading = true
                axios.get(baseURL+"githubDownload/?q=assets").then((result) => {
                    this.nologin_versions = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => {this.loading = false});
            },
            enroll_nologin_versions() {
                this.nologin_versions_block = !this.nologin_versions_block;
                if (this.nologin_versions_block == true) {this.get_nologin_versions();}
            },
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', params: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.permissions) {this.opravnenia = result.data.permissions;}
                    else {this.opravnenia = '00';}
                    this.myself = result.data.myself;
                }, (error) => {
                console.log(error);
            });
        }
    }
</script>