<template>
    <headerfilter :pageTitle="'Settings'" />
  <div class="mx-auto w-max sm:ml-28 mt-24">
    <section v-if="loaded" class="bg-gray-700 text-gray-400 text-base rounded-xl whitespace-nowrap w-max mt-6 mb-10 p-8 px-14 relative" style="min-width:400px">
      <router-view :key="$route.path"></router-view>
    </section>
  </div>
</template>

<script setup>  
  import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
export default {
  components() {
    headerfilter
  },
  data() {
    return {
      showHeader: false,
      loaded:false
    }
  },
  created() {
    if (!(this.$cookies.get('c_id'))) {
        var companyID = null;
    } else {
        var companyID = this.$cookies.get('c_id');
    }
    axios.get(baseURL+"opravnenia/userToken.php", {
        params: {
            userToken: this.$cookies.get('userToken'),
            companyID: companyID,
            place: 'header'
        }
        }).then((result) => {
          if (result.data.selectBranch) {
            let prev = this.$route.path;
            this.$router.push({name:'Branches', params: { prevRoute: prev } });
          } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
            this.$cookies.set('userToken','');
            window.location.href = '/';
          }
        }, (error) => {
        console.log(error);
    }).finally(this.loaded = true)
  },
}
</script>